import React, { useEffect } from 'react';
import flatpickr from 'flatpickr';
import "flatpickr/dist/flatpickr.min.css"; // Importa los estilos predeterminados
import "../styles/BreakInput.css";

function BreakInput({ onTimeChange }) {
    useEffect(() => {
        // Creat an flatpickr instance
        const fp = flatpickr("#breakInput", {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            defaultDate: "00:00",
            time_24hr: true,
            disableMobile: true,
            onChange: (selectedDates, dateStr) => {
                onTimeChange(dateStr); 
            }
        });

        // Cleanup function that will destroy the flatpickr instance when the component is unmounted or remounted
        return () => {
            fp.destroy();
        };
    }, []); 

    return (
        <input type="text" id="breakInput" className="break-input" placeholder="00:00"/>
    );
}

export default BreakInput;
