export const checkPasswordStrength = (password) => {
    let strength = 0;
    let message;
    let conditions = {
        minLength: false,
        containsNumber: false,
        containsUppercase: false,
        containsLowercase: false,
        containsSpecialChar: false
    };

    // Verify the maximum length
    if (password.length >= 8) {
        strength++;
        conditions.minLength = true;
    }

    // Verify special characters
    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) {
        strength++;
        conditions.containsSpecialChar = true;
    }

    // Verify uppercase letters
    if (/[A-Z]/.test(password)) {
        strength++;
        conditions.containsUppercase = true;
    }

    // Verify lowercase letters
    if (/[a-z]/.test(password)){
        strength++;
        conditions.containsLowercase = true;
    }

    // Verify numbers
    if (/\d/.test(password)) {
        strength++;
        conditions.containsNumber = true;
    }

    if(strength < 2)
        message = "Weak";
    else if(strength >= 2 && strength < 4)
        message = "Medium";
    else if(strength >= 4)
        message= "Strong";

    return{
        message: message,
        strength: strength,
        conditions: conditions
    } 
}