import axios from "axios";
import {deleteSessionCookie, verifySessionCookie} from "./cookies";

export const initSessionValidation =  async () => {

    const sessionData = await verifySessionCookie();  

    // Check if a token exists in localStorage
  if (sessionData) {
    // If there is a token, send it to the server for validation

    const responseSession = await axios.post(`${process.env.REACT_APP_API_URL}/api/check-session-token`, {
      sessionData: sessionData
    });

    if (responseSession && responseSession.data.validToken) {  //EMAIL NAME SURNAME TIMEZONE
      const email = responseSession.data.email;
      const name = responseSession.data.name;
      const surname = responseSession.data.surname;
      const timezone = responseSession.data.timezone;


      return {
          email: email,
          name: name,
          surname: surname,
          timezone: timezone,
          success: true
      };
    } else {
        await deleteSessionCookie();
        return { success: false };
    }
  } else {
    return { success: false };
  }
}

