import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; 
import "../styles/Home.css";
import "../styles/Login.css";
import { useUser } from '../components/UserContext'; // import UserProvider
import {showMessageError} from "../components/messages.js";
import axios from 'axios';
import { Link } from "react-router-dom";
import { createSession } from "../components/Session.js";
import { initSessionValidation }from "../components/Auth";
import {deleteAllSession} from "../components/Session.js";
import {setBodyClass} from "../components/ShowRecaptcha.js";
import {isValidEmail} from "../components/UsefulFunctions.js";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {createSessionCookie} from "../components/cookies.js";

import { useLoading } from '../components/LoadingContext.js';

// Function to generate a unique idempotency key
const generateIdempotencyKey = async () => `Login-${new Date().getTime()}-${Math.random().toString(36).substring(2, 11)}`;

function Login({ isLogin = false, invalidToken = true, isAuthenticated, setIsAuthenticated, setActivatedSession, cookieConsent, showCookieBanner, checkCookieConsent, consent }) {

  const [isLoginState, setIsLogin] = useState(isLogin); 
  const [invalidTokenState, setInvalidToken] = useState(invalidToken); 

  const { startLoading, stopLoading } = useLoading();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [forgotenPasswordSended, setForgotenPasswordSended] = useState(false);
  const [emailRecovery, setEmailRecovery] = useState("");
  const [nameUserRecovery, setNameUserRecovery] = useState("");
  const [unavailableButton, setUnavailableButton] = useState(false);

  const isSubmitting = useRef(false);

  const navigate = useNavigate(); 

  const { updateUser, loadUserData } = useUser();

  const numMaxFailedAttempts = 10;
//----
const { executeRecaptcha } = useGoogleReCaptcha();

//----

useEffect(() => {
  document.title = "Sign In | ClockIn Smart";
}, []);

useEffect(() => {
  setBodyClass("login-page", true);
  return () => {
    setBodyClass("login-page", false);
  };
}, []);

useEffect(() => {
  if(isAuthenticated)
    goToHome();

    checkCookieConsent();
})

useEffect(() => {
  if (!isSubmitting.current && isError) {
    showMessageError(errorMessage, setIsError, setErrorMessage, 3000);
  }
}, [isError, errorMessage]);

const attemptCounter = (() => {
  let counter = 0; // Private variable within the function scope
  // Function to increment the counter
  const increment = () => {
      counter++;
  };

  const reset = () => {
    counter = 0;
};

  // Function to get the current value of the counter
  const getValue = () => counter;

  const setButtonUnavailable = () => setUnavailableButton(true);
  

  const setButtonAvailable = () => setUnavailableButton(false);

  // Return an object with only the incrementing and value retrieval functions
  return {
      increment,
      reset,
      getValue,
      setButtonUnavailable,
      setButtonAvailable
  };
})();

  const comprobateRecaptcha = useCallback( (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      showMessageError("Error. Execute recaptcha not yet available", setIsError, setErrorMessage, 3000);
      return;
    }

    executeRecaptcha("formRecaptcha").then((gReCaptchaToken) => {

      if(attemptCounter.getValue() > numMaxFailedAttempts || unavailableButton){
        setUnavailableButton(true);
        showMessageError("Error. Maximum number of allowed attempts reached. Try later", setIsError, setErrorMessage, 10000);

        setTimeout(() => {
          attemptCounter.reset(); 
          setUnavailableButton(false);
        }, 10000);
        return;
      }
      
      if(isLoginState){
          loginUser(gReCaptchaToken);
      }else
          sendEmailRecovery(gReCaptchaToken);
    });
    
  },[executeRecaptcha, cookieConsent, email, password, isLoginState, emailRecovery, nameUserRecovery, unavailableButton ]);

  const comprobateIfExists = async ({ emailRecovery }) => {
      // Make a request to the server to check if the email is already registered
      const responseEmail = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkEmail`, {
        email: emailRecovery
      });
      setNameUserRecovery(responseEmail.data.userName);
      if(responseEmail.data.exists){
        return {
          exists: responseEmail.data.exists,
          userName: responseEmail.data.userName,
        };
      }
      return responseEmail.data.exists; // If true, exists, if false, it does not exists
  };

  const comprobateIfExistsAccess = async ({ email }) => {
    // Make a request to the server to check if the email is already registered
    const responseEmailAccess = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkEmailLoginAccess`, {
      email: email,
    });
    if(responseEmailAccess.data.exists){
      setNameUserRecovery(responseEmailAccess.data.userName);
      return {
        exists: responseEmailAccess.data.exists,
        accessRegistered: responseEmailAccess.data.accessRegistered
      };
    }
    return responseEmailAccess.data.exists; // If true, exists, if false, it does not exists
};

  const goToHome = () => {
    navigate('/Home');
  }

  const loginUser = async (gReCaptchaToken) => {

    if (isSubmitting.current) return;
    isSubmitting.current = true;

    const responseCaptcha = await axios.post(`${process.env.REACT_APP_API_URL}/verifyRecaptcha`, {
            captchaValue: gReCaptchaToken,
          });

       if(responseCaptcha.data.success){

        if (email.trim("") !== "" && password.trim("") !== "") {

          startLoading();
          
          // First, we check if email exists: to verify if it's blocked
          const emailExists = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkEmail`, {
            email: email
          });
          
          if(emailExists.data.exists){
            
            const emailExistsAccess = await comprobateIfExistsAccess({email})

            if(emailExistsAccess.exists){
              if(emailExistsAccess.accessRegistered.blocked){
                attemptCounter.increment();
                isSubmitting.current = false;
                stopLoading();
                setErrorMessage("Error. Deactivated account");
                setIsError(true);
                /* setLoading(false);
                showMessageError("Error. Desactivated account", setIsError, setErrorMessage, 3000); */
                // It is blocked
                return;
              }
            }

            try {
              const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, {
                email,
                password,
              });
  
              const messageResponse = response.data.message;

              if (response.data.success) {
                // I check if there's a record of previous access

                const dateConvertedNewAccess = new Date(); 
                const dateISO =dateConvertedNewAccess.toISOString();
                if(emailExistsAccess.exists){
                  // I modify the attempts to 0 of the existing record.
                  const idAccessRegistered = emailExistsAccess.accessRegistered._id;

                  // Generate a new Idempotency key
                  const newIdempotencyKey = await generateIdempotencyKey();

                  const config = {
                      headers: {
                        'idempotency-key': newIdempotencyKey
                      }
                  };

                  try {
                    // Make the PUT request to the server to update the access.
                      await axios.put(`${process.env.REACT_APP_API_URL}/api/updateLoginAccess/${idAccessRegistered}`, {
                      last_login_date: dateISO,
                      failed_attempts_count: 0,
                      blocked: false
                    }, config);
                
                  } catch (error) {
                    isSubmitting.current = false;
                    stopLoading();
                    setErrorMessage("An unexpected error has occurred");
                    setIsError(true);
                    /* setLoading(false);
                    showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000); */
                    return;
                  }

                }else{
                  // Create a new one with the attempts set to 0 of the new record.
                  // I'll have to make a createLoginAccess...
                  try{

                    // Generate a new Idempotency key
                    const newIdempotencyKey = await generateIdempotencyKey();

                    const config = {
                        headers: {
                          'idempotency-key': newIdempotencyKey
                        }
                    };

                    await axios.post(`${process.env.REACT_APP_API_URL}/api/createLoginAccess`, {
                    email: email,
                    last_login_date: dateISO,
                    failed_attempts_count: 0,
                    blocked: false
                  }, config);

                  }catch(error){
                    isSubmitting.current = false;
                    stopLoading();
                    setErrorMessage("An unexpected error has occurred");
                    setIsError(true);
                    /* setLoading(false);
                    showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000); */
                    return;
                  }
                  
                }

                //I create the session for the user:
                try{
                  const newSession = await createSession(email);
                  if(newSession.success){
                    await createSessionCookie(newSession.meta);
                    const responseSessionActivated = await initSessionValidation();
                    setActivatedSession(responseSessionActivated.success); 
                  }else{
                    isSubmitting.current = false;
                    stopLoading();
                    setErrorMessage("An unexpected error has occurred");
                    /* setLoading(false);
                    showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000); */
                    attemptCounter.reset();
                    setIsError(true);
                    return;
                  }
                }catch(error){
                  isSubmitting.current = false;
                  stopLoading();
                  setErrorMessage("An unexpected error has occurred");
                  /* setLoading(false);
                  showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000); */
                  attemptCounter.reset();
                  setIsError(true);
                  return;
                }
                isSubmitting.current = false;
                setIsError(false);
                setEmail("");
                setPassword("");
                setIsAuthenticated(true);
                attemptCounter.reset();
                // Save the user data in the UserContext
                const user = response.data;
                
                updateUser(user.email, user.name, user.surname, user.timezone); 
                loadUserData();
           
                navigate('/Home');
                
                
              } else {
                
                const dateConvertedNewAccess = new Date(); 
                const dateISO =dateConvertedNewAccess.toISOString();

                if(emailExistsAccess.exists){
                  //Modify the attempts to +1 of the existing record.
                  // IS IT GREATER THAN 5? IF IT IS, LOCK THE ACCOUNT
                  let failed_attempts = emailExistsAccess.accessRegistered.failed_attempts_count;

                  if(failed_attempts > 5){
                    //Already blocked before...
                    isSubmitting.current = false;
                    attemptCounter.increment();
                    stopLoading();
                    return;
                  }
                  //If it passes through here, it means it wasn't blocked yet.
                  failed_attempts += 1;
                  
                  let blocked;
                  if(failed_attempts > 4){
                    blocked = true;
                    //Close the session in all devices for security.
                    deleteAllSession(email);
                  } 
                  else
                    blocked = false;

                  try {
                    const idAccessRegistered = emailExistsAccess.accessRegistered._id;
                    // Make the PUT request to the server to update the access

                    // Generate a new Idempotency key
                    const newIdempotencyKey = await generateIdempotencyKey();

                    const config = {
                        headers: {
                          'idempotency-key': newIdempotencyKey
                        }
                    };
    
                    await axios.put(`${process.env.REACT_APP_API_URL}/api/updateLoginAccess/${idAccessRegistered}`, {
                      last_login_date: dateISO,
                      failed_attempts_count: failed_attempts,
                      blocked: blocked
                    }, config);
                
                  } catch (error) {
                    isSubmitting.current = false;
                    stopLoading();
                  }

                  if(blocked){
                    //Deactivate the user's account
                    const activated = false;

                    // Generate a new Idempotency key
                    const newIdempotencyKey = await generateIdempotencyKey();

                    const config = {
                        headers: {
                          'idempotency-key': newIdempotencyKey
                        }
                    };

                    try{
                      const update = await axios.put(`${process.env.REACT_APP_API_URL}/api/update-user`, {
                      email: email,
                      activated: activated
                      },config);
                    
                    }catch(error){
                      isSubmitting.current = false;
                      stopLoading();
                    }
                    
                  }

                }else{
                  //Create a new one with the attempts incremented by +1 of the new record.
                  try{
                    // Generate a new Idempotency key
                    const newIdempotencyKey = await generateIdempotencyKey();

                    const config = {
                        headers: {
                          'idempotency-key': newIdempotencyKey
                        }
                    };

                    await axios.post(`${process.env.REACT_APP_API_URL}/api/createLoginAccess`, {
                    email: email,
                    last_login_date: dateISO,
                    failed_attempts_count: 1,
                    blocked: false
                  }, config);

                  }catch(error){
                    isSubmitting.current = false;
                    stopLoading();
                  }

                }
                isSubmitting.current = false;
                attemptCounter.increment();
                stopLoading();
                setErrorMessage(messageResponse);
                setIsError(true);
               
              }
            } catch (error) {
              isSubmitting.current = false;
              stopLoading();
              setErrorMessage("Error. An unexpected error has occurred");
              setIsError(true);

            }
          }else{ //Not exists.
            isSubmitting.current = false;
            /* setLoading(false); */
            attemptCounter.increment();
            stopLoading();
            setErrorMessage("Invalid login credentials. Please try again");
            setIsError(true);

          }
          
        } else {
          isSubmitting.current = false;
          /* setLoading(false); */
          attemptCounter.increment();
          stopLoading();
          setErrorMessage("Error. All inputs must be filled");
          setIsError(true);

        }
      }else{
        isSubmitting.current = false;
        /* setLoading(false); */
        attemptCounter.increment();
        stopLoading();
        setErrorMessage("Error Captcha");
        setIsError(true);

      }
  };

  const sendEmailRecovery = async (gReCaptchaToken) => {

    if (isSubmitting.current) return;
    isSubmitting.current = true;

    if(!emailRecovery.trim()){
      isSubmitting.current = false;
      showMessageError("Error. The email field must to be filled", setIsError, setErrorMessage, 3000);
      return;
    }else if(!isValidEmail(emailRecovery)){
      isSubmitting.current = false;
      showMessageError("Error. Invalid email format", setIsError, setErrorMessage, 3000);
      return;
    }

    startLoading();
    
    const responseCaptcha = await axios.post(`${process.env.REACT_APP_API_URL}/verifyRecaptcha`, {
            captchaValue: gReCaptchaToken,
          });

      if(responseCaptcha.data.success){

        // Validate if user exists
        const emailExists = await comprobateIfExists({ emailRecovery });
        
        if(emailExists.exists){
          const nameRecovery = emailExists.userName;
          try {

            // Generate a new Idempotency key
            const newIdempotencyKey = await generateIdempotencyKey();

            const config = {
                headers: {
                  'idempotency-key': newIdempotencyKey
                }
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/api/reset-password`, {
              emailRecovery,
              nameRecovery
            },config);

          } catch (error) {
            stopLoading();
            isSubmitting.current = false;
            /* console.error('Error authenticating user:', error); */
          }
        }
        stopLoading();
        setForgotenPasswordSended(true); //We go to information part.
        setInvalidToken(false); //To delete message expired tokens.
        isSubmitting.current = false;
      } else {
        stopLoading();
        isSubmitting.current = false;
        showMessageError("Error Captcha", setIsError, setErrorMessage, 3000);
      }
  }

  const forgotPasswordClick = () => {
    setEmailRecovery("");
    setEmail("");
    setPassword("");
    setInvalidToken(false);
    setIsLogin(!isLoginState);
  }

  return (
    <>
    {!forgotenPasswordSended && (
      <div className="login-container">
      { isLoginState && (
        <form onSubmit={comprobateRecaptcha}>
          <h3 className="login-title">Sign In</h3>

          <div className="error-message-container">
            {isError && !unavailableButton ? <p className="message-alert red">{errorMessage}</p> : undefined}
            {unavailableButton ? <p className="message-alert red">Button Disabled, try later.</p> : undefined}
          </div>
          
          <div className="login-attribute">
            <label htmlFor="email">Email</label>
            <input type="email" placeholder="Email" className="login-input"  value={email} onChange={(e) => setEmail(e.target.value)} required autoComplete="username"/>
          </div>

          <div className="login-attribute">
            <label htmlFor="password">Password</label>
            <input type="password" placeholder="Password" className="login-input"  value={password} onChange={(e) => setPassword(e.target.value)} required autoComplete="current-password"/>    
          </div>

          <div className="login-attribute">
            <span className="span-button" onClick={forgotPasswordClick}>Forgot your password?</span>
          </div>

          <div className="submit-button-login">
            <button type="submit" id="signIn" disabled={unavailableButton}>Sign in</button>
          </div>

          <p className="login-createAccount">
            <Link to="/SignUp">Create account</Link>
          </p>
        </form>
      )}

      { !isLoginState && !forgotenPasswordSended && (
        <form onSubmit={comprobateRecaptcha}>
          {!invalidTokenState ? 
          <div>
            <h3 className="login-title">Forgot your Password?</h3> 
            {
              isError 
                ? <p className="message-alert red">{errorMessage}</p> 
                : unavailableButton 
                  ? <p className="message-alert red">Button Disabled, try later</p> 
                  : !isError && !unavailableButton 
                    ? <p className="message-alert grey">Resetting your password also activates your account. If you have lost the activation email or encountered issues, use password reset as an alternative activation method</p> 
                    : undefined
            }
          </div>
          : 
          <div>
            <h3 className="login-title">Invalid token or expired</h3>
            <p className="message-alert orange">The reset link could not be verified. Please request another password reset link by entering your email address below</p>
          </div>}

          

          <div className="login-attribute">
            <label htmlFor="email">Email</label>
            <input type="email" placeholder="Email" className="login-input"  value={emailRecovery} onChange={(e) => setEmailRecovery(e.target.value)} required/>
          </div>

          <div className="submit-button-login">
            <button type="submit" id="signIn" disabled={unavailableButton}>Reset Password</button>
          </div>

          <div className="login-forgot-cancel">
            <span className="span-button" onClick={forgotPasswordClick}>Cancel</span>
          </div>
        </form>
      )}
    </div>
    )}
      
    { !isLoginState && forgotenPasswordSended && (
      <div className="recovery-container-login">
        <h3 className="login-title">Check your inbox</h3>
        <p className="message-alert green">Password reset instructions will be sent to your email address if it matches an account. {emailRecovery}</p>
        <h3 className="login-title">Not received your reset email?</h3>
        <ul className="instructions-recovery">
          <li>Check your spam folder.</li>
          <li>Wait 10 minutes before trying again.</li>
          <li>Check the email address you have entered is spelt correctly.</li>
        </ul>

        <div className="submit-button-login">
          <button type="button" onClick={goToHome}>Go to Home</button>
        </div>
        
      </div>   
    )}
 
    </>
  );
}

export default Login;