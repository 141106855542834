import React, { forwardRef } from 'react';
import Purpose from '../pages/Purpose'; 

const PurposeWrapper = forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <Purpose {...props} /> 
        </div>
    );
});

export default PurposeWrapper;