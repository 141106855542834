import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/Footer.css";

function Footer({isAuthenticated}) {
  return (
    <footer id="footer-container">
        <ul className="footer-list">
          <li className="footer-element">
            {isAuthenticated ? (
              <Link to="/Home">Home</Link>
            ): (
              <Link to="/Home">Home</Link>
            )}
            
          </li> 
          <li className="footer-element">
            <Link to="/Purpose">Purpose</Link>
          </li>

          <li className="footer-element">
            <Link to="/Privacy">Privacy</Link>
          </li>

          <li className="footer-element">
            <Link to="/Contact">Contact</Link>
          </li>
        </ul>
    </footer>
  );
}

export default Footer;