import React, {useState, useEffect, useCallback, useRef } from 'react';
import axios from "axios";
import "../styles/Historial.css";
import "../styles/App.css";
import { useUser  } from '../components/UserContext'; 
import {showMessageSuccess, showMessageError} from "../components/messages.js";
import {initSessionValidation} from "../components/Auth";
import {verifySessionCookie} from "../components/cookies.js";
import { fetchData } from '../components/LoadJobsShifts';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import {calculateHoursBetween, convertToDecimalHours, formatDateToInputValue, convertTo12HFormat, formatDateToInputValueMMDDYYYY, formatDateToInputYYYYMMDD, calculateEarnedShift, validateTime} from "../components/UsefulFunctions.js";
import { useLoading } from '../components/LoadingContext.js';
// Function to generate a unique idempotency key
const generateIdempotencyKey = async () => `Historial-${new Date().getTime()}-${Math.random().toString(36).substring(2, 11)}`;

function Historial({isAuthenticated, activatedSession, setActivatedSession, closedSession, setClosedSession}) {

  const { user, jobs, updateJobs, shifts, updateShifts, logout, chartParamsGraphic, setChartParamsGraphic } = useUser(); 
  const { lastParams, lastUpdate } = chartParamsGraphic;

  const [isManageToolsOpen, setIsManageToolsOpen] = useState(false);
 
  const { startLoading, stopLoading } = useLoading();

  const [selectedJob, setSelectedJob] = useState("All jobs");
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [selectStatus, setSelectStatus] = useState("All");
  const [selectedEditionJob, setSelectedEditionJob] = useState("Select");
  const [filteredShifts, setFilteredShifts] = useState([]);
  const [allOptions, setAllOptions] = useState([]);
  const [allJobsDetails, setAllJobsDetails] = useState([]);
  const [existData, setExistData] = useState(false);
  
  //----------------------------------

  const [nameEditJob, setNameEditJob] = useState("");
  const [positionEditJob, setPositionEditJob] = useState("");
  const [priceHourEditJob, setPriceHourEditJob] = useState("");
  const [dateCreatedEditJob, setDateCreatedEditJob] = useState("");

  const [selectedJobId, setSelectedJobId] = useState("");
  const [selectedShiftId, setSelectedShiftId] = useState(null);

  const [isModalDeleteJob, setIsModalDeleteJob] = useState(false);
  const [isModalWarningDelete, setIsModalWarningDelete] = useState(false);
  const [isModalWarningConfirm, setIsModalWarningConfirm] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  //----------------------------------
  const [nameNewJob, setNameNewJob] = useState("");
  const [positionNewJob, setPositionNewJob] = useState("");
  const [priceHourNewJob, setPriceHourNewJob] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [isCompletedAction, setIsCompletedAction] = useState(false);
  const [isNotCompletedAction, setIsNotCompletedAction] = useState(false);
  const [successfulMessage, setSuccessfulMessage] = useState("");
   
  const [accumulateMoneyFilteredActive, setAccumulateMoneyFilteredActive] = useState(0);
  const [accumulateMoneyFilteredScheduled, setAccumulateMoneyFilteredScheduled] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //------------------------------------

  const [dataLoaded, setDataLoaded] = useState(() => sessionStorage.getItem('dataLoaded') === 'true');

  const [showEditShiftForm, setShowEditShiftForm] = useState(false);
  const [shiftSelected, setShiftSelected] = useState({})
  const [isEditing, setIsEditing] = useState(false);

  const [selectedManageAction, setSelectedManageAction] = useState("");

  const [showEditJobForm, setShowEditJobForm] = useState(false);
  const [showNewJobForm, setShowNewJobForm] = useState(false);
  const [showTable, setShowTable] = useState(true);

  const isSubmitting = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Historial | ClockIn Smart";
  }, []);
  
  const toggleManageTools = () => {
    setIsManageToolsOpen(!isManageToolsOpen);
  };

  useEffect(() => {
    comprobateSession();
  },[isAuthenticated, navigate])

  const comprobateSession = async() => {
    const isSessionActivated = await initSessionValidation();
      if(isSessionActivated.success)
        setActivatedSession(true);
      else{
        setClosedSession(true);
        await logout();
        sessionStorage.removeItem('dataLoaded');
        
      }    
  }

    // Function to disable background scrolling when the modal is opened
  const disableBackgroundScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
  },[]);
    
    // Restore the default scrolling behavior
  const enableBackgroundScroll = useCallback(() => {
    document.body.style.overflow = ''; 
  },[]);

  useEffect(() => {
  },[disableBackgroundScroll, enableBackgroundScroll]);

 const openShowNewJob = () => {
    setShowEditJobForm(false);
    setShowNewJobForm(true);
  };
 
  const closeShowNewJob = () => {
    setShowNewJobForm(false);
    handleManageJobs("manageJob");
  };

  const openModalDeleteJob = () => {
    if (selectedEditionJob === "Select") {
      return;
  }
    disableBackgroundScroll();
    setWarningMessage("Are you sure you want to delete this job?")
    setIsModalDeleteJob(true);
  };
  
  const closeModalDeleteJob = () => {
    enableBackgroundScroll();
    setIsModalDeleteJob(false);
  };

  const openModalDeleteShift = (shiftId) => {
    setSelectedShiftId(shiftId);
    disableBackgroundScroll();
    setWarningMessage("Are you sure you want to delete this shift?");
    setIsModalWarningDelete(true);
  };

  const closeModalDeleteShift = () => {
    enableBackgroundScroll();
    setIsModalWarningDelete(false);
  };

  const openShowShift = (shift) => {
    setShowTable(false);
    setShiftSelected(shift);
    setShowEditShiftForm(true);
  }

  const closeShowShift = () => {
    setShowEditShiftForm(false);
    setIsEditing(false);
    setShowTable(true);
  }

  const openShowEditionJob = () => {
    setShowEditJobForm(true);
  };


  const closeShowEditionJob = () => {
    handleManageJobs("manageJob");
    setSelectedEditionJob("Select");
    setNameEditJob("");
    setPositionEditJob("");
    setPriceHourEditJob("");
    setDateCreatedEditJob("");
    
  }; 

  const showConfirmation = () => { //To change active to scheduled or oppositve
    return new Promise((resolve) => { 
        disableBackgroundScroll();       
        setIsModalWarningConfirm(true); 
        
        const handleConfirm = (isConfirmed) => {
            enableBackgroundScroll();
            resolve(isConfirmed); // Resolve promise with the user's choice
        };
        // Attach handlers directly in the modal UI 
        window.confirmHandler = handleConfirm; // Global handler for simplicity
    });
};

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const alreadyLoaded = dataLoaded;
  
    if (!alreadyLoaded && isAuthenticated) {
      fetchData(user, updateJobs, updateShifts).then(() => {
        if (!alreadyLoaded) { // Verificar de nuevo antes de actualizar
          sessionStorage.setItem('dataLoaded', 'true');
          setDataLoaded(true);
        }
      });
    }
  }, [isAuthenticated, dataLoaded, user, updateJobs, updateShifts]);
  

  useEffect(() => {

    if (!Array.isArray(shifts)) {
      /* console.error("shifts is not an array..."); */
      return;
    }
    let activeShifts = shifts.filter(shift => shift.status === 'active');
    let scheduledShifts = shifts.filter(shift => shift.status === 'scheduled');

    // Filter by selected job if it's not 'All'
    if (selectedJob !== "All jobs") {
        activeShifts = activeShifts.filter(shift => shift.job_id.name === selectedJob);
        scheduledShifts = scheduledShifts.filter(shift => shift.job_id.name === selectedJob);
    }

    // "Filter by selected month if it's not 'All'
    if (selectedMonth !== "All") {
        const filterByMonth = (shift) => {
          const date = new Date(shift.date); 
          const month = date.getUTCMonth(); 
          return month === parseInt(selectedMonth);
        };
        activeShifts = activeShifts.filter(filterByMonth);
        scheduledShifts = scheduledShifts.filter(filterByMonth);
    }

    // Sort active shifts by date in descending order
    activeShifts.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    // Sort scheduled shifts by date in ascending order
    scheduledShifts.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    // Merge the arrays, keeping the scheduled ones at the end, only if the selected state is 'All'
    let filteredShiftsResult = [];
    if (selectStatus === 'All') {
        filteredShiftsResult = [...activeShifts, ...scheduledShifts];
    } else if (selectStatus === 'active') {
        filteredShiftsResult = [...activeShifts];
    } else if (selectStatus === 'scheduled') {
        filteredShiftsResult = [...scheduledShifts];
    }

    setExistData(filteredShiftsResult.length === 0);
    setFilteredShifts(filteredShiftsResult);
}, [shifts, jobs, selectedJob, selectedMonth, selectStatus]);

  useEffect(() => {
    if (!user) return;
    // Filter jobs by the current user
    const userJobs = jobs;
    let options;

    if(userJobs.length !== 0){
      // Retrieve all available job options for the current user
      options = ["All jobs", ...new Set(userJobs.map(job => job.name))];
    
    }else{
      options = ["No jobs", ...new Set(userJobs.map(job => job.name))];
    }
     
    setAllOptions(options);
  }, [jobs, user]);

  useEffect(() => {
    if (!user) return;
    // Filter jobs by the current user
    const userJobs = jobs;
    // Prepare objects containing the ID and name of each job
    const jobDetails = userJobs.map(job => ({ id: job._id, name: job.name }));

    // Add a 'Select' option at the beginning
    if(jobDetails.length !== 0)
      setAllJobsDetails([{ id: '', name: 'Select job' }, ...jobDetails]);
    else
      setAllJobsDetails([{ id: '', name: 'No available jobs' }, ...jobDetails]);
  }, [jobs, user]);


useEffect(() => {
    const selectedJobDetails = jobs.find(job => job.name === selectedEditionJob);

    if (selectedJobDetails) {
      setNameEditJob(selectedJobDetails.name);
      setPositionEditJob(selectedJobDetails.position);
      setPriceHourEditJob(selectedJobDetails.price_hour);
      setDateCreatedEditJob(selectedJobDetails.date_created);
    } else {
      setNameEditJob("");
      setPositionEditJob("");
      setPriceHourEditJob("");
      setDateCreatedEditJob("");
    }
  }, [selectedEditionJob, allJobsDetails]);

  const handleSelectedEditJob = (e) => {
    // Find the selected job based on the name
    const selectedJobDetail = allJobsDetails.find(job => job.name === e.target.value);

    if (selectedJobDetail) {
      setSelectedJobId(selectedJobDetail.id);
      setSelectedEditionJob(selectedJobDetail.name);
    }
  };

  const handleSelectedEditShift = (value, key) => {
    let validValue = value;
    
    if (key === "break_time") {
      if (value.trim() === "") {
        validValue = ""; // If the field is empty, assign an empty string
      } else {
        const numValue = parseFloat(value);
        validValue = !isNaN(numValue) ? numValue : shiftSelected[key]; // If it's not a number, keep the previous value
      }
    }
  
    setShiftSelected(prevState => ({
      ...prevState,
      [key]: validValue // Use the key dynamically to update the correct state
    }));
  };

  const comprobateIfExists = async ({ nameNewJob, emailUser  }) => {
    try {
      // Make a request to the server to check if the job is already registered
      const responseName = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkName`, {
        name: nameNewJob,
        email: emailUser
      });
  
      return responseName.data.exists; // If true, exists, if false, it does not exist, so the job can be created
     
    } catch (error) {
      showMessageError("Ops! Error to verify the email", setIsNotCompletedAction, setErrorMessage, 3000);
      return error;
    }
    
  };

  const deleteShift = async () => {

    if (isSubmitting.current) return;
    isSubmitting.current = true;
    startLoading();
    try {

    // Generate a new Idempotency key
    const newIdempotencyKey = await generateIdempotencyKey();

    const config = {
        headers: {
          'idempotency-key': newIdempotencyKey
        }
    };

        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/deleteShift/${selectedShiftId}`, config);
        
        if(response.data.success){
          isSubmitting.current = false;
          await fetchData(user, updateJobs, updateShifts);
          setIsModalWarningDelete(false);
          stopLoading();
          closeShowShift();
          
          showMessageSuccess(response.data.message, setIsCompletedAction, setSuccessfulMessage, 2500);
  
          setTimeout(() => {
            enableBackgroundScroll();
          },2500);
        
        }else{
          stopLoading();
          isSubmitting.current = false;
          setIsModalWarningDelete(false);
          closeShowShift();
          showMessageError(response.data.message, setIsNotCompletedAction, setErrorMessage, 3000);
          setTimeout(() => {
            enableBackgroundScroll();
          },3000);

        }
        
        
    } catch (error) {
        stopLoading();
        isSubmitting.current = false;
        /* console.error("Error deleting shift:", error); */
    }
};

  const createNewJob = async (e) => {
    e.preventDefault();

    if (isSubmitting.current) return;
    isSubmitting.current = true;
    startLoading();

    const isSessionActivated = await initSessionValidation();
      if(!isSessionActivated.success){
        stopLoading();
        isSubmitting.current = false;
        setClosedSession(true);
        await logout();
        return;
      }

    if(!nameNewJob.trim() || !positionNewJob.trim() || (priceHourNewJob === null || priceHourNewJob === undefined)){
      stopLoading();
      isSubmitting.current = false;
      showMessageError("Ops! All fields must to be filled", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    if(priceHourNewJob < 0){
      stopLoading();
      isSubmitting.current = false;
      showMessageError("Ops! The price/h must to be possitive", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    if(isNaN(priceHourNewJob)){
      stopLoading();
      isSubmitting.current = false;
      showMessageError("Ops! The price/h must to be a number", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }
      const emailUser = user.email;
      
      const jobExists = await comprobateIfExists({ nameNewJob , emailUser });
      
      if(jobExists){
        stopLoading();
        isSubmitting.current = false;
        showMessageError("Ops! The job already exists.", setIsNotCompletedAction, setErrorMessage, 3000);
      }else{
        //Create a new job     
        try {

          // Generate a new Idempotency key
          const newIdempotencyKey = await generateIdempotencyKey();

          const config = {
              headers: {
                'idempotency-key': newIdempotencyKey
              }
          };

          // Make the POST request to the server
          const dateNewJob = new Date().toISOString(); 

          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/createNewJob`, {
            email: emailUser,
            name: nameNewJob.trim(),
            position: positionNewJob.trim(),
            date_created: dateNewJob,
            price_hour: priceHourNewJob
          },config);

          if(response.data.success){
            await fetchData(user, updateJobs, updateShifts);
            stopLoading();
            closeShowNewJob();
            showMessageSuccess(response.data.message, setIsCompletedAction, setSuccessfulMessage, 2500);
            setNameNewJob("");
            setPositionNewJob("");
            setPriceHourNewJob("");

          }else{
            stopLoading();
            showMessageError(response.data.message, setIsNotCompletedAction, setErrorMessage, 3000);
          }
          stopLoading();
          isSubmitting.current = false;

        } catch (error) {
          stopLoading();
          isSubmitting.current = false;
          showMessageError("Ops! The job could not created", setIsNotCompletedAction, setErrorMessage, 3000);
        }
      }
    
  }

  const editShift = async () => {

    const { _id, date, start_time, finish_time, break_time, job_id, status } = shiftSelected;

    if (isSubmitting.current) return;
    isSubmitting.current = true;


    if ((!date || !date.trim() || date === undefined || date === null || date === "Invalid date") || !start_time || !finish_time || !job_id || break_time === undefined || (break_time !== 0 && !break_time)) {
      isSubmitting.current = false;
      showMessageError("Ops! Please check or fill in all fields before saving", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    if(!validateTime(start_time) || !validateTime(finish_time)){
      isSubmitting.current = false;
      showMessageError("Ops! The shift could not updated. Invalid time format", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    if(break_time < 0 || break_time > 23.99){
      isSubmitting.current = false;
      showMessageError("Ops! The break time must to be possitive and less than 24h", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    if (isNaN(Date.parse(date))) {
      isSubmitting.current = false;
      showMessageError("Ops! The shift could not updated. Invalid date format", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    const userTimezone = user.timezone ? user.timezone : 'UTC';

    const timezoneOffset = moment.tz(userTimezone).utcOffset() / 60;  // Offset en horas

    const adjustedHour = timezoneOffset < 0 ? -timezoneOffset : 0;

    const currentUserMoment  = moment().tz(userTimezone);

    // Extract the date and time components from the moment object
    const yearUser = currentUserMoment.year();
    const monthUser = currentUserMoment.month(); // January is 0, December is 11
    const dateUser = currentUserMoment.date();
    const hourUser = currentUserMoment.hour();
    const minuteUser = currentUserMoment.minute();
    const secondUser = currentUserMoment.second();
    const millisecondUser = currentUserMoment.millisecond();

    const today = new Date(yearUser, monthUser, dateUser, hourUser, minuteUser, secondUser, millisecondUser);

    //The date obtained from the calendar needs to be converted to the user's UTC so that it always matches
    let newStatus;
    // Create a moment object in UTC from the ISO date
    const momentUtcDate = moment.utc(date).startOf('day').toISOString();

    ///////////////////////////
    const dateShift = new Date(momentUtcDate);

    var userTimezoneOffset = dateShift.getTimezoneOffset() * 60000;
    const newDateObj = new Date(dateShift.getTime() + userTimezoneOffset);
    ///////////////////////////

    // When comparing dates, it will be taken into account that when the user selects a date, it will be in the format 00:00h, that is,
    // it would be the start of the day, while 'today' is the exact time of the user.
    const wasActive = status === 'active';
    const isFutureDate = newDateObj > today;

    let isoDate_toSave;

    // If it's for changing it
    if(wasActive && isFutureDate){ // If it's to switch from active to scheduled, it will always be set at 00:00h
      isoDate_toSave = moment.utc(date).startOf('day').add(0, 'hours').toISOString();
    
    }else if (!wasActive && !isFutureDate){ // If it's to switch from scheduled to active, additional hours will be added if the UTC is negative.
      isoDate_toSave = moment.utc(date).startOf('day').add(adjustedHour, 'hours').toISOString();
    
    }else if (wasActive && !isFutureDate) { // If it's to switch from Active to Active
      isoDate_toSave = moment.utc(date).startOf('day').add(adjustedHour, 'hours').toISOString();
      
    }else{ // If it's to switch from scheduled to scheduled (!wasActive && isFuture)
      isoDate_toSave = moment.utc(date).startOf('day').add(0, 'hours').toISOString();
    } 

    

    if ((wasActive && isFutureDate) || (!wasActive && !isFutureDate)) {

        newStatus = isFutureDate ? 'scheduled' : 'active';
        const message = `This shift is currently marked as ${status}. Changing the date to ${newDateObj.toDateString()} will change its status to ${newStatus}. Are you sure you want to proceed?`;
        setWarningMessage(message);
        const confirmation = await showConfirmation();

        if (!confirmation) {
          isSubmitting.current = false;
          setIsModalWarningConfirm(false);
          return;
        }
    
    }else{
      newStatus = status;
    }

    startLoading();

    if(wasActive && isFutureDate){
      //Check if it doesn't exceed the maximum number it can have of scheduled:
      try{

        const token = await verifySessionCookie();

          if (!token) {
              stopLoading();
              return;
          }

          // Configuración de la cabecera de autorización y los parámetros de la consulta
          const config = {
              headers: {
                  Authorization: `Bearer ${token}` // Aquí se incluye el token en el encabezado
              }
          };
        
        const email = user.email;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/countScheduledShifts/${email}`,config);

        if(!response.data.success){
          stopLoading();
          isSubmitting.current = false;
          showMessageError("Ops! Error to count the scheduled Shifts. Please try again. If the issue persists, contact support", setIsNotCompletedAction, setErrorMessage, 3000);
          return;
      }
      
      const countScheduledShifts = response.data.countScheduledShifts;

      if(countScheduledShifts >= 30){
        stopLoading();
        isSubmitting.current = false;
        const message = `Ops! You have reached the limit of scheduled shifts. Currently you have scheduled ${countScheduledShifts} shifts, and you cannot add more`
        
        setIsModalWarningConfirm(false);
        showMessageError(message, setIsNotCompletedAction, setErrorMessage, 3000);
        return;
      }

      }catch(error){
        stopLoading();
        isSubmitting.current = false;
        showMessageError("Ops! Error to count the scheduled Shifts. Please try again. If the issue persists, contact support", setIsNotCompletedAction, setErrorMessage, 3000);
      }
    }

    const break_time2Decimals = parseFloat(break_time).toFixed(2);

    // calculate the new total_time and earned_shift
    const timeString  = calculateHoursBetween(start_time, finish_time);
    const timeDecimal = convertToDecimalHours(timeString);
    const totalTime  = parseFloat(timeDecimal - break_time2Decimals).toFixed(2);

    if(totalTime <= 0){
      stopLoading();
      isSubmitting.current = false;
      showMessageError("Ops! Break time cannot exceed the total shift duration", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }
   
    const price_hour = shiftSelected.job_id.price_hour;
    const earnedShift = parseFloat(calculateEarnedShift(price_hour, totalTime)).toFixed(2);
    
    try {

        // Generate a new Idempotency key
        const newIdempotencyKey = await generateIdempotencyKey();

        const config = {
            headers: {
              'idempotency-key': newIdempotencyKey
            }
        };

        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update-shift/${_id}`, {
            date: isoDate_toSave,
            start_time: start_time,
            finish_time: finish_time,
            total_time: totalTime,
            break_time: break_time2Decimals,
            earned_shift: earnedShift,
            id_schedule: null,
            status: newStatus
        },config);

        if(response.data.success){
          isSubmitting.current = false;
          await fetchData(user, updateJobs, updateShifts);
          stopLoading();
          setIsModalWarningConfirm(false);
          closeShowShift();
          
          showMessageSuccess(response.data.message, setIsCompletedAction, setSuccessfulMessage, 2500);
        
        }else{
          stopLoading();
          isSubmitting.current = false;
          setIsModalWarningConfirm(false);
          showMessageError(response.data.message, setIsNotCompletedAction, setErrorMessage, 3000);
        }      

    } catch (error) {
        stopLoading();
        isSubmitting.current = false;
        setIsModalWarningConfirm(false);
        showMessageError('Ops! The shift could not updated', setIsNotCompletedAction, setErrorMessage, 3000);
    }
};

  const deleteJob = async () => {
    if (!selectedJobId) {
        showMessageError('Ops! Select a job to delete', setIsNotCompletedAction, setErrorMessage, 3000);
        return;
    }

    if (isSubmitting.current) return;
    isSubmitting.current = true;

    startLoading();

    const isSessionActivated = await initSessionValidation();
      if(!isSessionActivated.success){
        stopLoading();
        isSubmitting.current = false;
        setClosedSession(true);
        await logout();
        return;
      }

    try {

      // Generate a new Idempotency key
      const newIdempotencyKey = await generateIdempotencyKey();

      const config = {
          headers: {
            'idempotency-key': newIdempotencyKey
          }
      };

        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/deleteJob/${selectedJobId}`, config);
        if(response.data.success){
          isSubmitting.current = false;
          await fetchData(user, updateJobs, updateShifts);
          
          handleManageJobs("manageJob");
          stopLoading();
          /* closeShowEditionJob(); */
          closeModalDeleteJob();
          /* setShowEditJobForm(false); */
          
          showMessageSuccess(response.data.message, setIsCompletedAction, setSuccessfulMessage, 2500);
          
          setSelectedJob("All jobs"); //Para cerrar la tabla del job que no existe.
          /* setSelectedEditionJob("Select"); */
           // Cierra el modal de confirmación
          
          setShowTable(true);
        
        }else{
          stopLoading();
          isSubmitting.current = false;
          closeModalDeleteJob();
          showMessageError("Ops! The job could not deleted. Please, try again. If the issue persists, contact support", setIsNotCompletedAction, setErrorMessage, 3000);
        }
        
    } catch (error) {
      stopLoading();
      isSubmitting.current = false;
      closeModalDeleteJob();
      showMessageError("Ops! The job could not deleted. Please, try again. If the issue persists, contact support", setIsNotCompletedAction, setErrorMessage, 3000);
    }
  };

  const updateJob = async (e) => {
    e.preventDefault();

    if (isSubmitting.current) return;
    isSubmitting.current = true;

    startLoading();
    
    const isSessionActivated = await initSessionValidation();
      if(!isSessionActivated.success){
        stopLoading();
        isSubmitting.current = false;
        setClosedSession(true);
        await logout();
        return;
      }  

    if (!selectedJobId || !nameEditJob.trim() || !positionEditJob.trim() || (priceHourEditJob === null || priceHourEditJob === undefined)) {
      stopLoading();
      isSubmitting.current = false;
      showMessageError("Ops! All fields must to be filled", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    if(isNaN(priceHourEditJob)){
      stopLoading();
      isSubmitting.current = false;
      showMessageError("Ops! The price per hour must to be a number", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    if (priceHourEditJob < 0){
      stopLoading();
      isSubmitting.current = false;
      showMessageError("Ops! The price per hour must to be possitive", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    try {

      // Generate a new Idempotency key
      const newIdempotencyKey = await generateIdempotencyKey();

      const config = {
          headers: {
            'idempotency-key': newIdempotencyKey
          }
      };

        const responseUpdate = await axios.put(`${process.env.REACT_APP_API_URL}/api/updateJob/${selectedJobId}`, {
            name: nameEditJob.trim(),
            position: positionEditJob.trim(),
            price_hour: priceHourEditJob
        }, config);

        if(responseUpdate.data.success){
          isSubmitting.current = false;
          const { updatedJob, updatedShifts } = responseUpdate.data;

          // Update the global jobs state
          updateJobs(prevJobs => prevJobs.map(job => job._id === updatedJob._id ? updatedJob : job));

          // Check if updatedShifts exists in the response before updating the shifts
          if (updatedShifts && updatedShifts.length > 0) {
              updateShifts(prevShifts => {
                  const updatedShiftsMap = updatedShifts.reduce((acc, shift) => ({ ...acc, [shift._id]: shift }), {});
                  return prevShifts.map(shift => updatedShiftsMap[shift._id] ? updatedShiftsMap[shift._id] : shift);
              });
          }
          await fetchData(user, updateJobs, updateShifts);
          stopLoading();
          showMessageSuccess(responseUpdate.data.message, setIsCompletedAction, setSuccessfulMessage, 2500);
          closeShowEditionJob(); 
          
        }else{
          stopLoading();
          isSubmitting.current = false;
          showMessageError("Error. Failed to update job and shifts.", setIsNotCompletedAction, setErrorMessage, 3000);
        }

    } catch (error) {
        stopLoading();
        isSubmitting.current = false;
        showMessageError("Error. Failed to update job and shifts.", setIsNotCompletedAction, setErrorMessage, 3000);
    }
    
};

const calculateTotalEarned = useCallback(() => {
  let totalEarnedActive = 0;
  let totalEarnedScheduled = 0;

  filteredShifts.forEach(shift => {
    if (shift.status === 'active') {
      totalEarnedActive += shift.earned_shift;
    } else if (shift.status === 'scheduled') {
      totalEarnedScheduled += shift.earned_shift;
    }
  });

  setAccumulateMoneyFilteredActive(totalEarnedActive);
  setAccumulateMoneyFilteredScheduled(totalEarnedScheduled);
}, [filteredShifts]);

useEffect(() => {
  calculateTotalEarned();
}, [calculateTotalEarned]);

  const goSignIn = () => {
    setClosedSession(false); // To make the logout message disappear
    navigate('/Login');
  }

  const handleManageJobs = (e) => {
    let action;
    if (typeof e === 'string') {
      action = e;
    } else if (e && e.target) {
      action = e.target.value;
    }

    setSelectedManageAction(action);
    
    switch (action) {
      
      case 'newJob':
        setShowTable(false);
        setShowEditJobForm(false);
        setShowEditShiftForm(false);
        openShowNewJob();
        break;
      case 'editJob':
        setShowTable(false);
        setShowNewJobForm(false);
        setShowEditShiftForm(false);
        openShowEditionJob();
        break;
      case 'manageJob':
        setShowTable(true);
        setShowNewJobForm(false);
        setShowEditJobForm(false);
        setShowEditShiftForm(false);
        setSelectedEditionJob("Select");
      break;
      default:
        break;
    }
  };

  //---------------------------------------------------------

  return (
    <>
    {isAuthenticated && activatedSession && !closedSession && (
      <div className="historial-container">
        <div className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-panel form" : "control-panel"}>

          {(windowWidth > 560 && ((!showNewJobForm && !showEditJobForm && !showEditShiftForm))) && (
            <>
              <div className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-group form" : "control-group"}>
              <select id="job-select" value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)} className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-button form" : "control-button"}>
                {allOptions.map(option => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
            
            <div className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-group form" : "control-group"}>
              <select id="month-select" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-button form" : "control-button"}>
                <option value="All">All Months</option>
                <option value={0}>January</option>
                <option value={1}>February</option>
                <option value={2}>March</option>
                <option value={3}>April</option>
                <option value={4}>May</option>
                <option value={5}>June</option>
                <option value={6}>July</option>
                <option value={7}>August</option>
                <option value={8}>September</option>
                <option value={9}>October</option>
                <option value={10}>November</option>
                <option value={11}>December</option>
              </select>
            </div>

            <div className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-group form" : "control-group"}>
              <select id="month-select" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)} className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-button form" : "control-button"}>
                <option value="All">All status</option>
                <option value="active">Actived</option>
                <option value="scheduled">Scheduled</option>
              </select>
            </div>

            <div className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-group form" : "control-group"}>
              <select id="manage-job" value={selectedManageAction} onChange={handleManageJobs}  className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-button form" : "control-button"}>
                <option value="manageJob">Manage jobs</option> 
                <option value="newJob">New job</option>
                <option value="editJob">Edit jobs</option>
              </select>
            </div>
          </>
          )}
          
          {((windowWidth <= 560) || ((showNewJobForm || showEditJobForm || showEditShiftForm))) && (
            <>
              <div className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-group form" : "control-group"}>
                <button onClick={toggleManageTools} className={(showNewJobForm || showEditJobForm || showEditShiftForm) ? "control-button form" : "control-button"}>{!isManageToolsOpen ? ("Manage Tools"): ("Close Tools")}</button>
                {isManageToolsOpen && (
                  <div className="manage-tools-dropdown">
                    {!showNewJobForm && !showEditJobForm && !showEditShiftForm && (
                      <div className="dropdown-item">
                        <select id="job-select-mobile" value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)}>
                          {allOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                          ))}
                        </select>
                      </div>
                    )}

                    {!showNewJobForm && !showEditJobForm && !showEditShiftForm && (
                      <div className="dropdown-item">
                        <select id="month-select-mobile" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                        <option value="All">All Months</option>
                          <option value={0}>January</option>
                          <option value={1}>February</option>
                          <option value={2}>March</option>
                          <option value={3}>April</option>
                          <option value={4}>May</option>
                          <option value={5}>June</option>
                          <option value={6}>July</option>
                          <option value={7}>August</option>
                          <option value={8}>September</option>
                          <option value={9}>October</option>
                          <option value={10}>November</option>
                          <option value={11}>December</option>
                        </select>
                      </div>
                    )}

                    {!showNewJobForm && !showEditJobForm && !showEditShiftForm && (
                      <div className="dropdown-item">
                        <select id="status-select-mobile" value={selectStatus} onChange={(e) => setSelectStatus(e.target.value)}>
                          <option value="All">All status</option>
                          <option value="active">Actived</option>
                          <option value="scheduled">Scheduled</option>
                        </select>
                      </div>
                    )}

                    <div className="dropdown-item">
                      <select id="manage-job-mobile" value={selectedManageAction} onChange={handleManageJobs}>
                        <option value="manageJob">{(showNewJobForm || showEditJobForm || showEditShiftForm) ? "Show table" : "Manage jobs"}</option>
                        <option value="newJob">New job</option>
                        <option value="editJob">Edit jobs</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          

        </div>


      {/* -------------------------------------------------- */}
      {showTable && (
        <>
          <table className="historial-table">
            <thead>
              <tr>
                <th className="historial-header">Job</th>
                {windowWidth > 900 && (
                  <>
                    <th className="historial-header">Position</th>
                  </>
                )} 
                <th className="historial-header">Date</th>
                
                {windowWidth > 660 && (
                  <>
                    <th className="historial-header">Status</th>
                    <th className="historial-header">Start</th>
                    <th className="historial-header">Finish</th>
                    <th className="historial-header">Break</th>
                  </>
                )}

                {windowWidth > 900 && (
                  <>
                    <th className="historial-header">Total time</th>
                    <th className="historial-header">$/h</th>  
                  </>
                )}
                <th className="historial-header">$/day</th>
                {windowWidth > 900 && (
                  <>  
                    <th className="historial-header">Edit</th>
                    <th className="historial-header">Delete</th>
                  </>
                )}
                {windowWidth <= 900 && (
                  <>
                    <th className="historial-header">Details</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {filteredShifts.map(shift => {

                return (
                  <tr key={shift._id}>
                    {windowWidth > 660 && (
                       <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'} name-job-big`}>{shift.job_id.name}</td>
                    )}
                    {windowWidth <= 660 && (
                       <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'} name-job-small`}>{shift.job_id.name}</td>
                    )}
                   
                    {windowWidth > 900 && (
                      <>
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}>{shift.job_id.position}</td>
                      </>
                    )}
                    <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}>{shift.status === 'scheduled' ? (formatDateToInputValueMMDDYYYY(shift.date)) : (formatDateToInputValueMMDDYYYY(shift.date, user.timezone))}</td>
                    
                    {windowWidth > 660 && (
                      <>
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}>{shift.status}</td>
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}>{convertTo12HFormat(shift.start_time)}</td>  
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}>{convertTo12HFormat(shift.finish_time)}</td>
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}>{shift.break_time} H</td>
                      </>
                    )}
                    {windowWidth > 900 && (
                      <>
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}>{shift.total_time} H</td>
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}>{shift.job_id.price_hour}</td>
                      </>
                    )}
                    <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}>{shift.earned_shift.toFixed(2)}</td>
                    {windowWidth > 900 && (
                      <>  
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}><button onClick={() => openShowShift(shift)}>Edit</button></td>
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}><button onClick={() => openModalDeleteShift(shift._id)}>Delete</button></td>
                      </>
                    )}
                    {windowWidth <= 900 && (
                      <>
                        <td className={`historial-cell ${shift.status === 'scheduled' ? 'scheduled-shift' : 'activated-shift'}`}><button onClick={() => openShowShift(shift)}>Details</button></td>
                      </>
                    )

                    }
                  </tr>
                )})}
            </tbody>
          </table>
          <div className={!existData ? "money-box" : "money-box empty"}>
            {existData ? (
              <>
                {allOptions.length <= 1 ? ( 
                  <div className="start-guide">
                    {allOptions[0] === "Select" || allOptions[0] === "No jobs" ? (
                      // No jobs created
                      <>
                        <h2>Getting Started</h2>
                        <p>First, create a job in the 'Manage Jobs' section.</p>
                        <p>Then, log your shifts from the Home page.</p>
                      </>
                    ) : (
                      // There are jobs created, but there are no shifts
                      <>
                        <h2>Getting Started</h2>
                        <p>Log your shifts from the Home page.</p>
                      </>
                    )}
                  </div>
                ) : shifts.length === 0 ? (
                  // There are jobs created, but no shifts are registered
                  <div className="start-guide">
                    <h2>Getting Started</h2>
                    <p>Log your shifts from the Home page.</p>
                  </div>
                ) : (
                  // There are jobs and there are shifts, but they do not match the selected filters
                  <h2>There are no shifts records matching these filters</h2>
                )}
              </>
            ) : (
              // There are data matching the filters
              <>
                <div className="earnings-container">
                  <span>Total Active</span>
                  <span>${accumulateMoneyFilteredActive.toFixed(2)}</span>
                </div>
                <div className="earnings-container">
                  <span>Total Scheduled</span>
                  <span>${accumulateMoneyFilteredScheduled.toFixed(2)}</span>
                </div>
              </>
            )}
          </div>
        </>
      )}
      
      {showNewJobForm && (
            <form className="newJob-form" onSubmit={createNewJob} >
              <h3 className="newJob-title">New Job</h3>

              <div className="newJob-attribute">
                  <label htmlFor="name">Name of job</label>
                  <input type="text" className="form-newJob" placeholder="Name of job" value={nameNewJob} onChange={(e) => setNameNewJob(e.target.value)} required></input>
              </div>

              <div className="newJob-attribute">
                  <label htmlFor="position">Position</label>
                  <input type="text" className="form-newJob" placeholder="Position" value={positionNewJob}  onChange={(e) => setPositionNewJob(e.target.value)} required></input>
              </div>

              <div className="newJob-attribute">
                  <label htmlFor="price">Price/h</label>
                  <input type="number" className="form-newJob" placeholder="Price/h" min="0" step="0.01" value={priceHourNewJob} onChange={(e) => setPriceHourNewJob(e.target.value)} required></input>
              </div>
              
              <div className="save-cancel-button-job">
                <button className="historial-cancel-button" type="button" onClick={closeShowNewJob} >Cancel</button>
                <button className="button-new-job"type="submit">Save</button>
              </div>
              
            </form>   
      )}

      {isModalDeleteJob && (
        <div className="modal-overlay">
          <div className="modal-confirmation">
            <h3 className="confirmation-title">Confirmation</h3>
            <p className="message-alert red">All shifts associated with this job will be deleted</p>
      
            <p className="confirmation-message">{warningMessage}</p> 
            <div className="confirmation-buttons">
              <button className="cancel-button" onClick={closeModalDeleteJob}>Cancel</button>
              <button className="delete-button" onClick={deleteJob}>Delete</button>
            </div>
          </div>
        </div>
      )}

      {isModalWarningDelete && (
        <div className="modal-overlay">
          <div className="modal-confirmation">
            <h3 className="confirmation-title">Confirmation</h3>
            <p className="confirmation-message">{warningMessage}</p>
            <div className="confirmation-buttons">
              <button className="cancel-button" onClick={closeModalDeleteShift}>Cancel</button>
              <button className="delete-button" onClick={deleteShift}>Delete</button>
            </div>
          </div>
        </div>
      )}

      {isModalWarningConfirm && (
        <div className="modal-overlay">
          <div className="modal-confirmation">
          <h3 className="confirmation-title">Confirmation</h3>
            <p className="confirmation-message">{warningMessage}</p>
            <div className="confirmation-buttons">
                <button className="cancel-button" onClick={() => window.confirmHandler(false)}>Cancel</button>
                <button className="confirm-button" onClick={() => window.confirmHandler(true)}>Confirm</button>
            </div>
          </div>
        </div>
      )}
      
      {isCompletedAction && (
          <>  
            <div className="modal-overlay"> 
            <div className="modal-success">
              <div className="modal-header">
                <img src="/success-icon.webp" alt="Success" width="128" height="128"  /> 
                <h3>Completed action!</h3>
              </div>
              <div className="modal-body">
                <p>{successfulMessage}</p>
                  
              </div>
            </div>
          </div>
          
        </>
    
        )
      }

        {isNotCompletedAction && (
          <>  
            <div className="modal-overlay"> 
            <div className="modal-error">
              <div className="modal-header">
                <img src="/error-icon.webp" alt="Error" width="128" height="128"  /> 
                <h3>Error!</h3>
              </div>
              <div className="modal-body">
                <p>{errorMessage}</p>
                <p><small>Please try again. If the issue persists, contact support.</small></p>
              </div>
            </div>
          </div>
          
        </>
    
        )
      }

      {showEditJobForm  && (
            <form className="editJob-form" onSubmit={updateJob} >
              <h3 className="editJob-title">Edit Job</h3>
              <p className="message-alert orange">Select a job before attempting to edit.</p>
              <div className="editJob-attribute">
                  <label htmlFor="name">Name of job</label>
                  <select id="options" className="form-editJob" value={selectedEditionJob} onChange={handleSelectedEditJob}>
                    {allJobsDetails.map(job => (
                      <option key={job.id} value={job.name}>{job.name}</option>
                    ))}
                  </select>
              </div>

              <div className="editJob-attribute">
                  <label htmlFor="position">Name of Job</label>
                  <input type="text" className="form-editJob" placeholder="Name of job" value={nameEditJob}  onChange={(e) => setNameEditJob(e.target.value)} disabled={((selectedEditionJob === "Select job") || (selectedEditionJob === "Select"))} ></input>
              </div>

              <div className="editJob-attribute">
                  <label htmlFor="position">Position</label>
                  <input type="text" className="form-editJob" placeholder="Position" value={positionEditJob}  onChange={(e) => setPositionEditJob(e.target.value)} disabled={((selectedEditionJob === "Select job") || (selectedEditionJob === "Select"))} ></input>
              </div>

              <div className="editJob-attribute">
                  <label htmlFor="price">Price/h</label>
                  <input type="number" className="form-editJob" placeholder="Price/h" value={priceHourEditJob} min="0" step="0.01" onChange={(e) => setPriceHourEditJob(e.target.value)} disabled={((selectedEditionJob === "Select job") || (selectedEditionJob === "Select"))} ></input>
              </div>  

              <div className="editJob-attribute">
                  <label htmlFor="date">Date of creation (UTC+0)</label>
                  <input type="date" className="form-editJob" value={formatDateToInputValue(dateCreatedEditJob)} style={{backgroundColor: 'gray'}} readOnly ></input>
              </div>

              <div className="save-cancel-button-job">
                <button className="historial-cancel-button" type="button" onClick={closeShowEditionJob} >Cancel</button>
                <button className="button-new-job"type="submit" disabled={((selectedEditionJob === "Select job") || (selectedEditionJob === "Select"))}>Edit</button>
                {((selectedEditionJob === "Select job") || (selectedEditionJob === "Select")) ? (""): (<p><span className="delete-job" onClick={openModalDeleteJob}>Delete job</span></p>)}
                    
              </div>          
            </form>
      )}
{/* ************************************************************************************************************* */}
{showEditShiftForm && (
      <form className="shift-form">
        <h3 className="shift-title">Shift Details for "{shiftSelected.job_id.name}"</h3>
        <p className="message-alert orange">The immutable fields belong to the job</p>

        <div className="shift-attribute">
          <label>Name of company:</label>
          <input type="text" value={shiftSelected.job_id.name} readOnly disabled />
        </div>
        <div className="shift-attribute">
          <label>Position:</label>
          <input type="text" value={shiftSelected.job_id.position} readOnly disabled />
        </div>
        <div className="shift-attribute">
          <label>Price/h:</label>
          <input type="number" value={shiftSelected.job_id.price_hour} min="0" readOnly disabled />
        </div>

        {/* Editable only if isEditing is true */}
        <div className="shift-attribute">
            <label>Date of the record:</label>
            <input type="date" value={shiftSelected.status === "scheduled" ? (formatDateToInputYYYYMMDD(shiftSelected.date)):(formatDateToInputYYYYMMDD(shiftSelected.date, user.timezone))} onChange={(e) => handleSelectedEditShift(e.target.value, 'date')} disabled={!isEditing} required/>
        </div>
        <div className="shift-attribute">
            <label>Start time:</label>
            <input type="time" value={shiftSelected.start_time} onChange={(e) => handleSelectedEditShift(e.target.value, 'start_time')} disabled={!isEditing} required/>
        </div>
        <div className="shift-attribute">
            <label>Finish time:</label>
            <input type="time" value={shiftSelected.finish_time} onChange={(e) => handleSelectedEditShift(e.target.value, 'finish_time')} disabled={!isEditing} required/>
        </div>

        <div className="shift-attribute">
            <label>Break time (H):</label>
            <input type="number" value={shiftSelected.break_time} onChange={(e) => handleSelectedEditShift(e.target.value, 'break_time')} min="0" max="23.99" step="0.01" disabled={!isEditing} required/>
        </div>

        {/***************************************** */}

        <div className="shift-attribute">
          <label>Total time (H):</label>
          <input type="number" value={shiftSelected.total_time} min="0" readOnly disabled />
        </div>

        <div className="save-cancel-button-job">
          {isEditing ? (
            <>
              <button type="button" className="historial-cancel-button" onClick={closeShowShift}>Cancel</button>
              <button type="button" onClick={editShift}>Save</button>
            </>
          ) : (
            <>
              <button type="button" className="historial-cancel-button" onClick={closeShowShift}>Show table</button>
              <button type="button" onClick={() => setIsEditing(true)}>Edit</button>      
            </>
          )}
          {windowWidth <= 900 && (<p><span className="delete-shift" onClick={() => openModalDeleteShift(shiftSelected._id)}>Delete Shift</span></p>)}
        </div>
      </form>
      )}

    </div>  
  /***************************************************************************************** */
    )}

      { (closedSession || !isAuthenticated) && (
        <div className="modal-no-session">
          <div className="modal-confirmation-no-session">
              <h3 className="confirmation-title-no-session">Notification</h3>
              <p className="confirmation-message-no-session">Your session has been closed or the password has been changed, please try to sign in again.</p>
              <div className="confirmation-buttons-no-session">
              <button className="button-no-session" onClick={goSignIn}>Sign in</button>
              </div>
          </div>
        </div>
      )}


    </>
  );
}

export default Historial;