import React, {useState, useEffect} from 'react';
import { Bar } from 'react-chartjs-2';
import "../styles/App.css"
import "../styles/Graphics.css"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ chartData, title, selectedMetric }) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  //Listen for window resize events
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!chartData || !chartData.datasets) {
    return <div className="error-data-graphic">No data available, try to refresh the page</div>;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    barThickness: windowWidth < 800 ? 30 : 50, // Set the bar width to 50px
    maxBarThickness: windowWidth < 800 ? 30 : 50, // Ensure it doesn't exceed 50px
    categoryPercentage: 0.7, // Adjust the percentage of category space
    barPercentage: 0.5, // Adjust the percentage of bar space within its category    
    minBarLength: 5,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: selectedMetric === 'workedHours' ? 'Work Hours (h)' : 'Money Earned ($)'
        },
        ticks: {
            callback: function(value) {
                //Change the display from 0.1 to 0 on the Y-axis
                return value === 0.01 ? '0' : value;
            },
            maxTicksLimit: 10,
            precision: 0
        }
      },
      x: {
        type: 'category',
       
        title: {
          display: true,
          text: 'Days or Periods'
        }
      }
    },
    animation: {
      duration: 1300,
      onComplete: () => {
        //Ensure that the chart is drawn from zero after each update
        if (chartData.datasets) {
          chartData.datasets.forEach(dataset => {
            dataset.data.forEach((value, index) => {
              dataset.data[index] = value; //This will force Chart.js to recognize a change in the data and redraw
            });
          });
        }
      }
    }
  ,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        align: 'center',
        font: {
          size: 20
        }
      },
      transitions: {
        hide: {
            animations: {
                y: {
                    from: 0
                }
            }
        },
        show: {
            animations: {
                y: {
                    from: 0
                }
            }
        }
    },
      tooltip: {
        callbacks: {
          label: function(context) {
            const totalHours = context.chart.data.datasets[0].data[context.dataIndex];
            const totalEarned = context.chart.data.datasets[1].data[context.dataIndex]; 
            return [
              `Worked hours: ${totalHours === 0.01 ? '0' : totalHours.toFixed(2)} h`,
              `Total Earned: $${totalEarned === 0.01 ? '0' : totalEarned.toFixed(2)}`
            ];
          }
        }
      }
    },
    elements: {
      bar: {
        borderWidth: 2,
        borderColor: '#ccc',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }
    }
  };

  const data = {
    labels: chartData.labels,
    datasets: chartData.datasets.map(dataset => {
      if (selectedMetric === 'workedHours' && dataset.label === 'Worked Hours') {
          return { ...dataset, hidden: false };
      } else if (selectedMetric === 'moneyEarned' && dataset.label === 'Total Earned') {
          return { ...dataset, hidden: false };
      } else {
          return { ...dataset, hidden: true };
      }
    })
  };

  return <Bar data={data} options={options} />;
}

export default BarChart;