import React, { useState, useCallback, useEffect, useRef } from 'react';
import "../styles/Home.css";
import "../styles/Contact.css";
import "../styles/App.css";
import axios from 'axios';
import {useNavigate, Link} from "react-router-dom";
import {isValidEmail} from "../components/UsefulFunctions.js";
import {showMessageSuccess, showMessageError} from "../components/messages.js";
import {setBodyClass} from "../components/ShowRecaptcha.js";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLoading } from '../components/LoadingContext.js';

// Function to generate a unique idempotency key
const generateIdempotencyKey = async () => `Contact-${new Date().getTime()}-${Math.random().toString(36).substring(2, 11)}`;

function Contact() {

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [privacyAccepted, setPrivacyAccepted] = useState(false);

  const { startLoading, stopLoading } = useLoading();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [successfulMessage, setSuccessfulMessage] = useState("");

  const isSubmitting = useRef(false);
  
  const navigate = useNavigate(); 

//----
const { executeRecaptcha } = useGoogleReCaptcha();
//----

useEffect(() => {
  document.title = "Contact | ClockIn Smart";
}, []);

useEffect(() => {
  setBodyClass("contact-page", true);
  return () => {
    setBodyClass("contact-page", false);
  };
}, []);

const handlePrivacyCheckboxChange = (e) => {
  setPrivacyAccepted(e);
};

  const comprobateRecaptcha = useCallback( (e) => {
    e.preventDefault();

    if(fullName.trim() === "" || email.trim() === "" || subject.trim() === "" || message.trim() === ""){
      showMessageError("Error. All fields must to be filled", setIsError, setErrorMessage, 3000);
      return;
    }

    if(privacyAccepted === false){
      showMessageError("Error. The privacy policy must to be accepted", setIsError, setErrorMessage, 3000);
      return;
    }

    if(!isValidEmail(email)){
      showMessageError("Error. Invalid email format", setIsError, setErrorMessage, 3000);
      return;
    }

    if (!executeRecaptcha) {    
      showMessageError("Error. Execute recaptcha not yet available", setIsError, setErrorMessage, 3000);
      return;
    }

    executeRecaptcha("formRecaptcha").then((gReCaptchaToken) => {
      contactUser(gReCaptchaToken);
    });
    
  },[executeRecaptcha, privacyAccepted, fullName, email, subject, message, privacyAccepted, isError, errorMessage]);

  const contactUser = async (gReCaptchaToken) => {

    if (isSubmitting.current) return;
    isSubmitting.current = true;

    startLoading();

    const responseCaptcha = await axios.post(`${process.env.REACT_APP_API_URL}/verifyRecaptcha`, {
            captchaValue: gReCaptchaToken,
          });

       if(responseCaptcha.data.success){
        
        const currentDate = new Date();
        const dateISO =currentDate.toISOString();

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {

          // Generate a new Idempotency key
          const newIdempotencyKey = await generateIdempotencyKey();

          const config = {
              headers: {
                'idempotency-key': newIdempotencyKey
              }
          };

          // Make the POST request to the server
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, {
            full_name: fullName,
            email: email,
            subject: subject,
            message: message,
            date: dateISO,
            timeZone: timeZone,
            accepted_privacy: privacyAccepted
          }, config);

          if(response.data.success){
            stopLoading();
            isSubmitting.current = false;
            showMessageSuccess(response.data.message, setIsSuccessful, setSuccessfulMessage, 3000);

            setTimeout(() => {
              setFullName("");
              setEmail("");
              setSubject("");
              setMessage("");
              setPrivacyAccepted(false);
              navigate("/Home");
          }, 3000);
          
          }else{
            stopLoading();
            isSubmitting.current = false;
            showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000);
          }

        } catch (error) {
          stopLoading();
          isSubmitting.current = false;
          showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000);
        }
        
        }else{
          stopLoading();
          isSubmitting.current = false;
          showMessageError("Error recaptcha", setIsError, setErrorMessage, 3000);
        }

      }
  
  return (
    <>
    <div className="contact-container">
      <form onSubmit={comprobateRecaptcha}>
        <h3 className="contact-title">Contact</h3>

        <div className="error-message-container">
          {isError && <p className="message-alert red">{errorMessage}</p>}
          {isSuccessful && <p className="message-alert green">{successfulMessage}</p>}
          </div>

        <div className="contact-attribute">
          <label htmlFor="text">Full name</label>
          <input type="text" placeholder="Full name" className="contact-input"  value={fullName} onChange={(e) => setFullName(e.target.value)} required/>
        </div>
        <div className="contact-attribute">
          <label htmlFor="email">Email</label>
          <input type="email" placeholder="Email" className="contact-input"  value={email}  onChange={(e) => setEmail(e.target.value)} required/>
        </div>

        <div className="contact-attribute">
          <label htmlFor="text">Subject</label>
          <input type="text" placeholder="Subject" className="contact-input"  value={subject}  onChange={(e) => setSubject(e.target.value)} required/>
        </div>

        <div className="contact-attribute">
          <label htmlFor="text">Message</label>
          <textarea placeholder="Message" className="contact-input"  value={message}  onChange={(e) => setMessage(e.target.value)} required></textarea>
        </div>

          <div className="contact-privacy">
          <input type="checkbox" id="privacyCheckbox" checked={privacyAccepted} onChange={(e) => handlePrivacyCheckboxChange(e.target.checked)} required/>
          <label htmlFor="privacyCheckbox">I accept the <Link to="/Privacy" className="privacy-checkbox">privacy policy</Link></label>
          </div>

        <div className="submit-button-contact">
          <button type="submit" id="signIn" >Send</button>
        </div>

      </form>
    </div>
    </>
  );
  }

export default Contact;