import React, {useState, useCallback, useEffect, useRef} from "react"
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";

import {deleteSessionCookie} from "../components/cookies";
import {deleteSession} from "../components/Session";

import "../styles/ActivateAccount.css";
import "../styles/UserContext.css";

// Function to generate a unique idempotency key
const generateIdempotencyKey = async () => `Historial-${new Date().getTime()}-${Math.random().toString(36).substring(2, 11)}`;

const handleContextMenuLoading = (event) => {
  event.preventDefault();
};

function ActivateAccount ({isAuthenticated}) {
    const { token } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [tokenExists, setTokenExists] = useState(false);

    const isSubmitting = useRef(false);

    const navigate = useNavigate();  

    useEffect(() => {
      document.title = "Activate Account | ClockIn Smart";
    }, []);

    const checkTokenExists = useCallback( async() => {

      if (isSubmitting.current) return;
      isSubmitting.current = true;

      if(isAuthenticated){
        isSubmitting.current = false;
        setIsLoading(false);
        navigate("/Home");
        return;
      }
  
      try {
        await deleteSessionCookie();
        await deleteSession();
  
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/thank-you/${token}`);
        if (response.data.success) {
          const emailAccount = response.data.email;
          const responseEmail = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkEmail`, { email: emailAccount });
  
          if (responseEmail.data.exists) {

            // Generate a new Idempotency key
            const newIdempotencyKey = await generateIdempotencyKey();

            const config = {
                headers: {
                  'idempotency-key': newIdempotencyKey
                }
            };

            const responseUsed = await axios.put(`${process.env.REACT_APP_API_URL}/api/update-used-activate-account`, {
              emailAccount: emailAccount,
              token: token
            }, config);
  
            if (responseUsed.data.success) {
              setTokenExists(true);
            }
          }
        }

      } catch (error) {
        isSubmitting.current = false;
        /* console.error('Error updating account:', error); */
      } finally {
        isSubmitting.current = false;
        setIsLoading(false);
      }
  }, [isAuthenticated, navigate, token]);


    useEffect(() => {
      checkTokenExists();
    }, [checkTokenExists]);

    const goToLogin = () => {
    setTokenExists(false);
    navigate("/Login");
    }

  return (
    <>

    {isLoading && ( 
      <div className="main-loading-screen">
        <video autoPlay loop muted className="loading-gif" onContextMenu={handleContextMenuLoading}>
          <source src="/video-gif.mp4" type="video/mp4"  />
        </video>
      </div>
    )}

    {!isLoading && tokenExists && (
        <div className="activate-container">
            <h3 className="message-alert green">Congratulations! Your account has been successfully activated</h3>
            <p>You can now log in and start using our services.</p>
            <div className="button-activate">
                <button type="button" onClick={goToLogin}>Go to Log In</button>
            </div>
      </div>
    )}

    {!isLoading && !tokenExists && (
        <div className="activate-container">
            <h3 className="message-alert orange">Account already activated or invalid token</h3>
            <p>You can now log in and start using our services.</p>
            <div className="button-activate">
                <button type="button" onClick={goToLogin}>Go to Log In</button>
            </div>
      </div>
    )}
    </>
  );
}

export default ActivateAccount;