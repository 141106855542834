import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser  } from './UserContext';
import { useLoading } from './LoadingContext.js';
import "../styles/Navbar.css"

function Navbar({ isAuthenticated, setIsInvalidToken, setIsLogin, consent, activatedSession, dataReady }) {
  const navigate = useNavigate();  // Change to useNavigate
  const [showMenu, setShowMenu] = useState(false);
  const { logout } = useUser(); 
  const { startLoading, stopLoading } = useLoading();

  const handleLogout = async () => {
    try {

        if(activatedSession === false)
          return;

        if(dataReady === false){
          return;
        }
        
        startLoading();
        await logout();
        sessionStorage.removeItem('dataLoaded');
        stopLoading();
        navigate('/Home');
    } catch (error) {
        /* console.error('Error during logout:', error); */
        stopLoading();
    }
};

  const changeView = () => {
    setShowMenu(!showMenu);
  }

  // Close the menu if clicked outside of it.
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.navbar-container') && showMenu) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showMenu]);

  const handleLogin = () => {
    setIsInvalidToken(false);
    setIsLogin(true);
    navigate("/Login" );
  }

  return (
    <nav className={`navbar-container ${showMenu ? 'show-side-menu' : ''}`}>
      {/* TRVL logo that is always displayed */}
      <Link to={isAuthenticated ? '/Home' : '/Home'} className='nav-link navbar-left'>
        <img src="/ClockIn_logo.webp" alt="Logo" height="32" width="32" />
      </Link>
      
      {/* Toggle for the dropdown menu (only visible on small screens) */}
      <div className="navbar-toggle" onClick={changeView}>
        <div className="hamburger-icon">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
  
      {/* Navbar links list */}
      <ul className={`navbar-list ${showMenu ? 'show-menu' : ''}`}>
        <li className="navbar-item">
          <Link to='/Purpose' className='nav-link'>Purpose</Link>
        </li>
        {isAuthenticated && (
          <>
            <li className="navbar-item">
              <Link to='/Historial' className='nav-link'>Historial</Link>
            </li>
            <li className="navbar-item">
              <Link to='/Profile' className='nav-link'>Profile</Link>
            </li>
            <li className="navbar-item">
              <Link to='/Graphics' className='nav-link'>Graphics</Link>
            </li>
          </>
        )}
        {!isAuthenticated && (
          <li className="navbar-item navbar-right">
            <span role="button" tabIndex="0" className='nav-link' onClick={handleLogin}>Sign in</span>
          </li>
        )}
        {isAuthenticated && (
          <li className="navbar-item navbar-right out">
            <span role="button" tabIndex="0" className='nav-link' onClick={handleLogout}>Sign out</span>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;