import React, {useState, useCallback, useEffect, useRef} from 'react'
import "../styles/Profile.css"
import"../styles/StrengthPassword.css";
import "../styles/App.css";
import axios from 'axios';
import moment from 'moment-timezone';
import { useUser } from '../components/UserContext';
import {encryptPasswordOnServer, comparePasswordsOnServer} from "../components/Encryption.mjs";
import {showMessageSuccess, showMessageError} from "../components/messages.js";
import {verifySessionCookie} from "../components/cookies.js";
import { useNavigate } from 'react-router-dom';  
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {checkPasswordStrength} from "../components/strengthPassword.js";
import {initSessionValidation} from "../components/Auth.js";
import {deleteAllSession, createSession} from "../components/Session.js";
import {createSessionCookie, forgetConsentPrivacy} from "../components/cookies.js";
import {setBodyClass} from "../components/ShowRecaptcha.js";

import { useLoading } from '../components/LoadingContext.js';

import ReactDOMServer from 'react-dom/server';

// Function to generate a unique idempotency key
const generateIdempotencyKey = async () => `Profile-${new Date().getTime()}-${Math.random().toString(36).substring(2, 11)}`;

function Profile({ isAuthenticated, activatedSession, setActivatedSession, closedSession, setClosedSession, cookieConsent, showCookieBanner, checkCookieConsent, consent }) {
  
  const navigate = useNavigate();  
  const { user, updateUser, logout } = useUser(); 

  const [name, setName] = useState("");
  const [surname, setSurname] = useState(""); 
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [timezone, settimezone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successfulMessage, setSuccessfulMessage] = useState("");
  const [isErrorDelete, setIsErrorDelete] = useState(false);

  const [isTouch, setIsTouch] = useState(false);
  const { startLoading, stopLoading } = useLoading();

  const [isCompletedAction, setIsCompletedAction] = useState(false);
  const [isNotCompletedAction, setIsNotCompletedAction] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const passwordStrength = checkPasswordStrength(newPassword);
  const [statusCookies, setStatusCookies] = useState(cookieConsent);
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [tooltipKey, setTooltipKey] = useState(0); 

  const [deleteName, setDeleteName] = useState("");
  const [deletePassword, setDeletePassword] = useState("");

  const [isModalDeleteConfirmation, setIsModalDeleteConfirmation] = useState(false);

  const isSubmitting = useRef(false);
 
  const { executeRecaptcha } = useGoogleReCaptcha();

  const timezoneOptions = moment.tz.names().map(tz => ({
    label: tz,
    value: tz
  }));

  useEffect(() => {
    document.title = "Profile | ClockIn Smart";
  }, []);

  useEffect(() => {
    setBodyClass("profile-page", true);
    return () => {
      setBodyClass("profile-page", false);
    };
  }, []);

  useEffect(() => {
    const isTouchDevice = () => {
      const touchEventSupport = 'ontouchstart' in window;
      const pointerCoarseSupport = window.matchMedia("(pointer: coarse)").matches;

      return touchEventSupport || pointerCoarseSupport;
    };
    const touchDevice = isTouchDevice();
    setIsTouch(touchDevice);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setTooltipKey(prevKey => prevKey + 1);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setStatusCookies(cookieConsent);
  },[showCookieBanner])

  useEffect(() => {
    comprobateSession();
  },[isAuthenticated, navigate])

  const handleCookieToggle = () => {
    const newConsent = !cookieConsent;
    setStatusCookies(newConsent);
    localStorage.setItem('cookieConsent', newConsent.toString());
    checkCookieConsent();
    window.dispatchEvent(new Event('storage'));
  };

  const comprobateSession = async() => {
    const isSessionActivated = await initSessionValidation();
        if(isSessionActivated.success){
          setActivatedSession(true);
          checkCookieConsent();
        }   
        else{
          setClosedSession(true);
          await logout();
          sessionStorage.removeItem('dataLoaded');
        }
          
  }

  const openDeleteModal = () => {
    setIsModalDeleteConfirmation(true);
  }

  const closeDeleteModal = () => {
    setIsModalDeleteConfirmation(false);
    setDeleteName("");
    setDeletePassword("");
  }

  useEffect(() => { //Only executes 1 time
    setName(user.name);
    setSurname(user.surname);
    settimezone(user.timezone);
  },[]);

  useEffect(() => {
  }, [setNewPassword, newPassword]);

  const handleLogout = () => {
    setClosedSession(false);
    navigate('/Home'); 
  }

  const ChangeEdition = () => {
    if(isEditable){ //We want to change to non editable. We restore the original values
      setName(user.name);
      setSurname(user.surname); 
      setNewPassword("");
      setConfirmPassword("");
      settimezone(user.timezone);
    }
    setIsEditable(!isEditable);
  }

  const comprobateRecaptcha = useCallback( (e) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      showMessageError("Ops! Execute recaptcha not yet available", setIsNotCompletedAction, setErrorMessage, 3000);
      return;
    }

    executeRecaptcha("formRecaptcha").then((gReCaptchaToken) => {
      updateDetails(gReCaptchaToken);
    });
    
  },[executeRecaptcha, cookieConsent, name, surname, confirmPassword, newPassword, timezone]);


  const updateDetails = async (gReCaptchaToken) => {

    if (isSubmitting.current) return;
    isSubmitting.current = true;

    startLoading();
    
    const isSessionActivated = await initSessionValidation();
      if(!isSessionActivated.success){
        stopLoading();
        isSubmitting.current = false;
        setActivatedSession(false); 
        return;
      }

    const responseCaptcha = await axios.post(`${process.env.REACT_APP_API_URL}/verifyRecaptcha`, {
            captchaValue: gReCaptchaToken,
          });
          
      if(responseCaptcha.data.success){
        

        if(name.trim() !== "" && surname.trim() !== "" && confirmPassword.trim() !== "" && timezone.trim() !== ""){
          //Comprobate if previous password is correct.
          const passwordsMatch = await comparePasswordsOnServer(confirmPassword, user.email); 
          if(passwordsMatch){ //Password is encrypted
            try {
              
              //If the newPassword is empty, the user want to preserve the previous password Without Encrypt again because it's already encrypt.
              let newPasswordToSend;
              if(newPassword.trim() === ""){
                //The password will not be encrypt again.
                /* setNewPassword(password);
                newPasswordToSend = password; */
                newPasswordToSend = ""; // Send empty string to the server
                
              }else{
                
                if (passwordStrength.strength < 4){
                  stopLoading();
                  isSubmitting.current = false;
                  showMessageError("Ops! Password must to be strong", setIsNotCompletedAction, setErrorMessage, 3000);
                  return;
                }
                //The new password is valid, so we change the cookies and close all sessions from others devices.
                deleteAllSession(user.email);
                const newSession = await createSession(user.email);
                if(newSession.success){
                  
                  await createSessionCookie(newSession.meta);
                  const responseSessionActivated = await initSessionValidation();
                  setActivatedSession(responseSessionActivated.success); 
                }else{
                  stopLoading();
                  isSubmitting.current = false;
                  showMessageError("Ops! Error creating session", setIsNotCompletedAction, setErrorMessage, 3000);
                  return
                }

                //Encrypt the new password.
                const encryptedPassword  = await encryptPasswordOnServer(newPassword);
                
                setNewPassword(encryptedPassword );
                newPasswordToSend = encryptedPassword ;
              }

              try {
                // Generate a new Idempotency key
                const newIdempotencyKey = await generateIdempotencyKey();
                const token = await verifySessionCookie();

                const config = {
                    headers: {
                      'idempotency-key': newIdempotencyKey,
                      Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update-user`, {
                    email: user.email,
                    name: name.trim(),
                    surname: surname.trim(),
                    password: newPasswordToSend,
                    timezone: timezone
                },config);

                stopLoading();
                isSubmitting.current = false;
                showMessageSuccess("Profile successfully updated.", setIsCompletedAction, setSuccessfulMessage, 2500);
                
                const userUpdated = response.data;
                updateUser(userUpdated.email, userUpdated.name, userUpdated.surname, userUpdated.timezone); 

                setName(userUpdated.name);
                setSurname(userUpdated.surname); 
                settimezone(userUpdated.timezone);

                setIsEditable(!isEditable);
                setConfirmPassword("");
                setNewPassword("");
                sessionStorage.removeItem('dataLoaded');
            } catch (error) {
                stopLoading();
                isSubmitting.current = false;
                showMessageError("Ops! An unexpected error has occurred", setIsNotCompletedAction, setErrorMessage, 3000);
            }
    
            } catch (error) {
              stopLoading();
              isSubmitting.current = false;
              showMessageError("Ops! An unexpected error has occurred", setIsNotCompletedAction, setErrorMessage, 3000);
              setTimeout(() => {
                setIsEditable(!isEditable);
              }, 2000);
            }
          }else{
            stopLoading();
            isSubmitting.current = false;
            showMessageError("Ops! Current password does not match", setIsNotCompletedAction, setErrorMessage, 3000);
          }
    
        }else if(confirmPassword.trim() === ""){
          stopLoading();
          isSubmitting.current = false;
          showMessageError("Ops! Please confirm current password", setIsNotCompletedAction, setErrorMessage, 3000);
          
        }else{
          stopLoading();
          isSubmitting.current = false;
          showMessageError("Ops! All required fields must to be filled", setIsNotCompletedAction, setErrorMessage, 3000);
        }
      }else{
        stopLoading();
        isSubmitting.current = false;
        showMessageError("Ops! Error Captcha", setIsNotCompletedAction, setErrorMessage, 3000);
      }
  }

  const deleteAccount = async (e) => {
    e.preventDefault(); 

    if (isSubmitting.current) return;
    isSubmitting.current = true;

    startLoading();

    const isSessionActivated = await initSessionValidation();
      if(!isSessionActivated.success){
        isSubmitting.current = false;
        setActivatedSession(false); 
        return;
      }
    const email = user.email;
    if(deleteName.trim() !== "" && deletePassword.trim() !== ""){
      const passwordsMatch = await comparePasswordsOnServer(deletePassword, email);
      if(passwordsMatch && deleteName === name){
        try {
          //HERE ALL PARTS OF THE ACCOUNT SHOULD BE DELETED
          
          // Generate a new Idempotency key
          const newIdempotencyKey = await generateIdempotencyKey();

          const config = {
              headers: {
                'idempotency-key': newIdempotencyKey
              }
          };

          const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/deleteAccount/${email}`, config);

          if(response.data.success){
            // After confirming the deletion, handle the final cleanup
            console.log(response.data.message);
            await logout(true);
            //await deleteAllSession(email); //Because Logout() only deletes the current session.
            closeDeleteModal();
            handleLogout();
            forgetConsentPrivacy();
            stopLoading();
            isSubmitting.current = false;
            sessionStorage.removeItem('dataLoaded');
          }else{
            stopLoading();
            isSubmitting.current = false;
            showMessageError("An unexpected error has occurred", setIsErrorDelete, setErrorMessage, 3000);
            setTimeout(() => {
              closeDeleteModal();
            }, 2000);
          }
          
  
        } catch (error) {
          stopLoading();
          isSubmitting.current = false;
          showMessageError("An unexpected error has occurred", setIsErrorDelete, setErrorMessage, 3000);
          setTimeout(() => {
            closeDeleteModal();
          }, 2000);
        }
      }else{
        stopLoading();
        isSubmitting.current = false;
        showMessageError("Invalid login credentials. Please try again", setIsErrorDelete, setErrorMessage, 3000);
      } 
    }else{
      stopLoading();
      isSubmitting.current = false;
      showMessageError("Error. All fields must to be filled", setIsErrorDelete, setErrorMessage, 3000);
    }
  }

  const jsxToHtmlString = (jsxElement) => {
    // Render the JSX element to an HTML string
    return ReactDOMServer.renderToString(jsxElement);
  };

  const goSignIn = () => {
    setClosedSession(false); // To make the logout message disappear.
    navigate('/Login');
  }

  return (
    <>
      {isAuthenticated && activatedSession && !closedSession && (
        <div className={isEditable ? "profile-container max" : "profile-container"}>
        <form onSubmit={comprobateRecaptcha}>
            <h3 className="profile-title">Account Details </h3>
            {isEditable && (<p className="message-alert orange">If the new password left blank, password will not be updated</p>)}
            
            <div className="profile-attribute">
                <label htmlFor="name">Name</label>
                <input type="text" className="form-control" value={isEditable ? name : user.name} onChange={(e) => setName(e.target.value)} style={{ pointerEvents: isEditable ? 'auto' : 'none', backgroundColor: isEditable ? 'white' : 'gray' }} required></input>
            </div>

            <div className="profile-attribute">
                <label htmlFor="surname">Surname</label>
                <input type="text" className="form-control" value={isEditable ? surname : user.surname}  onChange={(e) => setSurname(e.target.value)} style={{ pointerEvents: isEditable ? 'auto' : 'none', backgroundColor: isEditable ? 'white' : 'gray' }} required></input>
            </div>

            <div className="profile-attribute">
                <label htmlFor="email">Email</label>
                <input type="text" className="form-control" value={user.email} style={{backgroundColor: 'gray'}} readOnly></input>
            </div>

            {isEditable && (
              <div>
                <div className="profile-attribute">
                  <label htmlFor="password">New password</label>

                  {!isTouch ? (
                    <Tooltip
                        key={tooltipKey}
                        title={jsxToHtmlString(
                          <ul className="tooltip-content">
                            <li className={passwordStrength.conditions.minLength ? 'list-item valid' : 'list-item'}>
                              At least 8 characters
                            </li>
                            <li className={passwordStrength.conditions.containsNumber ? 'list-item valid' : 'list-item'}>
                              Contains at least one number
                            </li>
                            <li className={passwordStrength.conditions.containsUppercase ? 'list-item valid' : 'list-item'}>
                              Contains at least one uppercase letter
                            </li>
                            <li className={passwordStrength.conditions.containsLowercase ? 'list-item valid' : 'list-item'}>
                              Contains at least one lowercase letter
                            </li>
                            <li className={passwordStrength.conditions.containsSpecialChar ? 'list-item valid' : 'list-item'}>
                              Contains at least one special character
                            </li>
                          </ul>
                        )}
                        position={windowWidth < 905 ? "bottom" : "right"}
                        trigger="click"
                        arrow
                        theme="light"
                        distance={2}
                      >

                  <div className="password-container">
                    <input type="password" className="form-control" placeholder="If left blank, password won't be updated"  onChange={(e) => setNewPassword(e.target.value)} style={{ pointerEvents: isEditable ? 'auto' : 'none' }}></input>
                    {newPassword && (
                        <span className={`password-strength-message ${checkPasswordStrength(newPassword).message.toLowerCase()}`}>
                            {checkPasswordStrength(newPassword).message}
                        </span>
                    )}
                  </div>
                 </Tooltip> 
                ): (
                  <div className="password-container">
                    <input type="password" className="form-control" placeholder="If left blank, password won't be updated"  onChange={(e) => setNewPassword(e.target.value)} style={{ pointerEvents: isEditable ? 'auto' : 'none' }}></input>
                    {newPassword && (
                        <span className={`password-strength-message ${checkPasswordStrength(newPassword).message.toLowerCase()}`}>
                            {checkPasswordStrength(newPassword).message}
                        </span>
                    )}
                  </div>
                )}
                  
                </div>
                
                <div className="profile-attribute">
                  <label htmlFor="password">Confirm current password</label>
                  <input type="password" className="form-control" onChange={(e) => setConfirmPassword(e.target.value)} style={{ pointerEvents: isEditable ? 'auto' : 'none' }} required></input>
                </div>

                <div className="profile-attribute">
                  <label htmlFor="timezone">Time Zone</label>
                  <select
                      id="timezone"
                      className="form-control"
                      value={isEditable ? timezone : user.timezone} 
                      onChange={(e) => settimezone(e.target.value)} 
                      style={{ pointerEvents: isEditable ? 'auto' : 'none' }}
                      disabled={!isEditable}
                  >
                      {timezoneOptions.map(option => (
                          <option key={option.value} value={option.value}>
                              {option.label}
                          </option>
                      ))}
                  </select>
              </div>

                <div className="profile-attribute-switch">
                  <label className="switch">
                    <input type="checkbox" checked={statusCookies} onChange={handleCookieToggle} />
                    <span className="slider round"></span>
                  </label>
                  <label> Enable Analytics Cookies</label>
                </div>

              </div>
            )}

            

            {!isEditable && activatedSession && (<div className="edit-button">
                <button type="button" onClick={ChangeEdition}>Edit</button>
            </div>)}

            {isEditable && activatedSession && (<div className="save-cancel-button">
                <button type="button" className="profile-cancel-button" onClick={ChangeEdition}>Cancel</button>
                <button type="submit">Save</button>
                
                <p><span className="delete-account" onClick={openDeleteModal}>Delete account</span></p>
              </div>)}
        </form>

        {isModalDeleteConfirmation && activatedSession && (
          <div className="modal-overlay-delete">
            <form className="modal-confirmation-delete" onSubmit={deleteAccount}>
              <h3 className="confirmation-title-delete">Confirmation</h3>
              <p className="confirmation-message-delete">Are you sure you want to delete this account?</p>
              {!isErrorDelete && <p className="message-alert red">All jobs and shifts associated with this account will be deleted</p>}
              {isErrorDelete && <p className="message-alert red">{errorMessage}</p>}
              
              <div className="confirmation-inputs-delete">
                <label htmlFor="name">Name</label>
                <input type="text" placeholder="Enter name..." value={deleteName} onChange={(e) => setDeleteName(e.target.value)} required></input>
              </div>
                <div className="confirmation-inputs-delete">
                  <label htmlFor="password">Password</label>
                  <input type="password"  placeholder="Enter password..." value={deletePassword} onChange={(e) => setDeletePassword(e.target.value)} required></input>
                </div> 
              
              <div className="confirmation-buttons-delete">
                <button type="button" className="cancel-profile" onClick={closeDeleteModal}>Cancel</button>
                <button type="submit" className="delete-profile">Delete</button>
              </div>          
            </form>
          </div>
        )}
        </div>
      )}

        {isCompletedAction && (
          <>  
            <div className="modal-overlay"> 
              <div className="modal-success">
                <div className="modal-header">
                  <img src="/success-icon.webp" alt="Success" width="128" height="128"  /> 
                  <h3>Completed action!</h3>
                </div>
                <div className="modal-body">
                  <p>{successfulMessage}</p>
                </div>
              </div>
            </div>
          </>
        )
      }

        {isNotCompletedAction && (
          <>  
            <div className="modal-overlay"> 
            <div className="modal-error">
              <div className="modal-header">
                <img src="/error-icon.webp" alt="Error" width="128" height="128"  /> 
                <h3>Error!</h3>
              </div>
              <div className="modal-body">
                <p>{errorMessage}</p>
                <p><small>Please try again. If the issue persists, contact support.</small></p>
              </div>
            </div>
          </div>
          
        </>
    
        )
      }

      { !activatedSession && closedSession && !isAuthenticated && (
        <div className="modal-no-session">
        <div className="modal-confirmation-no-session">
            <h3 className="confirmation-title-no-session">Notification</h3>
            <p className="confirmation-message-no-session">Your session has been closed or the password has been changed, please try to sign in again.</p>
            <div className="confirmation-buttons-no-session">
            <button className="button-no-session" onClick={goSignIn}>Sign in</button>
            </div>
        </div>
        </div>
      )}
    </>
  )
}

export default Profile