export const showMessageSuccess = (message, setIsSuccessful, setSuccessfulMessage, time) => {

    if(showMessageSuccess.timerId) 
        clearTimeout(showMessageSuccess.timerId);
    
    setSuccessfulMessage(message);
    setIsSuccessful(true);

    setTimeout(() => {
        setIsSuccessful(false);
        setSuccessfulMessage(""); // Clean the message after a few seconds
        showMessageSuccess.timerId = null;
    }, time);
};

export const showMessageError = (message, setIsError, setErrorMessage, time) => {

    if(showMessageError.timerId) 
        clearTimeout(showMessageError.timerId);

    setErrorMessage(message);
    setIsError(true);
    setTimeout(() => {
        setIsError(false);

        setErrorMessage(""); // Clean the message after a few seconds
        showMessageSuccess.timerId = null;
    }, time);
};

export const showModalSuccess = (message, setIsSuccessful, setIsRegistered, setSuccessfulMessage, time) => { //setIsRegistered to make sure that the user clicked the button ADD.

    if(showMessageSuccess.timerId) 
        clearTimeout(showMessageSuccess.timerId);
    
    setSuccessfulMessage(message);
    setIsSuccessful(true);
    setIsRegistered(true);

    setTimeout(() => {
        setIsSuccessful(false);
        setIsRegistered(false);
        setSuccessfulMessage(""); // Clean the message after a few seconds
        showMessageSuccess.timerId = null;
    }, time);
};

export const showModalError = (message, smallMessage, setIsError, setIsRegistered, setErrorMessage, setSmallMessageModal) => {

    setErrorMessage(message);
    setSmallMessageModal(smallMessage);
    setIsError(true);   
    setIsRegistered(true);
};

