import React, { createContext, useContext, useState } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [globalLoading, setGlobalLoading] = useState(true);

  const startLoading = () => setGlobalLoading(true);
  const stopLoading = () => setGlobalLoading(false);

  return (
    <LoadingContext.Provider value={{ globalLoading, startLoading, stopLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => useContext(LoadingContext);