import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from "react-router-dom";

import Home from "./pages/Home";
import Purpose from "./pages/Purpose";
import Historial from "./pages/Historial";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Graphics from "./pages/Graphics";

import CookieConsentBanner from "./components/CookieConsentBanner";

import { useLoading } from './components/LoadingContext.js';

import ForgotPassword from "./pages/ForgotPassword";
import ActivateAccount from "./pages/ActivateAccount";

import AnalyticsInitializer from "./components/AnalyticsInitializer";

import ReactGA from 'react-ga4';

import './styles/App.css';
import './styles/Home.css';
import "./styles/Login.css";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useUser, useAuth } from './components/UserContext';

import LoadingHandler from './components/LoadingHandler'; // Importar el LoadingHandler

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppContent({ consent, isGAInitialized }) {
  const [isLogin, setIsLogin] = useState(true);
  const [isInvalidToken, setIsInvalidToken] = useState(false);

  const { globalLoading } = useLoading();
  const [initialLoading, setInitialLoading] = useState(true);

  const { 
    isAuthenticated,  
    activatedSession, 
    setIsAuthenticated, 
    setActivatedSession,
    cookieConsent,
    setCookieConsent,
    isVisibleCookieBanner, 
    setIsVisibleCookieBanner, 
    showCookieBanner,
    checkCookieConsent,
    dataReady
  } = useAuth();

  const { closedSession, setClosedSession } = useUser();

  const location = useLocation();

  useEffect(() => {
    
    if (consent === true && isGAInitialized && process.env.NODE_ENV === 'production') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location, consent, isGAInitialized]);

  
  // Stop initial loading after mount
  useEffect(() => {
    if (!globalLoading) {
      setInitialLoading(false);
    }
  }, [globalLoading]);

  // Render LoadingHandler during initial load
  if (initialLoading) {
    return <LoadingHandler />;
  }


  return (
    <>
      <ScrollToTop />
      <CookieConsentBanner setCookieConsent={setCookieConsent} isVisibleCookieBanner={isVisibleCookieBanner} setIsVisibleCookieBanner={setIsVisibleCookieBanner} />
      <Navbar isAuthenticated={isAuthenticated} setIsInvalidToken={setIsInvalidToken} setIsLogin={setIsLogin} consent={consent} activatedSession={activatedSession} dataReady={dataReady}/>
      <div className={`${location.pathname === "/Privacy" ? "privacy-page" : "main-content"}`}>
        <LoadingHandler />
        <Routes>
          <Route path="/" element={<Navigate to="/Home" />} /> 
          <Route path="/Home" element={<Home isAuthenticated={isAuthenticated} activatedSession={activatedSession} setActivatedSession={setActivatedSession} closedSession={closedSession} setClosedSession={setClosedSession} consent={consent}/>} />
          <Route path="/Purpose" element={<Purpose />} />

          {isAuthenticated && (
              <>
                <Route path='/Historial' element={<Historial isAuthenticated={isAuthenticated} activatedSession={activatedSession} setActivatedSession={setActivatedSession} closedSession={closedSession} setClosedSession={setClosedSession}/>}/>
                <Route path='/Profile' element={<Profile isAuthenticated={isAuthenticated} activatedSession={activatedSession} setActivatedSession={setActivatedSession} closedSession={closedSession} setClosedSession={setClosedSession} cookieConsent={cookieConsent} showCookieBanner={showCookieBanner} checkCookieConsent={checkCookieConsent} consent={consent}/>}/>
                <Route path='/Graphics'element={<Graphics isAuthenticated={isAuthenticated} activatedSession={activatedSession} setActivatedSession={setActivatedSession} closedSession={closedSession} setClosedSession={setClosedSession} />} />
              </>
            )}

          <Route path="/SignUp" element={<SignUp consent={consent}/>} />

          <Route
              path="/Login" 
              element={<Login isLogin={isLogin} invalidToken={isInvalidToken} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setActivatedSession={setActivatedSession} cookieConsent={cookieConsent} showCookieBanner={showCookieBanner} checkCookieConsent={checkCookieConsent} consent={consent}/>}
            />
        
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Privacy" element={<Privacy />} />

        <Route path="/forgotPassword" element={<Navigate to="/Home" />} />
        <Route path="/forgotPassword/:token" element={<ForgotPassword isAuthenticated={isAuthenticated} setIsInvalidToken={setIsInvalidToken} setIsLogin={setIsLogin} cookieConsent={cookieConsent} checkCookieConsent={checkCookieConsent}/>} />
        
        <Route path="/thank-you" element={<Navigate to="/Home" />} />
        <Route path="/thank-you/:token" element={<ActivateAccount isAuthenticated={isAuthenticated} />} />

        {/* Ruta de captura para cualquier URL desconocida */}
        <Route path="*" element={<Navigate to="/Home" />} />

        </Routes>
      </div>
      <Footer isAuthenticated={isAuthenticated} />
    </>
  );
}

const initializeGA = (trackingID, isGAInitialized) => {
  if (typeof window !== 'undefined' && !isGAInitialized && process.env.NODE_ENV === 'production') {
    ReactGA.initialize(trackingID, {
      gaOptions: {
        anonymizeIp: true,
        transport: 'beacon'
      }
    });
    
  }
};

function App() {
  const [consent, setConsent] = useState(null);
  const {isGAInitialized, setIsGAInitialized} = useUser();
  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    startLoading();
    //To initialize the variable when the page is loaded.
    const storedConsent = localStorage.getItem('cookieConsent');

    const initialConsent = storedConsent === 'true';
    
    setConsent(initialConsent);

    if(initialConsent && !isGAInitialized && process.env.NODE_ENV === 'production'){
      initializeGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, isGAInitialized);
      setIsGAInitialized(true);
    }

    const handleStorageChange = () => {
      const newConsent = localStorage.getItem('cookieConsent') === 'true';
      setConsent(newConsent);
    };

    window.addEventListener('storage', handleStorageChange);
    stopLoading();
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_PUBLIC_KEY_RECAPTCHA}> 
        <Router>
          <AnalyticsInitializer consent={consent} isGAInitialized={isGAInitialized} setIsGAInitialized={setIsGAInitialized} />
          <AppContent consent={consent} isGAInitialized={isGAInitialized}  />
        </Router>
    </GoogleReCaptchaProvider>
  );
}

export default App;