import React, {useState, useCallback, useEffect, useRef} from "react"
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import {encryptPasswordOnServer} from "../components/Encryption.mjs";
import {showMessageSuccess, showMessageError} from "../components/messages.js";
import {checkPasswordStrength} from "../components/strengthPassword.js";
import {setBodyClass} from "../components/ShowRecaptcha.js";
import "../styles/ForgotPassword.css";
import "../styles/UserContext.css";
import { useLoading } from '../components/LoadingContext.js';
import {deleteAllSession, deleteSession} from "../components/Session";

import {deleteSessionCookie} from "../components/cookies";

import ReactDOMServer from 'react-dom/server';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

// Function to generate a unique idempotency key
const generateIdempotencyKey = async () => `ForgotPassword-${new Date().getTime()}-${Math.random().toString(36).substring(2, 11)}`;

function ForgotPassword ({isAuthenticated, setIsInvalidToken, setIsLogin, cookieConsent, checkCookieConsent}) {
    const { token } = useParams();

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
    const [emailUser, setEmailUser] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const { startLoading, stopLoading } = useLoading();

    const [isError, setIsError] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successfulMessage, setSuccessfulMessage] = useState("");

    const [tokenExists, setTokenExists] = useState(false);

    const passwordStrength1 = checkPasswordStrength(newPassword);
    const passwordStrength2 = checkPasswordStrength(newPasswordRepeat);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [tooltipKey1, setTooltipKey1] = useState(0); // Estado para cambiar la clave del Tooltip
    const [tooltipKey2, setTooltipKey2] = useState(0); // Estado para cambiar la clave del Tooltip

    const isSubmitting = useRef(false);

    const [isTouch, setIsTouch] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    const navigate = useNavigate();  // Cambia a useNavigate

    useEffect(() => {
      document.title = "Reset Password | ClockIn Smart";
    }, []);

    useEffect(() => {
      setBodyClass("forgotpassword-page", true);
      return () => {
        setBodyClass("forgotpassword-page", false);
      };
    }, []);

    useEffect(() => {
      const isTouchDevice = () => {
        const touchEventSupport = 'ontouchstart' in window;
        const pointerCoarseSupport = window.matchMedia("(pointer: coarse)").matches;
  
        return touchEventSupport || pointerCoarseSupport;
      };
      const touchDevice = isTouchDevice();
      setIsTouch(touchDevice);
    }, []);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setTooltipKey1(prevKey1 => prevKey1 + 1);
        setTooltipKey2(prevKey2 => prevKey2 + 1);
      };
    
      window.addEventListener('resize', handleResize);
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const handleContextMenuLoading = (event) => {
      event.preventDefault();
    };
    
    const checkTokenExists = useCallback(async () => {

      if(isAuthenticated){
        setIsLoading(false);
        navigate("/Home");
        return;
      }

      await deleteSessionCookie();
      //Delete session from database
      
      await deleteSession();
      
  
      return new Promise((resolve, reject) => {
          axios.get(`${process.env.REACT_APP_API_URL}/api/forgotPassword/${token}`)
              .then(response => {
                  const exists = response.data.exists;
                  const emailUser = response.data.email;
                  setTokenExists(exists);
                  setEmailUser(emailUser);
                  setIsLoading(false);
                  resolve(exists);
              })
              .catch(error => {
                  /* console.error('Error verifying token:', error); */
                  setIsLoading(false);
                  reject(error);
              });
      });
  }, [token, emailUser]);


    useEffect(() => {
      checkTokenExists();
      checkCookieConsent();
    }, [checkTokenExists]);


    const comprobateRecaptcha = useCallback( (e) => {
        e.preventDefault();
    
        if (!executeRecaptcha) {
          showMessageError("Error. Execute recaptcha not yet available", setIsError, setErrorMessage, 3000);
          return;
        }
    
        executeRecaptcha("formRecaptcha").then((gReCaptchaToken) => {
            createNewPassword(gReCaptchaToken);
        });
        
      },[executeRecaptcha, cookieConsent, newPassword, newPasswordRepeat]);

      const createNewPassword = async (gReCaptchaToken) => {

        if (isSubmitting.current) return;
        isSubmitting.current = true;

        startLoading();

        const responseCaptcha = await axios.post(`${process.env.REACT_APP_API_URL}/verifyRecaptcha`, {
            captchaValue: gReCaptchaToken,
          });

       if(responseCaptcha.data.success){
            if(newPassword.trim() !== "" && newPasswordRepeat.trim() !== ""){
                if(newPassword.trim() === newPasswordRepeat.trim()){

                  if (passwordStrength1.strength < 4 || passwordStrength2.strength < 4){
                    stopLoading();
                    isSubmitting.current = false;
                    showMessageError("Error. The password must to be strong.", setIsError, setErrorMessage, 3000);
                    return;
                  }
                    // We check again if the token is correct
                    const updatedTokenExists = await checkTokenExists();
                    if(updatedTokenExists){
                      
                      // The password will be encrypted
                      const encrypt = await encryptPasswordOnServer(newPassword);
                      if (typeof encrypt !== 'string') {
                        isSubmitting.current = false;
                        throw new Error('Encrypted password is not a string, the type is: ',typeof(encrypt));
                        stopLoading();
                    }

                      const newPasswordToSend = encrypt;

                      try{

                        const responseEmail = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkEmail`, {
                          email: emailUser,
                        });

                        if(responseEmail.data.exists){
                          // Generate a new Idempotency key
                          const newIdempotencyKey = await generateIdempotencyKey();

                          const config = {
                              headers: {
                                'idempotency-key': newIdempotencyKey
                              }
                          };

                          const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/update-recovery-password`, {
                            email: emailUser,
                            password: newPasswordToSend
                          }, config);

                          // If the user accessed the application before, the unlock attempts will be reset if necessary
                          // Check if there were attempts previously

                          const responseEmailAccess = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkEmailLoginAccess`, {
                            email: emailUser
                          });
                          // We only update if it existed before
                          if(responseEmailAccess.data.exists){
                            const idLoginAccess = responseEmailAccess.data.accessRegistered._id;
                            const dateConvertedNewAccess = new Date(); 
                            const dateISO = dateConvertedNewAccess.toISOString();

                            // Generate a new Idempotency key
                            const newIdempotencyKey = await generateIdempotencyKey();

                            const config = {
                                headers: {
                                  'idempotency-key': newIdempotencyKey
                                }
                            };

                            await axios.put(`${process.env.REACT_APP_API_URL}/api/updateLoginAccess/${idLoginAccess}`, {
                              last_login_date: dateISO,
                              failed_attempts_count: 0,
                              blocked: false
                            }, config)
                    
                          }

                          if(response.data.success){

                            // Generate a new Idempotency key
                            const newIdempotencyKey = await generateIdempotencyKey();

                            const config = {
                                headers: {
                                  'idempotency-key': newIdempotencyKey
                                }
                            };
                            
                            // Now we modify the ResetPassword to mark that the token has been used
                            const responseUsed = await axios.put(`${process.env.REACT_APP_API_URL}/api/update-used-recovery-password`, {
                            email: emailUser,
                            token: token
                            }, config);

                            // Close the session in all devices for security
                            deleteAllSession(emailUser);

                            if(responseUsed.data.success){
                              stopLoading();
                              isSubmitting.current = false;
                              showMessageSuccess("Password updated successfully", setIsSuccessful, setSuccessfulMessage, 2500);
                              setTimeout(() => {
                                navigate('/Login');
                              },3000);
                            }
                            stopLoading();
                            isSubmitting.current = false;
                          }
                          stopLoading();
                          isSubmitting.current = false;
                        }else{
                          stopLoading();
                          isSubmitting.current = false;
                          showMessageError("Error. User data could not be verified", setIsError, setErrorMessage, 3000);
                        }

                      }catch(error){
                        stopLoading();
                        isSubmitting.current = false;
                        showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000);
                      } 

                    }else{
                      stopLoading();
                      isSubmitting.current = false;
                      showMessageError("Error. There was a problem with the form or the token has expired. Please try requesting a valid link again or contact support", setIsError, setErrorMessage, 3000);
                    }
                    
                }else{
                  stopLoading();
                  isSubmitting.current = false;
                  showMessageError("Error. Password does not match", setIsError, setErrorMessage, 3000);
                }

            }else{
              stopLoading();
              isSubmitting.current = false;
              showMessageError("Error. All fields must to be filled", setIsError, setErrorMessage, 3000);
            }
       }

      }

      useEffect(() => {
        if (!isLoading && !tokenExists) {
          setIsInvalidToken(true);
          setIsLogin(false);
          navigate('/Login');
        }
      }, [isLoading, tokenExists, setIsInvalidToken, setIsLogin, navigate]);

      const jsxToHtmlString = (jsxElement) => {
        // We render the JSX element to an HTML string.
        return ReactDOMServer.renderToString(jsxElement);
      };

  return (
    <>
    {isLoading && ( 
      <div className="main-loading-screen">
        <video autoPlay loop muted className="loading-gif" onContextMenu={handleContextMenuLoading}>
          <source src="/video-gif.mp4" type="video/mp4"  />
        </video>
      </div>
    )}

    {!isLoading && tokenExists && (
        <div className="recovery-container">
        <form onSubmit={comprobateRecaptcha}>
          <h3 className="recovery-title">Reset password</h3>
          <div className="error-message-container">
            {isError && <p className="message-alert red">{errorMessage}</p>}
            {isSuccessful && <p className="message-alert green">{successfulMessage}</p>}
          </div>
          <div className="recovery-attribute">
            <label htmlFor="newPassword">New password</label>
            {!isTouch ? (
              <Tooltip
                key={tooltipKey1}
                title={jsxToHtmlString(
                  <ul className="tooltip-content">
                    <li className={passwordStrength1.conditions.minLength ? 'list-item valid' : 'list-item'}>
                      At least 8 characters
                    </li>
                    <li className={passwordStrength1.conditions.containsNumber ? 'list-item valid' : 'list-item'}>
                      Contains at least one number
                    </li>
                    <li className={passwordStrength1.conditions.containsUppercase ? 'list-item valid' : 'list-item'}>
                      Contains at least one uppercase letter
                    </li>
                    <li className={passwordStrength1.conditions.containsLowercase ? 'list-item valid' : 'list-item'}>
                      Contains at least one lowercase letter
                    </li>
                    <li className={passwordStrength1.conditions.containsSpecialChar ? 'list-item valid' : 'list-item'}>
                      Contains at least one special character
                    </li>
                  </ul>
                )}
                position={windowWidth < 905 ? "bottom" : "right"}
                trigger="click"
                arrow
                theme="light"
                distance={2}
              >
                <div className="password-container">
                  <input type="password" placeholder="New password" className="recovery-input"  value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required/>
                    {newPassword && (
                      <span
                        className={`password-strength-message ${checkPasswordStrength(newPassword).message.toLowerCase()}`}
                      >
                        {checkPasswordStrength(newPassword).message}
                      </span>
                    )}
                </div>
              </Tooltip>
            ): (
              <div className="password-container">
                <input type="password" placeholder="New password" className="recovery-input"  value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required/>
                  {newPassword && (
                    <span
                      className={`password-strength-message ${checkPasswordStrength(newPassword).message.toLowerCase()}`}
                    >
                      {checkPasswordStrength(newPassword).message}
                    </span>
                  )}
              </div>
            )}
          </div>

          <div className="register-attribute">
            <label htmlFor="password">Confirm new password</label>
            {!isTouch ? (
              <Tooltip
                key={tooltipKey2}
                title={jsxToHtmlString(
                  <ul className="tooltip-content">
                    <li className={passwordStrength2.conditions.minLength ? 'list-item valid' : 'list-item'}>
                      At least 8 characters
                    </li>
                    <li className={passwordStrength2.conditions.containsNumber ? 'list-item valid' : 'list-item'}>
                      Contains at least one number
                    </li>
                    <li className={passwordStrength2.conditions.containsUppercase ? 'list-item valid' : 'list-item'}>
                      Contains at least one uppercase letter
                    </li>
                    <li className={passwordStrength2.conditions.containsLowercase ? 'list-item valid' : 'list-item'}>
                      Contains at least one lowercase letter
                    </li>
                    <li className={passwordStrength2.conditions.containsSpecialChar ? 'list-item valid' : 'list-item'}>
                      Contains at least one special character
                    </li>
                  </ul>
                )}
                position={windowWidth < 905 ? "bottom" : "right"}
                trigger="click"
                arrow
                theme="light"
                distance={2}
              >
              <div className="password-container">
                <input type="password" placeholder="Confirm new password" className="recovery-input"  value={newPasswordRepeat}  onChange={(e) => setNewPasswordRepeat(e.target.value)} required/>
                {newPasswordRepeat && (
                  <span className={`password-strength-message ${checkPasswordStrength(newPasswordRepeat).message.toLowerCase()}`}>
                      {checkPasswordStrength(newPasswordRepeat).message}
                  </span>
                )}
              </div>
            </Tooltip>
          ) : (
            <div className="password-container">
              <input type="password" placeholder="Confirm new password" className="recovery-input"  value={newPasswordRepeat}  onChange={(e) => setNewPasswordRepeat(e.target.value)} required/>
              {newPasswordRepeat && (
                <span className={`password-strength-message ${checkPasswordStrength(newPasswordRepeat).message.toLowerCase()}`}>
                  {checkPasswordStrength(newPasswordRepeat).message}
                </span>
              )}
            </div>
          )}
          </div>

          <div className="submit-button-recovery">
            <button type="submit" id="signIn" >Reset password</button>
          </div>
  
        </form>
      </div>
    )}
    </>
  );
}

export default ForgotPassword;