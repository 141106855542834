
import React, { createContext, useContext, useState, useEffect } from 'react';
import { initSessionValidation } from './Auth';
import {deleteSessionCookie, verifySessionCookie} from "./cookies";
import {deleteSession} from "./Session"
import { fetchData } from "./LoadJobsShifts";
import {preloadImages, todayUser} from "./UsefulFunctions";
import moment from 'moment-timezone';

import axios from 'axios';

import { useLoading } from './LoadingContext.js';

// create contexts
const AuthContext = createContext();
const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({
        email: '',
        name: '',
        surname: '',
        timezone: ''
    });
   
    const [jobs, setJobs] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [cookieConsent, setCookieConsent] = useState(false);
    const [isVisibleCookieBanner, setIsVisibleCookieBanner] = useState(false);
    const [isGAInitialized, setIsGAInitialized] = useState(false);
    const { startLoading, stopLoading } = useLoading();

    const checkCookieConsent = () => {
        const storedConsent = localStorage.getItem('cookieConsent');
        setCookieConsent(storedConsent === 'true'); // Update state based on storage
        if(storedConsent === null) {
            setIsVisibleCookieBanner(true); // Show banner if no consent
        }
    };

    const showCookieBanner = () => {
        setIsVisibleCookieBanner(true); // Explicitly show banner
    };

    useEffect(() => {
        checkCookieConsent(); // Check on initial load
    }, []);

    const initialChartParams = {
        rawData: null,
        chartData: null,
        dateRange: [new Date(new Date().setUTCDate(new Date().getUTCDate() - 7)), new Date()],
        selectedMetric: 'workedHours',
        selectedJob: 'All jobs',
        lastParams: null,
        viewStatus: "active",
        loadDataFirtsTime: false,
        lastUpdate: null
    };

    const [chartParamsGraphic, setChartParamsGraphic] = useState(initialChartParams);

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [activatedSession, setActivatedSession] = useState(false);
    const [closedSession, setClosedSession] = useState(false); // To display the logout message when inside
    const [userReady, setUserReady] = useState(false);
    const [dataReady, setDataReady] = useState(false);

    const resetChartParamsGraphic = () => {
        setChartParamsGraphic(initialChartParams);
    };

    const updateUser = (email, name, surname, timezone) => {
        setUser({
            email,
            name,
            surname,
            timezone
        });
        // To ensure that when the timezone changes, the end date of the graph is always updated to the user's current day
        const userDate = moment().tz(timezone);
        
        const startDate = userDate.clone().subtract(7, 'days').format();
        const endDate = userDate.format(); //The day of the user.
      
        setChartParamsGraphic(prev => ({
            ...prev,
            dateRange: [startDate, endDate],
            lastUpdate: {
                time: todayUser(timezone), //Current date of the user
                type: "User"
            }
        }))
        
    } 
    const updateJobs = (newJobs) =>{
        setJobs(newJobs);
        setChartParamsGraphic(prev => ({
            ...prev,
            lastUpdate: {
                time: todayUser(user.timezone), 
                type: "jobs"
            }
        }))
    } 
    const updateShifts = (newShifts) => {
        setShifts(newShifts);
        setChartParamsGraphic(prev => ({
            ...prev,
            lastUpdate: {
                time: todayUser(user.timezone), 
                type: "shifts"
            }
        }))
    } 


    useEffect(() => {
        const fetchDataAsync = async () => {
            
          if (userReady) {
            startLoading();
            if(user){
                try{

                    await fetchData(user, updateJobs, updateShifts);

                    const userDate = moment().tz(user.timezone);
                    const startDate = userDate.clone().subtract(7, 'days').format();
                    const endDate = userDate.format();
        
                    const updatedChartParams = {
                    ...initialChartParams,
                    dateRange: [startDate, endDate],
                    lastUpdate: {
                        time: Date.now(),
                        type: "User Loaded"
                    }
                    };
                    setChartParamsGraphic(updatedChartParams); 
                    setDataReady(true);
          
                }finally{
                    stopLoading();
                }
            }
                
            }
        };
      
    fetchDataAsync();
        
    }, [userReady]);

    const loadUserData = async () => {
        startLoading();
        try {
            const response = await initSessionValidation();
            
            if (response.success) {
                setUser({
                    email: response.email,
                    name: response.name,
                    surname: response.surname,
                    timezone: response.timezone
                });
                setIsAuthenticated(true);
                setActivatedSession(true);
                setUserReady(true);
                
                
            }else{
                setDataReady(true); 
                stopLoading();
            }
            
        } catch (error) {
            stopLoading();
            setDataReady(true);
        }
    };

    useEffect(() => {
        loadUserData();
    }, []);

    useEffect(() => {
        if (dataReady) {
          const imageUrls = [
            "/clock-icon.webp", 
            "/ClockIn_logo.webp",
            "/error-icon.webp",
            "/graphic-icon.webp",
            "/full-logo.png",
            "/loading-icon.webp",
            "/pc.webp",
            "/phone1.webp",
            "/phone2.webp",
            "/phone3.webp",
            "/success-icon.webp",
            "/tablet.webp"
          ];
          preloadImages(imageUrls).catch(() => {});
        }
      }, [dataReady]);
    

    const clearUserData = () => {
        setUser(null); // Clean the user
        setJobs([]);   // Clean the jobs
        setShifts([]); // Clean the shifts
        setActivatedSession(false);
        resetChartParamsGraphic();

    };

    const logout = async (deleteAccount = false) => {
        
        if(!deleteAccount){
            await deleteSession(); // Delete the session of the server (Only if do not delete account)
        }
      
        await deleteSessionCookie(); // Delete the cookie of the session
        setIsAuthenticated(false);
        clearUserData(); 
      
      };

     
     useEffect(() => {

        const checkForUpdates = async () => {
            
            try {
                const token = await verifySessionCookie();
                if (!token) {
                    throw new Error('Token not found in cookies');
                }

                const config = {
                    headers: {
                        Authorization: `Bearer ${token}` 
                    }
                };

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/check-shift-update`, config);
                if (response.data.updated) {
                    sessionStorage.removeItem('dataLoaded');
                }
            } catch (error) {
                /* console.error('Error checking shift update:', error); */
            }
        };

        const interval = setInterval(checkForUpdates, 600000); // 600.000 ms = 600 s = 10 min
        checkForUpdates(); 

        return () => clearInterval(interval);
    }, []); 


    return (
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, activatedSession, setActivatedSession, cookieConsent, setCookieConsent, isVisibleCookieBanner, setIsVisibleCookieBanner, showCookieBanner, checkCookieConsent, dataReady }}>
            <UserContext.Provider value={{ user, updateUser, jobs, updateJobs, shifts, updateShifts, logout, loadUserData, closedSession, setClosedSession, chartParamsGraphic, setChartParamsGraphic, lastUpdate : chartParamsGraphic.lastUpdate, isGAInitialized, setIsGAInitialized }}>
                {children}
            </UserContext.Provider>
        </AuthContext.Provider>
    );
};

// Hooks para usar los contextos
export const useAuth = () => useContext(AuthContext);
export const useUser = () => useContext(UserContext);