import ReactGA from 'react-ga4';
import axios from 'axios';

export const createSessionCookie = async (meta) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/set-cookie`, { meta });
  } catch (error) {
    /* console.error('Error setting cookie:', error); */
  }
};

export const deleteSessionCookie = async () => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/delete-cookie`);
  } catch (error) {
    /* console.error('Error deleting cookie:', error); */
  }
};

export const verifySessionCookie = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/verify-cookie`);

    if (response.data.success) {
      
      const token = response.data.token;

      return token;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const removeCookiesAnalytics = () => {
  const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID.replace('G-', '');
  const cookiesToDelete = [
    '_ga',
    '_gid',
    '_gat',
    `_ga_${analyticsId}`
  ];

  cookiesToDelete.forEach(cookie => {
    document.cookie = `${cookie}=; Max-Age=0; path=/; domain=.clockinsmart.com`;
  });
};

const verifyAndDeleteCookiesAnalytics = () => {
  const cookiesToCheck = ['_ga', '_gid', '_gat', `_ga_${process.env.REACT_APP_GOOGLE_ANALYTICS_ID.replace('G-', '')}`];
  const maxAttempts = 5;
  let attempts = 0;

  const checkCookiesAnalytics = () => {
    const remainingCookies = cookiesToCheck.filter(cookieName => {
      return document.cookie.split(";").some(cookie => cookie.trim().startsWith(cookieName));
    });

    if (remainingCookies.length === 0 || attempts >= maxAttempts) {
      if (remainingCookies.length === 0) {
        /* console.log("All Google Analytics cookies deleted successfully."); */
      } else {
        /* console.warn("Failed to delete some Google Analytics cookies:", remainingCookies); */
      }
      return;
    }

    /* console.log("Retrying cookie deletion, attempt:", attempts + 1); */
    removeCookiesAnalytics();
    attempts++;
    setTimeout(checkCookiesAnalytics, 500);
  };

  checkCookiesAnalytics();
};

export const handleConsent = (setConsent, consents) => { //From CookieConsentBanner
  localStorage.setItem('cookieConsent', consents);
  setConsent(consents);

  if (!consents) {
    verifyAndDeleteCookiesAnalytics(); // Call function directly on rejection
  }
};

export const forgetConsentPrivacy = () => {
  localStorage.removeItem('cookieConsent');
}