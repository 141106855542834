import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

const AnalyticsInitializer = ({ consent, isGAInitialized, setIsGAInitialized }) => {

  const deleteCookies = () => {
    const analyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID.replace('G-', '');
    const cookiesToDelete = [
      '_ga',
      '_gid',
      '_gat',
      `_ga_${analyticsId}`
    ];

    cookiesToDelete.forEach(cookie => {
      document.cookie = `${cookie}=; Max-Age=0; path=/; domain=.clockinsmart.com`;
    });
  };

  const verifyAndDeleteCookies = () => {
    const cookiesToCheck = ['_ga', '_gid', '_gat', `_ga_${process.env.REACT_APP_GOOGLE_ANALYTICS_ID.replace('G-', '')}`];
    const maxAttempts = 5;
    let attempts = 0;

    const checkCookies = () => {
      const remainingCookies = cookiesToCheck.filter(cookieName => {
        return document.cookie.split(";").some(cookie => cookie.trim().startsWith(cookieName));
      });

      if (remainingCookies.length === 0 || attempts >= maxAttempts) {
        if (remainingCookies.length === 0) {
          /* console.log("All Google Analytics cookies deleted successfully."); */
        } else {
          /* console.warn("Failed to delete some Google Analytics cookies:", remainingCookies); */
        }
        return;
      }

      /* console.log("Retrying cookie deletion, attempt:", attempts + 1); */
      deleteCookies();
      attempts++;
      setTimeout(checkCookies, 500);
    };

    checkCookies();
  };

  useEffect(() => {
    const initializeAnalytics = () => {
      if (consent !== null) {
        if (consent) {
          if (!isGAInitialized && process.env.NODE_ENV === 'production') {
            ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
              gaOptions: {
                anonymizeIp: true,
                transport: 'beacon'
              }
            });
            
            setIsGAInitialized(true);
            /* console.log("Google Analytics initialized in (Analytics) with consent granted."); */
          
          }
          // Re-enable Google Analytics if it was disabled
          window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`] = false;
          /* console.log('Google Analytics with consent granted.'); */
        } else {

          window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`] = true;
          /* console.log('Google Analytics consent denied.'); */

          // Attempt to delete cookies and verify
          verifyAndDeleteCookies();
        }
      }
    };

    initializeAnalytics();
  }, [consent, isGAInitialized, setIsGAInitialized]);

  return null;
};

export default AnalyticsInitializer;