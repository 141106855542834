import moment from 'moment-timezone';

export const calculateHoursBetween = (start, finish) => {

    const startDate = new Date(Date.UTC(2000, 0, 1, parseInt(start.substr(0, 2)), parseInt(start.substr(3, 2))));
    const finishDate = new Date(Date.UTC(2000, 0, 1, parseInt(finish.substr(0, 2)), parseInt(finish.substr(3, 2))));

    // If the end time is earlier than the start time, we add one day to the end date to account for the day change

    if (finishDate <= startDate) {
        finishDate.setUTCDate(finishDate.getUTCDate() + 1);
    }

    const differenceMs = finishDate - startDate;

    const hours = Math.floor(differenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    if (isNaN(hours) || isNaN(minutes)) {
      return 'Invalid time';  
  }

    return `${formattedHours}:${formattedMinutes}`;
  }

  export const convertToDecimalHours = (time) => {
    const [hoursStr, minutesStr] = time.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    const decimalHours = hours + minutes / 60;
    return parseFloat(decimalHours.toFixed(2)); //Round to two decimal places
}

export const calculateEarnedShift = (price_hour, total_time) => {
    const total_earned = price_hour * total_time;

    return total_earned;
}

export const formatDateToInputValue = (isoDateString) => {
  const date = new Date(isoDateString);
  const year = date.getUTCFullYear();
  // getMonth() returns a zero-based index, so 1 is added to get the correct month.
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Ensure the month is two digits
  const day = date.getUTCDate().toString().padStart(2, '0'); // Ensure the day is two digits

  return `${year}-${month}-${day}`;
};

export const formatDateToInputValueMMDDYYYY = (isoDateString, timezone) => {
  if(!timezone){

    //Create a Date object from the ISO string in UTC, and use UTC methods to avoid conversion to local time zone
    const date = new Date(isoDateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // // getUTCMonth() is from 0 to 11, 1 is added
    const day = date.getUTCDate().toString().padStart(2, '0'); // getUTCDate() returns the day of the month (1-31)
    return `${month}-${day}-${year}`;
  
  }else{

    const date = moment.tz(isoDateString, timezone);

    const year = date.year();
    const month = date.format('MM');  // MM format return the month from 01 to 12
    const day = date.format('DD');    // DD format return the day from 01 to 31

    return `${month}-${day}-${year}`;
  }
  
};

export const formatDateToInputYYYYMMDD = (isoDateString, timezone) => {
  if (!isoDateString) {
    return ''; // If the ISO date string is empty, it returns an empty string
  }

  try {
    let date, year, month, day;
    if (!timezone) {
      // Create a Date object from the ISO string in UTC
      date = new Date(isoDateString);
      year = date.getUTCFullYear().toString().padStart(4, '0'); // Ensure year has 4 digits
      month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Format MM
      day = date.getUTCDate().toString().padStart(2, '0'); // Format DD
    } else {
      // Use moment.js with specified timezone
      date = moment.tz(isoDateString, timezone);
      year = date.year().toString().padStart(4, '0'); // Ensure year has 4 digits
      month = date.format('MM'); // Format MM
      day = date.format('DD'); // Format DD
    }
    return `${year}-${month}-${day}`;
  } catch (error) {
    return ''; 
  }
};

export const validateTime = (time) => {
    const timePattern = /^([0-1]\d|2[0-3]):([0-5]\d)$/; // Matches HH:MM format
    return timePattern.test(time);
  };

export const convertTo12HFormat = (time) => {
  let [hours, minutes] = time.split(':');
  hours = parseInt(hours, 10); // Convert to number for calculations
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // '0' is converted in '12'
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const groupDataByDays = (startISO, endISO, allData, timezone = "UTC") => {

  const startDate = moment.tz(startISO, timezone); 
  const endDate = moment.tz(endISO, timezone);
  
  endDate.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

  const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)); //const totalDays = endDate.diff(startDate, 'days');
  const daysPerBar = Math.ceil(totalDays / 10);
         
  let groupedHours = [];
  let groupedEarnings = [];
  let labels = [];
  let current = moment.tz(startISO, timezone);

  while (current <= endDate) {
      // Calculate how many days are left until the endDate
      let daysToEnd = Math.ceil((endDate - current) / (1000 * 60 * 60 * 24));

      // Adjusts the days per bar if the remaining days are fewer than daysPerBar
      let effectiveDaysPerBar = Math.min(daysPerBar, daysToEnd);

      let periodEnd = moment(current).add(effectiveDaysPerBar - 1, 'days');

      // Ensure that periodEnd does not exceed endDate
      if (periodEnd > endDate) {
          periodEnd = moment(endDate);
      }

      // Use internal format for comparison
      const startStr = current.format('YYYY-MM-DD');
      const endStr = periodEnd.format('YYYY-MM-DD');

      // Format dates for display
      const displayStartStr = current.format('MM/DD/YYYY');
      const displayEndStr = periodEnd.format('MM/DD/YYYY');

      const periodData = allData.filter(item => {
        const itemDateStr = item.date;  
        return itemDateStr >= startStr && itemDateStr <= endStr;
    });

      const totalHours = periodData.reduce((sum, item) => {
      return sum + item.totalHours;
      }, 0);
      const totalEarned = periodData.reduce((sum, item) => sum + item.totalEarned, 0);

      groupedHours.push(totalHours ? totalHours : 0.01);
      groupedEarnings.push(totalEarned ? totalEarned : 0.01);

      labels.push(`${displayStartStr} - ${displayEndStr }`);
      current.add(effectiveDaysPerBar, 'days');

      //If the current period ends on endDate, break the loop
      if (periodEnd.isSame(endDate, 'day')) {
        break;
      }
  }

  return {
      labels,
      datasets: [
          {
              label: 'Worked Hours',
              data: groupedHours,
              backgroundColor: 'rgba(53, 162, 235, 0.5)',
              borderColor: 'rgba(53, 162, 235, 1)'
          },
          {
              label: 'Total Earned',
              data: groupedEarnings,
              backgroundColor: 'rgba(235, 53, 53, 0.5)',
              borderColor: 'rgba(235, 53, 53, 1)'
          }
      ]
  };
};


export const calculateDataForRange = (start, end, allData) => {
  const startStr = start.toISOString().split('T')[0];
  const endStr = end.toISOString().split('T')[0];
  let sum = 0;

  allData.forEach(item => {
    const itemDateStr = new Date(item.date).toISOString().split('T')[0];
    if (itemDateStr >= startStr && itemDateStr <= endStr) {
        sum += item.totalHours; 
    }
  });
  return sum;
};



export const calculateDailyData = (startISO, endISO, allData, timezone = "UTC") => {

  const startDate = moment.utc(startISO);
  const endDate = moment.utc(endISO);

  const totalDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
  let labels = [];
  let dailyData = [];
  
  for (let i = 0; i <= totalDays; i++) {
    const current = moment(startDate).add(i, 'days');

    const formattedCurrentDate = current.format('YYYY-MM-DD');

    labels.push(current.format('MM/DD/YYYY'));

    const shiftsForDay = allData.filter(shift => shift.date === formattedCurrentDate);
    const totalHours = shiftsForDay.reduce((acc, shift) => acc + shift.totalHours, 0);
    const totalEarned = shiftsForDay.reduce((acc, shift) => acc + shift.totalEarned, 0);

    dailyData.push({ totalHours, totalEarned });
  }

  return {
      labels,
      datasets: [
        { 
          label: 'Worked Hours',
          data: dailyData.map(data => data.totalHours === 0 ? 0.01 : data.totalHours), 
          backgroundColor: 'rgba(53, 162, 235, 0.5)', 
          borderColor: 'rgba(53, 162, 235, 1)' 
        },
        { 
          label: 'Total Earned',
          data: dailyData.map(data => data.totalEarned === 0 ? 0.01 : data.totalEarned),
          backgroundColor: 'rgba(235, 53, 53, 0.5)', 
          borderColor: 'rgba(235, 53, 53, 1)'
        }
      ],
  };
};


export const getNextDay = (currentMoment) => { // So that the next day of the user's time zone is passed
  let nextDay = currentMoment.clone().add(1, 'days').startOf('day');

    // Create a date in ISO format that maintains the local time as if it were UTC
    const offset = nextDay.utcOffset();
    const nextDayAdjusted = nextDay.add(offset, 'minutes').utc().format();

    // return ISO String
    return moment(nextDayAdjusted).toISOString();
}

export const preloadImages = (urls) => {
  return Promise.all(
      urls.map(
          (url) => new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;

              img.onload = () => resolve(url); // Resolve once image loads
              img.onerror = () => reject(url); // Reject on load error
          })
      )
  );
};

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

// Function that adjusts the date to UTC without changing the local time
export const adjustDateToUTCWithoutChangingTime = (dateString) => {
  return dateString.slice(0, -6) + 'Z';
}

export const todayUser = (userTimezone) => {
  const currentUserMoment  = moment().tz(userTimezone);

  // Extract the date and time components from the moment object
  const yearUser = currentUserMoment.year();
  const monthUser = currentUserMoment.month(); // January 0, December is 11
  const dateUser = currentUserMoment.date();
  const hourUser = currentUserMoment.hour();
  const minuteUser = currentUserMoment.minute();
  const secondUser = currentUserMoment.second();
  const millisecondUser = currentUserMoment.millisecond();

  const today = new Date(yearUser, monthUser, dateUser, hourUser, minuteUser, secondUser, millisecondUser);
  return today;
}

export const getDatefromISODate = (momentUtcDate) => {

  //Convert 2024-05-21T00:00:00.000Z in Tue May 21 2024 00:00:00 GMT-X
  //To create the shift on the server, I use it without the timezoneOffset since it came with the timezoneOffset added previously.
 
  const dateUTC = new Date(momentUtcDate);
  var userTimezoneOffset = dateUTC.getTimezoneOffset() * 60000;
  const result = new Date(dateUTC.getTime() + userTimezoneOffset);

  return result;
}

// Convert a date to ISO format while keeping the same absolute time
export const getISODatefromDate = (date) => {
  
  const dateISO = moment(date).format("YYYY-MM-DD[T]HH:mm:ss[Z]");
  return dateISO;
};