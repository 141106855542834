import React, {useState, useEffect, useCallback, useRef} from "react"
import "../styles/Home.css"
import "../styles/SignUp.css"
import"../styles/StrengthPassword.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"
import {isValidEmail} from "../components/UsefulFunctions.js";

import {showMessageError} from "../components/messages.js";
import {checkPasswordStrength} from "../components/strengthPassword.js";
import {setBodyClass} from "../components/ShowRecaptcha.js";

import ReactDOMServer from 'react-dom/server';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLoading } from '../components/LoadingContext.js';

// Function to generate a unique idempotency key
const generateIdempotencyKey = async () => `Signup-${new Date().getTime()}-${Math.random().toString(36).substring(2, 11)}`;

function SignUp({ consent }) {

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [privacyAccepted, setPrivacyAccepted] = useState(false);
    
    const passwordStrength1 = checkPasswordStrength(password);
    const passwordStrength2 = checkPasswordStrength(confirmPassword);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [tooltipKey1, setTooltipKey1] = useState(0); 
    const [tooltipKey2, setTooltipKey2] = useState(0); 

    const [isTouch, setIsTouch] = useState(false);
    const { startLoading, stopLoading } = useLoading();

    const isSubmitting = useRef(false);
    
    const navigate = useNavigate();  

    const [isCreatedAccount, setIsCreatedAccount] = useState(false);

    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
      document.title = "Sign Up | ClockIn Smart";
    }, []);

    useEffect(() => {
      setBodyClass("signup-page", true);
      return () => {
        setBodyClass("signup-page", false);
      };
    }, []);

    useEffect(() => {
      const isTouchDevice = () => {
        const touchEventSupport = 'ontouchstart' in window;
        const pointerCoarseSupport = window.matchMedia("(pointer: coarse)").matches;
  
        return touchEventSupport || pointerCoarseSupport;
      };
      const touchDevice = isTouchDevice();
      setIsTouch(touchDevice);
    }, []);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
        setTooltipKey1(prevKey1 => prevKey1 + 1);
        setTooltipKey2(prevKey2 => prevKey2 + 1);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    const comprobateIfExists = async ({ email }) => {
      try {
        // Make a request to the server to check if the email is already registered
        const responseEmail = await axios.post(`${process.env.REACT_APP_API_URL}/api/checkEmail`, {
          email: email,
        });
  
        return responseEmail.data.exists; // If true, exists, if false, it does not exist, so the user can be created.
      } catch (error) {
        showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000);
      }
    };

    const comprobateRecaptcha = useCallback( (e) => {
      e.preventDefault();
  
      if (!executeRecaptcha) {
        showMessageError("Error. Execute recaptcha not yet available", setIsError, setErrorMessage, 3000);
        return;
      }

      if(!privacyAccepted){
        showMessageError("Error. The privacy policy must to be accepted", setIsError, setErrorMessage, 3000);
        return;
      }
  
      executeRecaptcha("formRecaptcha").then((gReCaptchaToken) => {
        createUser(gReCaptchaToken);
      });
      
    },[executeRecaptcha, privacyAccepted, name, surname, email, password, confirmPassword]);
 
    const createUser = async (gReCaptchaToken) => {

      if (isSubmitting.current) return;
      isSubmitting.current = true;

      startLoading();

      const responseCaptcha = await axios.post(`${process.env.REACT_APP_API_URL}/verifyRecaptcha`, {
        captchaValue: gReCaptchaToken,
      });

      if(responseCaptcha.data.success){
            // Validate that all fields are complete
        if ( name.trim() !== "" && surname.trim() !== "" && email.trim() !== "" && password.trim() !== "" && confirmPassword.trim() !== "" ) {

          const passwordsMatch = (password === confirmPassword);

          // Validate that passwords match
          if (passwordsMatch) {

            if (passwordStrength1.strength < 4 || passwordStrength2.strength < 4){
              stopLoading();
              isSubmitting.current = false;
              showMessageError("Error. The password must to be strong", setIsError, setErrorMessage, 3000);
              return;
            }

            if(!isValidEmail(email)){
              stopLoading();
              isSubmitting.current = false;
              showMessageError("Error. Invalid email format", setIsError, setErrorMessage, 3000);
              return;
            }

            // Validate if user exists
            const emailExists = await comprobateIfExists({ email });
    
            if (emailExists) {
              stopLoading();
              isSubmitting.current = false;
              showMessageError("Error. The email is already registered", setIsError, setErrorMessage, 2000);
              return;
            } else {

              const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

              // Generate a new Idempotency key
              const newIdempotencyKey = await generateIdempotencyKey();

              const config = {
                  headers: {
                    'idempotency-key': newIdempotencyKey
                  }
              };

              try {
                // Make the POST request to the server
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/signup`, {
                  name: name.trim(),
                  surname: surname.trim(),
                  email: email,
                  password: password,
                  timezone: timezone
                },config);

                if(response.data.success){
                  
                  isSubmitting.current = false;
                  setIsCreatedAccount(true); 
                  //The email will be send.
                  try {
                    // Generate a new Idempotency key
                    const newIdempotencyKey = await generateIdempotencyKey();

                    const config = {
                        headers: {
                          'idempotency-key': newIdempotencyKey
                        }
                    };

                    await axios.post(`${process.env.REACT_APP_API_URL}/api/send-activate`, {
                      emailActivate: email,
                      nameActivate: name.trim()
                    }, config);

                    stopLoading();
                    isSubmitting.current = false;
                    //The form will be empty
                    setName("");
                    setSurname("");
                    setEmail("");
                    setPassword("");
                    setConfirmPassword("");
                    setPrivacyAccepted(false);
        
                  } catch (error) {
                    stopLoading();
                    isSubmitting.current = false;
                    showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000);
                  }

                }else{
                  stopLoading();
                  isSubmitting.current = false;
                  showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000);
                }
                
              } catch (error) {
                stopLoading();
                isSubmitting.current = false;
                showMessageError("An unexpected error has occurred", setIsError, setErrorMessage, 3000);
              }
            }
          } else {
            stopLoading();
            isSubmitting.current = false;
            showMessageError("Error. The passwords do not match", setIsError, setErrorMessage, 3000);
          } 
        } else {
          stopLoading();
          isSubmitting.current = false;
          showMessageError("Error. All fields must be filled", setIsError, setErrorMessage, 3000);
        }

        }else{
          stopLoading();
          isSubmitting.current = false;
          showMessageError("Error Captcha", setIsError, setErrorMessage, 3000);
        }
      };

      const goToLogin = () => {
        setIsCreatedAccount(false);
        navigate("/Login");
      }

      const jsxToHtmlString = (jsxElement) => {
        // Render the JSX element to an HTML string
        return ReactDOMServer.renderToString(jsxElement);
      };

      const handlePrivacyCheckboxChange = (e) => {
        setPrivacyAccepted(e.target.checked);
      };

    return (
      <>
        { !isCreatedAccount && (
          <div className="register-container">
            <form onSubmit={comprobateRecaptcha}>
                <h3 className="register-title">Create account </h3>

                <div className="error-message-container">
                  {isError && <p className="message-alert red">{errorMessage}</p>}
                </div>

                <div className="register-attribute">
                    <label htmlFor="name">Name</label>
                    <input type="text" placeholder="Name" className="form-control" onChange={(e) => setName(e.target.value)} value={name} required></input>
                </div>

                <div className="register-attribute">
                    <label htmlFor="username">Surname</label>
                    <input type="text" placeholder="Surname" className="form-control" onChange={(e) => setSurname(e.target.value)} value={surname} required></input>
                </div>

                <div className="register-attribute">
                    <label htmlFor="email">Email</label>
                    <input type="email" placeholder="Email" className="form-control" onChange={(e) => setEmail(e.target.value)} value={email} required></input>
                </div>

                  <div className="register-attribute">
                      <label htmlFor="password">Password</label>
                      {!isTouch ? (
                      <Tooltip
                        key={tooltipKey1}
                        title={jsxToHtmlString(
                          <ul className="tooltip-content">
                            <li className={passwordStrength1.conditions.minLength ? 'list-item valid' : 'list-item'}>
                              At least 8 characters
                            </li>
                            <li className={passwordStrength1.conditions.containsNumber ? 'list-item valid' : 'list-item'}>
                              Contains at least one number
                            </li>
                            <li className={passwordStrength1.conditions.containsUppercase ? 'list-item valid' : 'list-item'}>
                              Contains at least one uppercase letter
                            </li>
                            <li className={passwordStrength1.conditions.containsLowercase ? 'list-item valid' : 'list-item'}>
                              Contains at least one lowercase letter
                            </li>
                            <li className={passwordStrength1.conditions.containsSpecialChar ? 'list-item valid' : 'list-item'}>
                              Contains at least one special character
                            </li>
                          </ul>
                        )}
                        position={windowWidth < 905 ? "bottom" : "right"}
                        trigger="click"
                        arrow
                        theme="light"
                        distance={2}
                      >
                        <div className="password-container">
                          <input
                            type="password"
                            placeholder="Password"
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                          />
                          {password && (
                            <span
                              className={`password-strength-message ${checkPasswordStrength(password).message.toLowerCase()}`}
                            >
                              {checkPasswordStrength(password).message}
                            </span>
                          )}
                        </div>
                      </Tooltip>): (
                        <div className="password-container">
                          <input
                            type="password"
                            placeholder="Password"
                            className="form-control"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                          />
                          {password && (
                            <span
                              className={`password-strength-message ${checkPasswordStrength(password).message.toLowerCase()}`}
                            >
                              {checkPasswordStrength(password).message}
                            </span>
                          )}
                        </div>
                      )}
                  </div>

                  <div className="register-attribute">
                      <label htmlFor="password">Confirm password</label>
                      {!isTouch ? (
                      <Tooltip
                        key={tooltipKey2}
                        title={jsxToHtmlString(
                          <ul className="tooltip-content">
                            <li className={passwordStrength2.conditions.minLength ? 'list-item valid' : 'list-item'}>
                              At least 8 characters
                            </li>
                            <li className={passwordStrength2.conditions.containsNumber ? 'list-item valid' : 'list-item'}>
                              Contains at least one number
                            </li>
                            <li className={passwordStrength2.conditions.containsUppercase ? 'list-item valid' : 'list-item'}>
                              Contains at least one uppercase letter
                            </li>
                            <li className={passwordStrength2.conditions.containsLowercase ? 'list-item valid' : 'list-item'}>
                              Contains at least one lowercase letter
                            </li>
                            <li className={passwordStrength2.conditions.containsSpecialChar ? 'list-item valid' : 'list-item'}>
                              Contains at least one special character
                            </li>
                          </ul>
                        )}
                        position={windowWidth < 905 ? "bottom" : "right"}
                        trigger="click"
                        arrow
                        theme="light"
                        distance={2}
                      >
                        <div className="password-container">
                        <input type="password" placeholder="Confirm password" className="form-control" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} required></input>
                        {confirmPassword && (
                            <span className={`password-strength-message ${checkPasswordStrength(confirmPassword).message.toLowerCase()}`}>
                                {checkPasswordStrength(confirmPassword).message}
                            </span>
                        )}
                      </div>

                      </Tooltip>
                      ):(
                        <div className="password-container">
                          <input type="password" placeholder="Confirm password" className="form-control" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} required></input>
                          {confirmPassword && (
                              <span className={`password-strength-message ${checkPasswordStrength(confirmPassword).message.toLowerCase()}`}>
                                  {checkPasswordStrength(confirmPassword).message}
                              </span>
                          )}
                        </div>
                      )}
                </div>

                <div className="signup-privacy">
                  <input type="checkbox" id="privacyCheckbox" checked={privacyAccepted} onChange={handlePrivacyCheckboxChange} required/>
                  <label htmlFor="privacyCheckbox">I accept the <Link to="/Privacy" className="privacy-checkbox">privacy policy</Link></label>
                </div>

                <div className="submit-button-register">
                    <button type="submit">Sign Up</button>
                </div>

                <p className="register-goToLogin">
                    Already registered <Link to="/Login">Sign In</Link>
                </p>
            </form>
          </div>)}

        { isCreatedAccount && (
          <div className="register-container register">
            <h3 className="message-alert green">Congratulations! Your account has been created</h3>
            <p className="message-alert grey">If you do not receive the activation email or encountered issues, use password reset as an alternative activation method.</p>
            <h3 className="register-title">What are the next steps?</h3>
            <ul className="instructions-register">
              <li>Check your email.</li>
              <li>Activate the account by clicking the button in the email.</li>
              <li>Ensure the email address you entered is spelled correctly.</li>
            </ul>

            <div className="submit-button-register">
              <button type="button" onClick={goToLogin}>Go to Log In</button>
            </div>
            
          </div>   
        )}
      </>
    )
}

export default SignUp