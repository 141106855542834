import React, {useEffect} from "react";
import "../styles/Privacy.css";
import {Link} from "react-router-dom";

function Privacy() {
  useEffect(() => {
    document.title = "Privacy | ClockIn Smart";
  }, []);

  return (
      <div className="privacy-container">
        <h1 className="privacy-title">Privacy Policy</h1>
        <hr className="privacy-divider" />
        <div className="privacy-content">
          <p>This Privacy Policy outlines the methods by which the ClockIn Smart platform collects, uses, and safeguards personal information. By engaging with the platform, you agree to the data practices described herein.</p>
          <p><strong>Note:</strong> By accepting the privacy policy, you also agree to the use of essential cookies necessary for the functionality and security of the platform. To accept optional cookies, you must do so through the cookie banner or from your authenticated user profile. Essential cookies are assumed to be accepted for using the website, while optional cookies require your explicit consent. However, to create an account or send an email through contact, you need to give your explicit consent for essential cookies (excluding optional ones). Although the acceptance of essential cookies is assumed, this consent must be explicit.</p>
          
          <h2>Identity and Contact Details</h2>
          <p><strong>Controller:</strong> Julio Montesinos Alvarado</p>
          <p><strong>Contact:</strong> support@clockinsmart.com</p>
          
          <h2>Collection of Personal Information</h2>
          <p>Personal information is collected to enhance user experience and deliver customized services. The information collected includes:</p>
          <ul>
            <li><strong>Voluntarily provided information: </strong>Includes, but is not limited to, names, surnames, email addresses, and passwords. Information related to the jobs posted and shifts registered on the platform is also collected.</li>
            <li><strong>Automatically Collected Information: </strong>The platform may automatically collect information about your device and activity anonymously. This includes browser types, time zones, operating systems, last access, account status, and other device technologies. Google Analytics is configured to anonymize IP addresses and is used for analytics. reCAPTCHA, which also collects IP addresses, is used to ensure the authenticity of users, but this information is managed by Google and not by our platform.</li>
          </ul>

          <h2>Legal Basis for Processing</h2>
          <ul>
            <li><strong>Consent:</strong> For optional cookies and certain marketing activities</li>
            <li><strong>Contract:</strong> For the fulfillment of services provided through the platform.</li>
            <li><strong>Legal obligation:</strong> Compliance with legal obligations.</li>
            <li><strong>Legitimate Interests:</strong> To improve services and ensure platform security.</li>
          </ul>

          <h2>Use of Personal Information</h2>
          <p>The information collected is utilized to:</p>
          <ul>
            <li>Enhance and personalize user experiences on the platform.</li>
            <li>Analyze user behavior and preferences to improve services.</li>
            <li>Respond efficiently to customer service and support inquiries.</li>
          </ul>

          <h2>Cookies and Tracking Technologies</h2>
          <ul>
            <li><strong>Essential Cookies: </strong>Crucial for maintaining user session states, managing user consent preferences, and ensuring the security and integrity of the platform.</li>
            <li><strong>Google reCAPTCHA: </strong>Helps prevent spam and abuse by verifying that actions are performed by humans, managed according to Google's <a className="url-privacy" href="https://policies.google.com/privacy">Privacy Policy</a>.</li>
          </ul>

          <h2>Optional Tracking:</h2>
          <ul>
            <li><strong>Google Analytics: </strong>Analyzes user interactions on the platform. This includes tracking the frequency of page visits, login activities, and job or shift management activities. Managed according to Google's own <a className="url-privacy" href="https://policies.google.com/privacy">Privacy Policy</a>.</li>
          </ul>

          <h2>Managing Cookies</h2>
          <p>Users can accept or decline optional cookies. Essential cookies, required for basic functionalities and security, cannot be declined as they are integral to our services. Users may adjust their browser settings to block cookies, which may impact platform functionality.</p>

          <h2>Data Security</h2>
          <p>Robust security measures protect personal information:</p>
          <ul>
            <li><strong>Encryption: </strong>Sensitive data is encrypted both in transit and at rest.</li>
            <li><strong>Authentication and Auditing: </strong>Strong authentication measures are enforced, supplemented by periodic audits to secure the systems.</li>
          </ul>

          <h2>Cookies and Storage Used</h2>
          <p>Here is a list of cookies, session storage, and local storage items used on the platform, along with their purposes:</p>
          <div className="cookies-table-container">
            <table className="cookies-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Purpose</th>
                  <th>Duration</th>
                  <th>Optional/Essential</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>_ga</td>
                  <td>Cookie</td>
                  <td>Used by Google Analytics to distinguish users anonymously.</td>
                  <td>2 years</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>Cookie</td>
                  <td>Used by Google Analytics to distinguish users anonymously.</td>
                  <td>24 hours</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>_gat</td>
                  <td>Cookie</td>
                  <td>Used by Google Analytics to throttle request rate.</td>
                  <td>1 minute</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>_ga_ID</td>
                  <td>Cookie</td>
                  <td>Used by Google Analytics to persist session state anonymously.</td>
                  <td>2 years</td>
                  <td>Optional</td>
                </tr>
                <tr>
                  <td>_grecaptcha</td>
                  <td>Cookie</td>
                  <td>Used by Google reCAPTCHA to verify that actions are performed by humans and to ensure security, collecting the IP address anonymously.</td>
                  <td>6 months</td>
                  <td>Essential</td>
                </tr>
                <tr>
                  <td>token_meta</td>
                  <td>Session Storage</td>
                  <td>Maintains the user’s session state across page requests.</td>
                  <td>Session duration</td>
                  <td>Essential</td>
                </tr>
                <tr>
                  <td>dataLoaded</td>
                  <td>Session Storage</td>
                  <td>Indicates whether the initial data load has completed to avoid redundant requests.</td>
                  <td>Session duration</td>
                  <td>Essential</td>
                </tr>
                <tr>
                  <td>cookieConsent</td>
                  <td>Local Storage</td>
                  <td>Stores the user's consent decision for cookies.</td>
                  <td>2 years or until changed</td>
                  <td>Essential</td>
                </tr>
                <tr>
                  <td>isGAInitialized</td>
                  <td>Local Storage</td>
                  <td>Tracks whether Google Analytics has been initialized to prevent multiple initializations.</td>
                  <td>2 years or until changed</td>
                  <td>Essential</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p><strong>Note:</strong> The duration of cookies created by Google Analytics and Google reCAPTCHA is subject to change by Google at any time.</p> 

          <h2>Retention of Personal Information</h2>
          <p>Personal information is retained for as long as necessary to fulfill the purposes for which it was collected or as required by law.</p>

          <h2>User Rights</h2>
          <p>Users may access, correct, or delete their personal information. Additionally, processing can be restricted by altering cookie preferences or by account deletion, which results in the immediate and permanent removal of data. As the data collected by Google Analytics is anonymous, it cannot be associated with a specific user, and therefore cannot be accessed, corrected, or deleted on a user-specific basis. However, users can control the collection of their data by managing their cookie preferences.</p>

          <h2>International Data Transfers</h2>
          <p>Data is primarily stored and processed in the European Economic Area (EEA) as the MongoDB cluster is located in Paris, France. Therefore, accessing the platform from outside the EEA, including from countries like Australia, does not result in the data being transferred outside the EEA, as the data remains stored and processed in Paris.</p>

          <h2>Use of MongoDB</h2>
          <p>MongoDB is used as the database management system, with the primary cluster located in Paris, France. This ensures that data is stored within the European Economic Area (EEA) and is protected by robust security measures.</p>

          <h2>Updates to the Privacy Policy</h2>
          <p>This policy may be updated at any time. Significant changes will be communicated via a notice on the platform or by email.</p>

          <h2>Contact</h2>
          <p>For questions or concerns about this privacy policy, please visit the <Link to="/Contact" className="url-privacy">Contact page</Link>.</p>
        </div>
      </div>
  );
}

export default Privacy;