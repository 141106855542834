import React from 'react';

function LoadingScreen() {

  const handleContextMenuLoading = (event) => {
    event.preventDefault();
  };

  return (
    <div className="main-loading-screen">
      <video autoPlay loop muted className="loading-gif" onContextMenu={handleContextMenuLoading}>
        <source src="/video-gif.mp4" type="video/mp4" alt="Loading" />
        Loading...
      </video>
    </div>
  );
}

export default LoadingScreen;