import React, {useEffect, useState, useRef, useCallback} from "react"
import axios from "axios";
import moment from 'moment-timezone';
import { useUser } from '../components/UserContext';
import { showModalSuccess, showModalError} from "../components/messages.js";
import "../styles/Home.css"
import "../styles/App.css";
import "../styles/Purpose.css";
import { useNavigate } from 'react-router-dom';
import {initSessionValidation} from "../components/Auth"
import {calculateHoursBetween, convertToDecimalHours, calculateEarnedShift, getNextDay, getDatefromISODate, validateTime} from "../components/UsefulFunctions.js";
import {fetchData} from "../components/LoadJobsShifts.js";
import {verifySessionCookie} from "../components/cookies.js";
import PurposeWrapper from '../components/PurposeWrapper';
import BreakInput from "../components/BreakInput.js";

import { useLoading } from '../components/LoadingContext.js';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Function to generate a unique idempotency key
const generateIdempotencyKey = async () => `Home-${new Date().getTime()}-${Math.random().toString(36).substring(2, 11)}`;

function Home ({isAuthenticated, activatedSession, setActivatedSession, closedSession, setClosedSession, consent}) {

    const { user, jobs, updateJobs, updateShifts, logout } = useUser(); 

    const [selectedJob, setSelectedJob] = useState("All");
    const [allOptions, setAllOptions] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [finishTime, setFinishTime] = useState("");
    const [messageModal, setMessageModal] = useState("");
    const [smallMessageModal, setSmallMessageModal] = useState("");

    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const { startLoading, stopLoading } = useLoading();
    
    const [showHomeTimer, setShowHomeTimer] = useState(true);
    const [thereAreJobs, setThereAreJobs] = useState(true);
    const [registeredJob, setRegisteredJob] = useState(false);

    const [isScheduleShift, setIsScheduleShift] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [selectedRecurrence, setSelectedRecurrence] = useState("Select");
    const [breakTime, setBreakTime] = useState("00:00");

    const [loadingSchedule, setLoadingSchedule] = useState(false);
    const hasCheckedSession = useRef(false);
    

    const isSubmitting = useRef(false);

    const purposeRef = useRef(null);
    
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Home | ClockIn Smart";
      }, []);

    useEffect(() => { 
        comprobateSession(); 
    },[isAuthenticated, navigate])
    
    const comprobateSession = async() => {
    const isSessionActivated = await initSessionValidation();
        if(isSessionActivated.success)
            setActivatedSession(true);
        else{
            if(isAuthenticated) // If the session was previously initiated
                setClosedSession(true);
            await logout();
            sessionStorage.removeItem('dataLoaded');
        }    
    }
    
    useEffect(() => {
        const alreadyLoaded = sessionStorage.getItem('dataLoaded');
    
        if (!alreadyLoaded && isAuthenticated && user) {
            /* startLoading(); */
            // Call fetchData with only the necessary arguments
            fetchData(user, updateJobs, updateShifts).then(() => {
                sessionStorage.setItem('dataLoaded', 'true');
                stopLoading(); 
            }).catch(() => {
                stopLoading();
            });
        }
    }, [isAuthenticated, user, updateJobs]);


      useEffect(() => {
        if (!user) return;

        if (!Array.isArray(jobs)) {
            /* console.error("Expected jobs to be an array, but got:", jobs); */
            return;
        }


        const userJobsTemp = jobs; // Los trabajos ya están filtrados por el servidor
        const uniqueOptions = new Set();

        userJobsTemp.forEach(job => {
            const option = `${job.name} [${job.position}]`;
            uniqueOptions.add(option);
        });

        let options; 
        if(uniqueOptions.size !== 0){
            setThereAreJobs(true);
            options = ["Select", ...uniqueOptions];
        }
            
        else{
            setThereAreJobs(false);
            options = ["Select", "No available jobs"];
        }

        setAllOptions(options);  
    
      }, [jobs, user]);

      const scrollToPurpose = () => {

        if (purposeRef.current) {
            purposeRef.current.scrollIntoView({ behavior: "smooth" });
        }
      };


      const closeModalError = () => {
        setShowHomeTimer(true); 
        setIsError(false);   
        setRegisteredJob(false);
      }

      const goSignIn = () => {
        setClosedSession(false); // To make the logout message disappear
        navigate('/Login');
      }

      const changeViewScheduleShift = () => {
        setIsScheduleShift(!isScheduleShift);
      }

      const breakTimeChange = (newTime) => {
        setBreakTime(newTime); // Update the state with the new break time
    };

      const addScheduledShift = async () => {

        if (isSubmitting.current) return;
        isSubmitting.current = true;
        
        const isSessionActivated = await initSessionValidation();
        if(!isSessionActivated.success){
            isSubmitting.current = false;
            setClosedSession(true);
            await logout();
            return;
        }

        if(!thereAreJobs){
            setShowHomeTimer(false);
            isSubmitting.current = false;
            showModalError("Looks like you haven't added any jobs yet!", "Just head over to the 'New Job' option in the Historial section to get started. After that, you'll be all set to add shifts from the main page", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
            return;
        }else{ 
            setShowHomeTimer(true);                                
            if(!startTime.trim("") || !finishTime.trim("") || !startDate || !endDate || (selectedRecurrence === "select") || selectedJob === "Select"){
                if(selectedJob === "Select"){
                    isSubmitting.current = false;
                    showModalError("Oops! It looks like you missed selecting a job", "Please choose a job from the list to proceed", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                    return;          
                }else{
                    isSubmitting.current = false;
                    showModalError("Oops! It seems like some details are missing", "Please make sure to fill in all the fields before proceeding", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                    return;
                }
            }

            if(!validateTime(startTime) || !validateTime(finishTime)){
                isSubmitting.current = false;
                showModalError("Ops! The shift could not created. Invalid time format", "Please adjust your time", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                return;
            }

            if(selectedRecurrence !== "daily" && selectedRecurrence !== "weekly" && selectedRecurrence !== "fortnight" && selectedRecurrence !== "monthly"){
                isSubmitting.current = false;
                showModalError("Ops! The shift could not created. Invalid Recurrence format", "Please adjust your recurrence", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                return;
            }

            // To display the loading message...
            setLoadingSchedule(true);
            setIsError(true);   
            setRegisteredJob(true);

            try{

                const token = await verifySessionCookie(); 

                if (!token) {
                    isSubmitting.current = false;
                    return;
                }

                // Configuración de la cabecera de autorización y los parámetros de la consulta
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}` // Aquí se incluye el token en el encabezado
                    }
                };
                const email = user.email;
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/countScheduledShifts/${email}`,config);
                
                if(!response.data.success){
                    setLoadingSchedule(false);
                    isSubmitting.current = false;
                    showModalError("Error to count the scheduledShifts", "Please adjust your dates or recurrence", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                    return;
                }
                
                const countScheduledShifts = response.data.countScheduledShifts;

                // Calculate the number of days between startDate and endDate
                // Convert startDate and endDate to Date objects and adjust them to UTC
                const startUtc = new Date(startDate);
                startUtc.setMinutes(startUtc.getMinutes() - startUtc.getTimezoneOffset());
                const endUtc = new Date(endDate);
                endUtc.setMinutes(endUtc.getMinutes() - endUtc.getTimezoneOffset());

                const differenceInDays = (endUtc - startUtc) / (1000 * 3600 * 24);

                // Calculate the number of additional shifts based on the recurrence
                let additionalShifts = 0;
                switch (selectedRecurrence) {
                case 'daily':
                    additionalShifts = Math.ceil(differenceInDays) + 1;
                    break;
                case 'weekly':
                    additionalShifts = Math.ceil(differenceInDays / 7) + 1;
                    break;
                case 'fortnight':
                    additionalShifts = Math.ceil(differenceInDays / 14) + 1;
                    break;
                case 'monthly':
                    additionalShifts = Math.ceil(differenceInDays / 30) + 1;
                    break;
                case 'default':
                    additionalShifts = 30;
                    break;
                }

                if (countScheduledShifts + additionalShifts > 30) {
                    const shiftsRemaining = 30 - countScheduledShifts;
                    setLoadingSchedule(false);
                    isSubmitting.current = false;
                    showModalError(
                        `You have reached the limit of scheduled shifts. Currently you have scheduled ${countScheduledShifts} shifts, and you can only add ${shiftsRemaining} more`, 
                        "Please adjust your dates or recurrence", 
                        setIsError, 
                        setRegisteredJob, 
                        setMessageModal, 
                        setSmallMessageModal
                    );
                    return;
                }


                const openingBracketIndex = selectedJob.indexOf("[");
                const closingBracketIndex = selectedJob.indexOf("]");

                if (openingBracketIndex !== -1 && closingBracketIndex !== -1) {

                    const name = selectedJob.substring(0, openingBracketIndex).trim();
                    const position = selectedJob.substring(openingBracketIndex + 1, closingBracketIndex).trim();

                    const responseJobDetails  = await axios.post(`${process.env.REACT_APP_API_URL}/api/getJobDetails`, {
                        name: name,
                        position: position,
                        email: user.email
                    });
                    const jobDetails = responseJobDetails.data.job;


                    if (jobDetails) {
                        // Then, create the shift with the obtained details
                        const timeString  = calculateHoursBetween(startTime, finishTime);
                        const timeDecimal = convertToDecimalHours(timeString);
                        const breakTimeDecimal = convertToDecimalHours(breakTime);
                        const totalTime  = parseFloat(timeDecimal - breakTimeDecimal).toFixed(2);                        

                        if(totalTime <= 0){
                            setLoadingSchedule(false);
                            isSubmitting.current = false;
                            showModalError("Ops! Break time cannot exceed the total shift duration", "Please adjust your times", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                            return;
                        }

                        const earnedShift = parseFloat(calculateEarnedShift(jobDetails.price_hour, totalTime)).toFixed(2);

                        // Generate a new Idempotency key
                        const newIdempotencyKey = await generateIdempotencyKey();

                        const config = {
                            headers: {
                              'idempotency-key': newIdempotencyKey
                            }
                        };
                        
                        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/createScheduledShift`, {
                            job_id: jobDetails._id,
                            start_date: startUtc.toISOString(),
                            finish_date: endUtc.toISOString(),
                            start_time: startTime,
                            finish_time: finishTime,
                            total_time: totalTime,
                            break_time: breakTimeDecimal,
                            earned_shift: earnedShift,
                            recurrence: selectedRecurrence
                            
                        }, config);

                        if(response.data.success){
                            setLoadingSchedule(false);
                            setIsError(false);
                            isSubmitting.current = false;
                            sessionStorage.removeItem('dataLoaded');
                            showModalSuccess("Scheduled shift successfully registered", setIsSuccess, setRegisteredJob, setMessageModal, 2500);
                            setTimeout(() => {
                                navigate("/Historial");
                            }, 2500); 

                        }else{
                            setLoadingSchedule(false);
                            showModalError("Ops! Something wrong happen.", "Please try again. If the issue persists, contact support", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                        }  
                        isSubmitting.current = false;                   
                    } 
                }else{
                    setLoadingSchedule(false);
                    if(selectedJob === "All"){
                        showModalError("Ops! We can't proceed without a job selection.", "Make sure to select a job that's currently available in the list", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                    }else{
                        showModalError("Looks like there's a mix-up in the job format.", "Please try again. If the issue persists, contact support", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                    }
                    isSubmitting.current = false;
                }
            }catch{
                isSubmitting.current = false;
                setLoadingSchedule(false);
                showModalError("Uh-oh! The job selected isn't available anymore.", "Please refresh the list and select another job", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
            }

        }
    };

      const addShift = async () => {

        if (isSubmitting.current) return;
        isSubmitting.current = true;
        startLoading();
        const isSessionActivated = await initSessionValidation();
        if(!isSessionActivated.success){
            setClosedSession(true);
            isSubmitting.current = false;
            await logout();
            return;
        }

        if(!thereAreJobs){
            stopLoading();
            setShowHomeTimer(false);
            isSubmitting.current = false;
            showModalError("Looks like you haven't added any jobs yet!", "Just head over to the 'Manage Jobs' option in the Historial section to create a new job. After that, you'll be all set to add shifts from the main page", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
            return;
        }else{
            setShowHomeTimer(true);
            
            if(startTime !== "" && finishTime !== ""){
                if(selectedJob === "Select"){
                    stopLoading();
                    isSubmitting.current = false;
                    showModalError("Oops! It looks like you missed selecting a job.", "Please choose a job from the list to proceed", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                    return;          
                }
            }else{
                stopLoading();
                isSubmitting.current = false;
                showModalError("Oops! It seems like some details are missing.", "Please make sure to fill in all the fields before proceeding", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                return;
            }

            try{
                const openingBracketIndex = selectedJob.indexOf("[");
                const closingBracketIndex = selectedJob.indexOf("]");

                if (openingBracketIndex !== -1 && closingBracketIndex !== -1) {

                    const name = selectedJob.substring(0, openingBracketIndex).trim();
                    const position = selectedJob.substring(openingBracketIndex + 1, closingBracketIndex).trim();
                    
                    const currentUserMoment = moment().tz(user.timezone);
                    // What needs to be done now is to set that date at 00:00h without modifying the day due to UTC, and then convert it to Date
                    const localStartMoment = moment.parseZone(currentUserMoment);
                    // Adjust the date to the start of the day in its original time zone
                    const startOfDayLocal = localStartMoment.startOf('day');

                    /* Now, with the Date variable of the user's day at 00:00h, we will check that if the UTC is negative,
                    we will have to add those hours. If it's positive, it will be set at 00:00h. */
                    const timezoneOffset = moment.tz(user.timezone).utcOffset() / 60;
                    const adjustedHour = timezoneOffset < 0 ? -timezoneOffset : 0;


                    if(timezoneOffset < 0){ // If it's >0, it will remain the same, which will be at 00:00h
                        startOfDayLocal.add(adjustedHour, "hours");
                    }

                    // Convert that date to UTC while maintaining the same day and time
                    const startOfDayUTC = startOfDayLocal.utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

                    const email = user.email;
                    const responseJobDetails  = await axios.post(`${process.env.REACT_APP_API_URL}/api/getJobDetails`, {
                        name: name,
                        position: position,
                        email: email
                    });
                    const jobDetails = responseJobDetails.data.job;

                    if (jobDetails) {
                        // Then, create the shift with the obtained details.
                        const timeString  = calculateHoursBetween(startTime, finishTime);
                        const timeDecimal = convertToDecimalHours(timeString);
                        const breakTimeDecimal = convertToDecimalHours(breakTime);
                        const totalTime  = parseFloat(timeDecimal - breakTimeDecimal).toFixed(2);

                        if(totalTime <= 0){
                            stopLoading();
                            showModalError("Ops! Break time cannot exceed the total shift duration", "Please adjust your times", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                            isSubmitting.current = false;
                            return;
                        }

                        const earnedShift = parseFloat(calculateEarnedShift(jobDetails.price_hour, totalTime)).toFixed(2);

                        // Generate a new Idempotency key
                        const newIdempotencyKey = await generateIdempotencyKey();

                        const config = {
                            headers: {
                              'idempotency-key': newIdempotencyKey
                            }
                        };
                        
                        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/createShift`, {
                            job_id: jobDetails._id, // Make sure this is the correct way to obtain the job ID
                            date: startOfDayUTC,
                            start_time: startTime,
                            finish_time: finishTime,
                            total_time: totalTime, 
                            break_time: breakTimeDecimal,
                            earned_shift: earnedShift,
                            status: 'active'
                        }, config);

                        if(response.data.success){
                            stopLoading();
                            sessionStorage.removeItem('dataLoaded');
                            isSubmitting.current = false;
                            showModalSuccess("Shift successfully registered", setIsSuccess, setRegisteredJob, setMessageModal, 2500);
                            setTimeout(() => {
                                navigate("/Historial");
                            }, 2500); 


                        }else{
                            stopLoading();
                            isSubmitting.current = false;
                            showModalError("Ops! Something wrong happen.", "Please try again. If the issue persists, contact support", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                        }
                        
                    } 
                }else{
                    if(selectedJob === "All"){
                        showModalError("Hmm, we can't proceed without a job selection.", "Make sure to select a job that's currently available in the list", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                    }else{
                        showModalError("Looks like there's a mix-up in the job format.", "Please try again. If the issue persists, contact support", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
                    }
                    stopLoading();
                    isSubmitting.current = false;
                }
            }catch{
                stopLoading();
                isSubmitting.current = false;
                showModalError("Uh-oh! The job selected isn't available anymore.", "Please refresh the list and select another job", setIsError, setRegisteredJob, setMessageModal, setSmallMessageModal);
            }
        }    
    }

    return (
        <>
        {isAuthenticated && activatedSession && !closedSession && (
            <>
            { showHomeTimer && !registeredJob && !isScheduleShift && (
                <div className="time-container">
                    <div className="time-firstRow-element">
                        <div className="start-time">
                            <h1>Start Time</h1>
                            <input type="time" className="time-input" value={startTime} onChange={(e)=> setStartTime(e.target.value)}/> 
                        </div>
                        <div className="finish-time">
                            <h1>End Time</h1>
                            <input type="time" className="time-input" value={finishTime} onChange={(e) => setFinishTime(e.target.value)}/> 
                        </div>
                    </div>
                    <div className="time-SecondRow-element">
                        <div className="break">
                            <h1>Break</h1>
                            <BreakInput onTimeChange={breakTimeChange} />
                        </div>
                        <div>
                            <h1>Job</h1>
                            <select id="optionsHome" className="job-input" value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)}>
                            {allOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                            </select>
                        </div>
                    </div>
                    <div className="buttons-container">
                        <button className="time-add" onClick={changeViewScheduleShift}>SCHEDULE SHIFT</button>
                        <button className="time-add" onClick={addShift}>ADD SHIFT</button>
                    </div>
                </div>
            )}
            {/**SCHEDULED SHIFT */}
            { showHomeTimer && !registeredJob && isScheduleShift && (
                <div className="time-container">
                    <div className="time-firstRow-element">
                        <div className="start-time">
                            <h1>Start Time</h1>
                            <input type="time" className="time-input" value={startTime} onChange={(e)=> setStartTime(e.target.value)}/> 
                        </div>
                        <div className="finish-time">
                            <h1>End Time</h1>
                            <input type="time" className="time-input" value={finishTime} onChange={(e) => setFinishTime(e.target.value)}/> 
                        </div>
                    </div>
                    <div className="time-SecondRow-element">
                        <div className="break">
                            <h1>Break</h1>
                            <BreakInput onTimeChange={breakTimeChange} />
                        </div>
                        <div className="job-schedule">
                            <h1>Job</h1>
                            <select id="optionsHome" className="job-input" value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)}>
                            {allOptions.map(option => (
                                <option key={option} value={option}>{option}</option>
                            ))}
                            </select>
                        </div>
                        <div className="date-schedule">
                            <h1>Date Range</h1>

                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                minDate={getDatefromISODate(getNextDay(moment().tz(user.timezone)))}
                                placeholderText="Select"
                                onChange={(update) => {
                                    setDateRange(update);
                                }}
                                isClearable={true}
                            />
                        </div>
                        <div className="recurrence-schedule">
                            <h1>Recurrence</h1>
                            <select className="recurrence-input" value={selectedRecurrence} onChange={(e) => setSelectedRecurrence(e.target.value)}>
                            <option id="select" value="select">Select</option>
                            <option id="daily" value="daily">Daily</option>
                            <option id="weekly" value="weekly">Weekly</option>
                            <option id="fortnight" value="fortnight">Fortnight</option>
                            <option id="monthly" value="monthly">Monthly</option>
                            </select>
                        </div>
                    </div>
                    <div className="buttons-container">
                        <button className="time-add" onClick={changeViewScheduleShift}>CANCEL</button>
                        <button className="time-add" onClick={addScheduledShift}>SCHEDULE SHIFT</button>
                    </div>
                </div>
            )}
            { (isError || isSuccess) && ( 
                <div className="modal-overlay">
                    <div className={isError ? "modal-error" : "modal-success"}>
                        {isError && !loadingSchedule && (
                            <>
                                <div className="modal-header">
                                    <img src="/error-icon.webp" alt="Error" width="128" height="128"  /> 
                                    <h3>Error!</h3>
                                </div>
                                <div className="modal-body">
                                    <p>{messageModal}</p>
                                    <p><small>{smallMessageModal}</small></p>
                                    <button onClick={closeModalError}>Accept</button>
                                </div>
                            </>
                        )}
                        {isSuccess && !loadingSchedule && (
                            <>
                                <div className="modal-header">
                                    <img src="/success-icon.webp" alt="Success" width="128" height="128"  /> 
                                    <h3>Completed action!</h3>
                                </div>
                                <div className="modal-body">
                                    <p>{messageModal}</p>
                                    <p><small>You can see all your shifts in Historial section.</small></p>
                                </div>
                            </>
                        )}
                        {loadingSchedule && (
                            <>
                                <div className="modal-header">
                                    <img src="/loading-icon.webp" alt="Loading" width="128" height="128"  /> 
                                    <h3>Saving the scheduled shifts...</h3>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
        )}
        

        { !isAuthenticated && !activatedSession && closedSession && (
            <div className="modal-no-session">
                <div className="modal-confirmation-no-session">
                    <h3 className="confirmation-title-no-session">Notification</h3>
                    <p className="confirmation-message-no-session">Your session has been closed or the password has been changed, please try to sign in again.</p>
                    <div className="confirmation-buttons-no-session">
                    <button className="button-no-session" onClick={goSignIn}>Sign in</button>
                    </div>
                </div>
            </div>
        )}

        { !isAuthenticated && !closedSession && (
            <>
            <div className="home-container">
                <div className="branding">
                    <img src="/ClockIn_logo.webp" alt="ClockIn Smart Logo" className="logo lazy-image" loading="lazy" onLoad={(e) => e.target.classList.add('loaded')} />
                    <h1 className="title">ClockIn Smart</h1>
                    <p className="tagline">TRACK TIME, UNLOCK POTENTIAL</p>
                    <p className="intro-text">Track your casual shifts effortlessly with ClockIn Smart. Stay flexible and focused on your work.</p>
                    <button className="intro-button" onClick={scrollToPurpose}>Discover More</button>
                </div>
                <div className="visual">
                    <img src="/pc.webp" alt="ClockIn Smart App" className="pc-image lazy-image" loading="lazy" onLoad={(e) => e.target.classList.add('loaded')} />
                </div>
                
            </div>
           
                <PurposeWrapper ref={purposeRef} isHomeSection={true} />
            
            </>
        )}
    </>
        
    );
}

export default Home;