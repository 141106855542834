import axios from 'axios';
import {verifySessionCookie} from './cookies'

// Configuración global de axios para enviar cookies
axios.defaults.withCredentials = true;

export const createSession = async (email) => {

  try{

    const responseSession = await axios.post(`${process.env.REACT_APP_API_URL}/api/create-session`, {
      email: email,
    });

    if(responseSession.data.success){
      const meta = responseSession.data.meta;
      const success = responseSession.data.success;
      return {
          meta: meta,
          success: success
      }
    }

    }catch(error){
      /* console.error("Error creating session:", error); */
    }
    return null;
}

export const deleteSession = async () => {
  try {

      const token = await verifySessionCookie();

      if (!token) {
        throw new Error('Token not found in cookies');
      }

      const config = {
        headers: {
            Authorization: `Bearer ${token}` 
        }
      };

      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-session`,config);
      return response.data;
  } catch (error) {
      return { success: false, message: "Failed to delete session" };
  }
};

export const deleteAllSession = async (email) => {

  try {
    const responseDeleteSession = await axios.delete(`${process.env.REACT_APP_API_URL}/api/delete-all-session/${email}`);
    return responseDeleteSession.data;
  
  } catch (error) {
    return { success: false, message: "Failed to delete all sessions" };
  }
}