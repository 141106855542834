import React, {useEffect, useState} from 'react'
import "../styles/Purpose.css"

function Purpose({isHomeSection = false}) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollY, setScrollY] = useState(0);
  const [removeIcons, setRemoveIcons] = useState(window.innerWidth < 850);

  useEffect(() => {
    document.title = "Purpose | ClockIn Smart";
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
      setRemoveIcons(newWidth < 850);
    };

    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const parallaxStyle = (factor) => ({
    transform: `translateY(${scrollY * factor}px)`
  });

  return (
    <div className={isHomeSection ? ("purpose-container home-section") : ("purpose-container")}>
      <div className="title-purpose">
        <span className="text-your-time1">Your Time</span>, 
        <span className="text-your-time2">Your Money</span>, 
        <span className="text-your-time3">Your Control</span>
      </div>

      <div className={isHomeSection ? ("images-container home-section") : ("images-container")}>
        <div className="image-block" style={isHomeSection ? {} : parallaxStyle(0.1)}>
          <img src="/phone1.webp" alt="Shifts management interface with actived and scheduled times" className="image-phone fade-in lazy-image" loading="lazy" onLoad={(e) => e.target.classList.add('loaded')}/>
          <p>Manage your shifts</p>
        </div>
        {windowWidth > 850 && (
        <div className="image-block" style={isHomeSection ? {} : parallaxStyle(0.1)}>
          <img src="/phone2.webp" alt="Home screen where to set the start and end time of the shift" className="image-phone fade-in lazy-image" loading="lazy" onLoad={(e) => e.target.classList.add('loaded')}/>
          <p>Plan your day</p>
        </div>)}

        {windowWidth > 660 && (<div className="image-block" style={isHomeSection ? {} : parallaxStyle(0.1)}>
          <img src="/phone3.webp" alt="Graphic showing the hours worked per day" className="image-phone fade-in lazy-image" loading="lazy" onLoad={(e) => e.target.classList.add('loaded')}/>
          <p>Visualize your work</p>
        </div>)}
      </div>

      <div className="info-section">
        <div className={!removeIcons ? ("info-text"): ("info-text-centered")}>
          <h2>Why Choose ClockIn Smart?</h2>
          <p>
          ClockIn Smart is the ultimate tool for keeping track of your time and earnings. With its intuitive interface, you can easily log your worked hours, plan your future shifts, and analyze your earnings clearly. All of this allows you to focus on what matters, your results and the efficiency of your work.
          </p>
        </div>
        { windowWidth > 850 && (
          <div className="info-image">
            <img src="/clock-icon.webp" alt="Clock with rotating arrow" className="image-icon1 lazy-image" loading="lazy" onLoad={(e) => e.target.classList.add('loaded')}/>
          </div>
        )
        }
        
      </div>

      <div className="info-section">
        {windowWidth > 850 && (
          <div className="info-image">
            <img src="/graphic-icon.webp" alt="A bar graph with an arrow indicating upward growth" className="image-icon1 lazy-image" loading="lazy" onLoad={(e) => e.target.classList.add('loaded')}/>
         </div>
        )}
        <div className={!removeIcons ? ("info-text"): ("info-text-centered")}>
          <h2>For whom is it developed?</h2>
          <p>
          ClockIn Smart is designed and developed for people with busy, changing schedules, like those working on a project, freelancers with different hours each week, or students managing their study time. It helps you keep track of the hours you have worked, plan for the hours you will work, and see the benefits you gain from your efforts.
          </p>
        </div>
      </div>

      <div className="images-container">
        <div className="image-block">
          <img src="/tablet.webp" alt="Graphic page showing the money earned per days" className="image-tablet lazy-image" loading="lazy" onLoad={(e) => e.target.classList.add('loaded')}/>
        </div>

      </div>
    
    </div>
  );
}

export default Purpose
