import axios from "axios"

// Function to encrypt a password
const encryptPasswordOnServer = async (password) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/encrypt-password`, {
            password: password
        });

        if (response.status !== 200 || typeof response.data.hashedPassword !== 'string') {
            throw new Error('Error encrypting password or invalid response format');
        }

        return response.data.hashedPassword;
    } catch (error) {
        /* console.error('Error:', error); */
        throw error;
    }
};

const comparePasswordsOnServer = async (password, email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/compare-passwords`, {
            password: password,
            email: email
        });

        if (response.status !== 200) {
            throw new Error('Error comparing passwords');
        }

        return response.data.match;
    } catch (error) {
        /* console.error('Error:', error); */
        throw error;
    }
};

export { encryptPasswordOnServer, comparePasswordsOnServer };