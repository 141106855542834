import React from 'react';
import ReactDOM from 'react-dom/client';
import { UserProvider } from "./components/UserContext";
import { LoadingProvider } from './components/LoadingContext';
import App from "./App.js"
import './styles/Index.css';
import reportWebVitals from './reportWebVitals';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <LoadingProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </LoadingProvider>
  </React.StrictMode>
);

reportWebVitals();