import React, { useState, useEffect } from "react";
import axios from "axios";
import BarChart from "../components/BarChart";
import { useUser } from '../components/UserContext';
import { useNavigate } from 'react-router-dom';
import {formatDateToInputValueMMDDYYYY, groupDataByDays, calculateDailyData, getDatefromISODate, getISODatefromDate} from "../components/UsefulFunctions.js";
import {initSessionValidation} from "../components/Auth";
import {verifySessionCookie} from "../components/cookies.js";
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import "../styles/Graphics.css";
import "../styles/App.css";
import 'react-datepicker/dist/react-datepicker.css';

function Graphics({isAuthenticated, activatedSession, setActivatedSession, closedSession, setClosedSession}) {
  
  const [error, setError] = useState("");
  const [allJobOptions, setAllJobOptions] = useState([]);
  const {user, jobs, chartParamsGraphic, setChartParamsGraphic, logout } = useUser(); 
  const [canUpdateData, setCanUpdateData] = useState(true);
  const [canLoadData, setCanLoadData] = useState(true);

  const [isManageToolsOpen, setIsManageToolsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [forceReload, setForceReload] = useState(false);

  const { lastParams, lastUpdate } = chartParamsGraphic;

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Graphics | ClockIn Smart";
  }, []);

  useEffect(() => {
    comprobateSession();
  },[isAuthenticated, navigate])

  const comprobateSession = async() => {
    const isSessionActivated = await initSessionValidation();
      if(isSessionActivated.success){
        setActivatedSession(true);
      }
      else{
          if(isAuthenticated) //If the session was previously initiated
              setClosedSession(true);
        await logout();
        sessionStorage.removeItem('dataLoaded');
      }    
  }


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleManageTools = () => {
    setIsManageToolsOpen(!isManageToolsOpen);
  };

  const goSignIn = () => {
    setClosedSession(false);
    navigate('/Login');
  }

  useEffect(() => {
    if (!user) return;
      const userJobsOptions = jobs.map(job => ({
        id: job._id,
        name: job.name
    }));

    let options;

    if(userJobsOptions.length !== 0){
      // Retrieve all available job options for the current user
      options = [{ id: 'all', name: 'All jobs' }, ...userJobsOptions];
    
    }else{
      options = [{ id: 'all', name: 'No jobs' }, ...userJobsOptions];
    }
    setAllJobOptions(options);
  }, [jobs, user]);

  /* -------------------------------------------ACTIONS----------------------------------- */
  useEffect(() => {

    const interval = setInterval(async () => {
      
      try {
        const token = await verifySessionCookie();
        if (!token) {
          throw new Error('Token not found in cookies');
        }

        const config = {
          headers: {
              Authorization: `Bearer ${token}` 
          }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/check-shift-update`, config);
        if (response.data.updated) {
          sessionStorage.removeItem('dataLoaded');
          setForceReload(true);
        }

      } catch (error) {
        /* console.error('Error checking shift update:', error); */
      }
    }, 600000); // 600,000 ms = 1 min

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (forceReload) {

      setChartParamsGraphic(prev => ({
        ...prev,
        loadDataFirstTime: false
      }));
      setForceReload(false);
    }
  }, [forceReload, setChartParamsGraphic]);

  /* -----------------------------------FINISH ACTIONS----------------------------------- */


useEffect(() => {
  const { rawData, loadDataFirstTime, lastParams, dateRange, selectedJob, selectedMetric, lastUpdate, viewStatus } = chartParamsGraphic;
  
  const fetchDataNeeded = !loadDataFirstTime || !rawData || (lastUpdate !== lastParams?.lastUpdate);
 
  if (fetchDataNeeded && user && canLoadData === true) {
    setCanUpdateData(false);  //Para que no cargue la gráfica cuando aún no ha cargado los datos.
    
    //To load the data for 2 years:
    setCanLoadData(false); // To load it only 1 time
    const dateRangeTotal = [moment().subtract(1, 'year').tz(user.timezone).format(), moment().add(1, 'year').tz(user.timezone).format()];

    // The dateRange does not have the calculation of the user's UTC, however, the dateRangeTotal does (the period of 2 years)
    fetchData(dateRange, dateRangeTotal, selectedJob, selectedMetric, viewStatus);
    setCanUpdateData(true);
  } else if (!fetchDataNeeded && rawData && canUpdateData) {
    updateChartData(rawData);
    setCanUpdateData(false);
  }
}, [user,
  chartParamsGraphic.rawData,
  chartParamsGraphic.loadDataFirstTime,
  chartParamsGraphic.lastUpdate,
  chartParamsGraphic.dateRange,
  chartParamsGraphic.selectedJob,
  chartParamsGraphic.selectedMetric,
  chartParamsGraphic.viewStatus]);


// Update `fetchData` to control when to update the state
const fetchData = async (dateRange, dateRangeTotal, selectedJob, selectedMetric, viewStatus) => {

  // Change both the start and end of dateRange and dateRangeTotal to start at 00:00h and end at 23:59h
  /* dateRange Before: [ "2024-05-10T18:17:27Z", "2024-05-17T18:17:27Z" ]
     Range total: [ "2023-05-17T18:17:27-05:00", "2025-05-17T18:17:27-05:00" ] */
  
  // It already has the UTC+0 format from before
  const [startDate, endDate] = dateRange; 
  const [startDateTotal, endDateTotal] = dateRangeTotal;

  /////////////////////////////////////////////////////////////////////////////////////////////
  
  // Now we are going to change startDate and endDate so that startDate has 00:00h and endDate has 23:59h to obtain from the database from
  // the beginning of the first day until the end of the last day.

  // To calculate the range of the 7 days that will be shown in the user's graph from their day to 7 days ago

  // Create a moment object from the start date, respecting the offset
  const localStartMoment = moment.parseZone(startDate);
  const localEndMoment = moment.parseZone(endDate);

  // Adjust the date to the start of the day in its original time zone
  const startOfDayLocal = localStartMoment.startOf('day');
  const endOfDayLocal = localEndMoment.endOf('day');

  // Convert that date to UTC while maintaining the same day and time
  const startOfDayUTC = startOfDayLocal.utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const endOfDayUTC = endOfDayLocal.utcOffset(0, true).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const startOfDayDate = getDatefromISODate(startOfDayUTC);
  const endOfDayDate = getDatefromISODate(endOfDayUTC);

  const rangeOfDateFromGraphic = [startOfDayDate, endOfDayDate];

  /////////////////////////////////////////////////////////////////////////////////////////////

  try {
    //I pass the UTC dates of the 2-year range to shift-summary, and within this, they will be returned in the date format that the user has

    const token = await verifySessionCookie(); 

          if (!token) {
              return;
          }

          // Configuración de la cabecera de autorización y los parámetros de la consulta
          const config = {
              headers: {
                  Authorization: `Bearer ${token}` // Aquí se incluye el token en el encabezado
              },
              params: {
                startDate: startDateTotal,
                endDate: endDateTotal,
                email: user.email,
                timezone: user.timezone
              }
          };

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/shifts-summary`, config);

    if (response.data && Array.isArray(response.data.shifts)) {

        setChartParamsGraphic(prev => ({
            ...prev,
            rawData: response.data.shifts,
            loadDataFirstTime: true,
            dateRange: rangeOfDateFromGraphic,
            lastParams: {
                dateRange: rangeOfDateFromGraphic,
                selectedJob,
                selectedMetric,
                viewStatus,
                lastUpdate: prev.lastUpdate,
                rawData: response.data.shifts
            }
        }));
        

    } else {
        throw new Error("Data fetched is not in expected format or empty");
    }
  } catch (error) {
    setError("Error fetching data from server");
  }
};

const updateChartData = (rawData) => {
  const { dateRange, selectedJob, viewStatus  } = chartParamsGraphic;
  
  // Adjust dates for internal use according to the user's time zone

  const startISO = getISODatefromDate(dateRange[0]);
  const endISO = getISODatefromDate(dateRange[1]);
  
  let filteredData = rawData;
  
  if (selectedJob !== 'All jobs' && selectedJob !== "all") {
    filteredData = rawData.filter(data => data.job_id === selectedJob);
  }

  if (viewStatus && viewStatus !== "all") {
    filteredData = filteredData.filter(data => data.status === viewStatus);
  }

  let resultData;
  const diffDays = Math.ceil((new Date(endISO) - new Date(startISO)) / (1000 * 60 * 60 * 24));
  
  if (diffDays > 10) {
    resultData = groupDataByDays(startISO, endISO, filteredData, user.timezone);
  } else {
    resultData = calculateDailyData(startISO, endISO, filteredData, user.timezone);
  }

  if (JSON.stringify(chartParamsGraphic.chartData) !== JSON.stringify(resultData)) {
    setChartParamsGraphic(prev => ({
        ...prev,
        chartData: resultData
    }));
  }
};
  
  return (
    <>
      {isAuthenticated && activatedSession && !closedSession && (
        <div className="graphics-container">
        <div className="graphics-content">
          <div className="filter-section">
            
              {windowWidth > 970 && (
                <>
                  <input type="text" className="dropdown today disabled" value={formatDateToInputValueMMDDYYYY(new Date(), user.timezone)} readOnly />
                  <select id="status" className="dropdown" value={chartParamsGraphic.viewStatus} onChange={(e) => {
                    setChartParamsGraphic(prev => ({ ...prev, viewStatus: e.target.value }));
                    setCanUpdateData(true);
                    }}>
                    <option value="all">All status</option>
                    <option value="active">Actived</option>
                    <option value="scheduled">Scheduled</option>
                  </select>
                  <select id="metric" className="dropdown" value={chartParamsGraphic.selectedMetric} onChange={(e) => {
                    setChartParamsGraphic(prev => ({ ...prev, selectedMetric: e.target.value }));
                    setCanUpdateData(true);
                    }}>
                      <option value="workedHours">Work Hours</option>
                      <option value="moneyEarned">Money Earned</option>
                  </select>

                  <select id="jobSelected" value={chartParamsGraphic.selectedJob} onChange={(e) => {
                    setChartParamsGraphic(prev => ({ ...prev, selectedJob: e.target.value }));
                    setCanUpdateData(true);
                  }} 
                  className="dropdown">
                    {allJobOptions.map(option => (
                      <option key={option.id} value={option.id}>{option.name}</option>
                    ))}
                  </select>
                  
                  <div className="datePicker-input">        
                  <DatePicker
                    selectsRange
                    startDate={chartParamsGraphic.dateRange[0] ? moment.utc(chartParamsGraphic.dateRange[0]).toDate() : null}
                    endDate={chartParamsGraphic.dateRange[1] ? moment.utc(chartParamsGraphic.dateRange[1]).toDate() : null}
                    onChange={(dates) => {
                      const [start, end] = dates;
                      if (start && !end) {
                        // When the first date is selected and the second one is null, set only the first one
                        setChartParamsGraphic(prev => ({
                          ...prev,
                          dateRange: [moment.utc(start).toISOString(), null]
                        }));
                      } else if (start && end) {
                        // When both dates are selected, update the state completely
                        setChartParamsGraphic(prev => ({
                          ...prev,
                          dateRange: [moment.utc(start).toISOString(), moment.utc(end).toISOString()]
                        }));
                        setCanUpdateData(true);
                      }
                    }}
                    maxDate={moment.utc().add(365, 'days').toDate()}
                    minDate={moment.utc().subtract(365, 'days').toDate()}
                  />
                </div>
                
                </>

              )}

              {windowWidth <=970 && (
                <>
                <div className="control-group-graphic">
                  <button onClick={toggleManageTools} className="control-button-graphic">{!isManageToolsOpen ? ("Manage Tools"): ("Close Tools")}</button>
                  { isManageToolsOpen && (
                    <>
                      <div className="manage-tools-dropdown-graphic">

                        <div className="dropdown-item-graphic">
                          <select id="status" className="dropdown" value={chartParamsGraphic.viewStatus} onChange={(e) => {
                            setChartParamsGraphic(prev => ({ ...prev, viewStatus: e.target.value }));
                            setCanUpdateData(true);
                            }}>
                            <option value="all">All status</option>
                            <option value="active">Actived</option>
                            <option value="scheduled">Scheduled</option>
                          </select>
                        </div>
                        
                        <div className="dropdown-item-graphic">
                          <select id="metric" className="dropdown" value={chartParamsGraphic.selectedMetric} onChange={(e) => {
                            setChartParamsGraphic(prev => ({ ...prev, selectedMetric: e.target.value }));
                            setCanUpdateData(true);
                            }}>
                              <option value="workedHours">Work Hours</option>
                              <option value="moneyEarned">Total Earned</option>
                          </select>
                        </div>
                        
                            <div className="dropdown-item-graphic">
                              <select id="jobSelected" value={chartParamsGraphic.selectedJob} onChange={(e) => {
                                setChartParamsGraphic(prev => ({ ...prev, selectedJob: e.target.value }));
                                setCanUpdateData(true);
                              }} 
                              className="dropdown">
                                {allJobOptions.map(option => (
                                  <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                              </select>
                            </div>

                            <div className="dropdown-item-graphic">
                              <div className="datePicker-input">
                              <DatePicker
                                selectsRange
                                startDate={chartParamsGraphic.dateRange[0] ? moment.utc(chartParamsGraphic.dateRange[0]).toDate() : null}
                                endDate={chartParamsGraphic.dateRange[1] ? moment.utc(chartParamsGraphic.dateRange[1]).toDate() : null}
                                onChange={(dates) => {
                                  const [start, end] = dates;
                                  if (start && !end) {
                                    // When the first date is selected and the second one is null, set only the first one
                                    setChartParamsGraphic(prev => ({
                                      ...prev,
                                      dateRange: [moment.utc(start).toISOString(), null]
                                    }));
                                  } else if (start && end) {
                                    // When both dates are selected, update the state completely
                                    setChartParamsGraphic(prev => ({
                                      ...prev,
                                      dateRange: [moment.utc(start).toISOString(), moment.utc(end).toISOString()]
                                    }));
                                    setCanUpdateData(true);
                                  }
                                }}
                                maxDate={moment.utc().add(365, 'days').toDate()}
                                minDate={moment.utc().subtract(365, 'days').toDate()}
                              />
                              </div>
                            </div>         
                        </div>

                    </>
                    )
                  }
                </div>
              </>
            )}
            
          
          </div>
          <div className="chart-section">
            {error && <div className="error-data-graphic">{error}</div>}
            <div className="barChart-graphic">
              {((chartParamsGraphic.chartData && !error && !(lastUpdate !== lastParams?.lastUpdate)) || !canUpdateData && !error ) ? (<BarChart chartData={chartParamsGraphic.chartData} title={chartParamsGraphic.selectedMetric === "workedHours" ? ("Work Hours"):("Money Earned")} selectedMetric={chartParamsGraphic.selectedMetric} />) : ((lastUpdate !== lastParams?.lastUpdate) && !error && <div className="loading-data-graphic">Loading data...</div>) }
            </div>
           
          </div>
        </div>
      </div>

    )}
    {
      (!isAuthenticated || !activatedSession || closedSession) && (
        <div className="modal-no-session">
          <div className="modal-confirmation-no-session">
              <h3 className="confirmation-title-no-session">Notification</h3>
              <p className="confirmation-message-no-session">Your session has been closed or the password has been changed, please try to sign in again.</p>
              <div className="confirmation-buttons-no-session">
              <button className="button-no-session" onClick={goSignIn}>Sign in</button>
              </div>
          </div>
        </div>
      )
    }
    
    </>
    
  );
}
export default Graphics;