import axios from "axios";

import {verifySessionCookie} from "./cookies.js";

export const fetchData = async (user, updateJobs, updateShifts = null) => {
    try {

        const token = await verifySessionCookie(); // Asumiendo que el token se guarda en una cookie

        if (!token) {
            /* throw new Error('Token not found in cookies'); */
            return updateJobs([]);
        }

        // Configuración de la cabecera de autorización y los parámetros de la consulta
        const config = {
            headers: {
                Authorization: `Bearer ${token}` // Aquí se incluye el token en el encabezado
            }
        };

        // Load and update jobs
        let jobs;

        try{
            const responseJobs = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-jobs`, config);
            jobs = responseJobs.data.jobs;
            updateJobs(jobs);

        }catch(error){
            if (error.response && error.response.status === 403) {
                /* console.log('Access forbidden for jobs: 403'); */
            }
            updateJobs([]);
        }

        // Load and update shifts only if updateShifts is given
        if (updateShifts) {
            if(!jobs)
                return;
            if (jobs.length !== 0) {

                const token = await verifySessionCookie();
                if (!token) {
                    /* throw new Error('Token not found in cookies'); */
                    return updateShifts([]);;
                }

                const configShifts = {
                    headers: {
                        Authorization: `Bearer ${token}` // Aquí se incluye el token en el encabezado
                    },
                    params: { jobIds: jobs.map(job => job._id).join(',') } 
                };

                try {
                    const responseShifts = await axios.get(`${process.env.REACT_APP_API_URL}/api/user-shifts`, configShifts);
                    const shifts = responseShifts.data;
                    updateShifts(shifts);

                } catch (error) {
                    if (error.response && error.response.status === 403) {
                        /* console.log('Access forbidden for shifts: 403'); */
                    }
                    updateShifts([]);
                }

            } else {
                updateShifts([]);
            }
        }
    } catch (error) {
        if (error.response && error.response.status === 403) {
            /* console.log('Access forbidden: 403'); */
        }
    }
};